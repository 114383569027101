import { SET_LOCALE } from '../constants';

import literalFiles from '../model/literals';

export default (state = require(`../model/Literals.json`), action) => {
    switch (action.type) {
        case SET_LOCALE:
            const { locale } = action;
            if (locale == 'en')
            {
                return require(`../model/Literals.json`);
            }
            else
            {
                literalFiles[locale];
                return literalFiles[locale];
            }
        default:
            return state;
    }
}