import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

import { getNationalComparisonsChartData } from '../../../api';
import { nationalComparisonLabel } from '../../../model/PageLabels';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

class NationalComparisonsChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            splits: [],
            auxSeries: [],
            chartConfig: {
                title: {
                    text: "",
                    align: 'left'
                },
                credits: {
                    enabled: false
                },
                colors: [],
                chart: {
                    type: "bar",
                    backgroundColor: '#FFF'
                },
                exporting: {
                    enabled: false,
                    buttons: {
                        contextButton: {
                            menuItems: ['viewFullscreen']
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                tooltip: {},
                plotOptions: {},
                series: []
            },
            chartConfigs: [],
            isLoading: false
        }
    }

    componentDidMount() {
        document.title = nationalComparisonLabel
        // console.log("National Comparisons chart did mount");
        this.getLoadData(this.props.filters.question, this.props.filters.sectorSize, this.props.filters.activitySector, 
            this.props.filters.establishmentSize, this.props.filters.country, this.props.filters.country2,this.props.filters.locale);
    }

    componentDidUpdate(prevProps) {
        // console.log("prevProps.filters",prevProps.filters,"this.props.filters",this.props.filters);
        if(prevProps.filters.question != this.props.filters.question || prevProps.filters.sectorSize != this.props.filters.sectorSize
            || prevProps.filters.activitySector != this.props.filters.activitySector || prevProps.filters.establishmentSize != this.props.filters.establishmentSize
            || prevProps.filters.country != this.props.filters.country || prevProps.filters.country2 != this.props.filters.country2
            || prevProps.filters.locale != this.props.filters.locale){
            this.getLoadData(this.props.filters.question, this.props.filters.sectorSize, this.props.filters.activitySector, 
                this.props.filters.establishmentSize, this.props.filters.country, this.props.filters.country2,this.props.filters.locale);
        }
    }

    createColorPalette = () => {
        let colorArray = [];

        if(this.props.filters.country === "EU28" || this.props.filters.country === "EU27_2020"){
            colorArray.push("#449fa2");
            colorArray.push("#003399");
        }else if(this.props.filters.country2 === "EU28" || this.props.filters.country2 === "EU27_2020"){
            colorArray.push("#003399");
            colorArray.push("#f6a400");
        }else{
            colorArray.push("#449fa2");
            colorArray.push("#f6a400");
        }

        colorArray.reverse();
        
        return colorArray;
    }

    // Gets data for the selected Chart
    getLoadData = (question, sectorSize, activityId, establishmentId, country1, country2, locale) => {
        // console.log("National Comparisons chart getLoadData");
        let categories = [];
        let auxSeries = [];
        let eachChartConfig = {};
        let chartsConfig = [];
        let splits = [];

        const csvData = [];

        getNationalComparisonsChartData(question, sectorSize, sectorSize === "activity-sector" ? activityId : establishmentId, country1, country2, locale)
            .then((data) => {
                // console.log("national comparisons data", data.resultset);
                try {
                    this.setState({ ...this.state, isLoading: true });
                    data.resultset.forEach(element => {
                        // console.log("element", element);
                        if (categories.indexOf(element.country) == -1) {
                            categories.push(element.country);
                        }

                        let split1 = element.split1;
                        if (!(split1 in auxSeries)) {
                            splits.push(split1);
                            auxSeries[split1] = [];
                        }

                        // auxSeries[split1].push(element.value);

                        if(element.value != undefined){
                            auxSeries[split1].push(element.value);
                        }

                        auxSeries[split1].reverse();

                        csvData.push({ country: element.countryCode, question: this.props.filters.question, subsector: element.split2, answer: element.split1, value: element.value })
                    });

                    this.props.csvDataCallback(csvData);

                    categories.reverse();

                    // console.log("auxSeries",auxSeries);

                    for (let serie in auxSeries) {
                        eachChartConfig = {
                            chart: {
                                type: 'bar',
                                height: 180,
                                marginRight: 50
                            },
                            credits: {
                                enabled: false
                            },
                            colors: this.createColorPalette(),
                            title: {
                                text: this.props.literals.L100588+": "+serie,
                                align: 'left',
                                style: {
                                  fontFamily: '"Oswald", sans-serif',
                                  color: "#00a1a3",
                                  fontSize: "32px"
                                }
                            },
                            exporting: {
                                enabled: false
                            },
                            xAxis: {
                              labels: {
                                style: {
                                  fontFamily: "OpenSans",
                                  fontWeight: "Bold",
                                  fontSize: "14px"
                                }
                              },
                              categories: categories,
                              lineWidth: 0
                            },
                            yAxis: {
                                min: 0,
                                gridLineWidth: 0,
                                visible:false,
                                max: 100
                            },
                            plotOptions: {
                                series: {
                                    colorByPoint: true,
                                    pointPadding: 0,
                                    dataLabels: {
                                        enabled:true,
                                        inside: false,
                                        crop: false,
                                        overflow: "allow",
                                        style: {
                                            fontFamily: "OpenSans",
                                            fontWeight: "Bold",
                                            fontSize: "14px"
                                        },
                                        format: '{point.y:.1f} %'
                                    }
                                }
                            },
                            legend: {
                                enabled: false
                            },
                            tooltip: {
                                // headerFormat: '<b>{point.x}</b><br/><b>{series.name}</b><br/>',
                                // pointFormat: '<b>{point.y}%</b> ',
                                enabled:true,
                                useHTML: true,
                                opacity:0.9,
                                backgroundColor: '#333333',
                                borderColor: '#000000',
                                zIndex: 100,
                                style: {
                                    width: 450,
                                    color: 'white',
                                    fontFamily: 'OpenSans',
                                    fontWeight: 'normal',
                                    fontSize:'12px'
                                },
                                formatter: function () {
                                    return '<ul class="tooltip-item">'+
                                    '<li>'+ this.x +' </li>' +
                                    '<li><strong class="tooltip-value"> </strong> '+ this.y +'%</li>' +
                                    '</ul>';
                                }
                            },
                            series: [{
                                name: serie,
                                data: auxSeries[serie]
                            }]
                        };

                        chartsConfig[serie] = {};
                        chartsConfig[serie] = eachChartConfig;
                    }

                    this.setState({
                        splits: splits,
                        auxSeries: auxSeries,
                        chartConfigs: chartsConfig
                    });

                } catch (error) {
                    console.log('error fetching data', error)
                } finally {
                    this.setState({ ...this.state, isLoading: false })
                }
            })

        // getNationalComparisonsChartData(question, sectorSize, sectorSize === "activity-sector" ? activityId : establishmentId, country1, country2, "EN")
        //     .then((data) => {
        //         console.log("national comparisons data", data.resultset);

        //         try {
        //             // this.setState({ ...this.state, isLoading: true });
        //             // data.resultset.forEach(element => {
        //             //     // console.log("element", element);
        //             //     if (categories.indexOf(element.country) == -1) {
        //             //         categories.push(element.country);
        //             //     }

        //             //     let split1 = element.split1;
        //             //     if (!(split1 in auxSeries)) {
        //             //         auxSeries[split1] = []
        //             //     }

        //             //     // auxSeries[split1].push({name: element.split1Name, y: element.value});
        //             // });

        //             // categories.forEach(category => {
        //             //     for (let serie in auxSeries) {
        //             //         let found = data.resultset.find(element => element.country === category && element.split1 === serie);
        //             //         if(found != undefined){
        //             //             auxSeries[serie].push({name: found.split1Name, y: found.value});
        //             //         }else{
        //             //             auxSeries[serie].push({name: "Answer", y: 0});
        //             //         }
        //             //     }
        //             // })

        //             // console.log("categories",categories);
        //             // console.log("auxSeries",auxSeries);
        //         } catch (error) {
        //             console.log('error fetching data', error)
        //         } finally {
        //             this.setState({ ...this.state, isLoading: false })
        //         }

        //         // let newArray;
        //         // for (let serie in auxSeries) {
        //         //     // console.log("serie",serie);
        //         //     series.push( {
        //         //         name: serie, 
        //         //         data: auxSeries[serie],
        //         //     } );
        //         //     newArray = [...series].reverse();
        //         // }
    }

    render()
	{
		return(
			<div className="national-comparison-chart">
                <div className="xxs-w1 xs-w1 w1 center-text">
                    <div className="card--block--chart">
                        <div className="chart--block">
                            {
                                this.state.splits.map(serie => (
                                    <HighchartsReact key={serie}
                                        highcharts={Highcharts}
                                        options={this.state.chartConfigs[serie]}
                                        containerProps={{ className: 'chart--block' }}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
			</div>
		)
	}
}

export default NationalComparisonsChart;