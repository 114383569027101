import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { overviewLabel } from '../../model/PageLabels';

class Overview extends Component
{
	constructor(props){
		super(props);
		this.state = {
			year: this.props.year,
			maxCharacters: 600, 
			classNameAcc1:"active",classNameAcc2:"",
			isVisible: false
		}
	}

	componentDidMount() {
		document.title = overviewLabel;
	}

	componentDidUpdate(prevProps, prevState){
		// console.log('prevState',prevState);
		if(this.state.year != this.props.year){
			// console.log('year changed');
			this.setState({
					isVisible: false,
					year: this.props.year,
					classNameAcc1:"active",
					classNameAcc2:""
				});
		}
	}
	toggleText = ()  => {
		this.setState({ isVisible: !this.state.isVisible })
	}

	accordion = (accordion) => (event) => {        
		// console.log('event',event);
		// console.log('event.currentTarget',event.currentTarget);
		// console.log('event.currentTarget.nextElementSibling.className',event.currentTarget.nextElementSibling.className);
		var elemActive = event.currentTarget.nextElementSibling.className.indexOf('active');

		if( elemActive > 0 ){
			if(accordion === "1"){
				this.setState({classNameAcc1: ""})
			}else{
				this.setState({classNameAcc2: ""})
			}
		}else{
			if(accordion === "1"){
				this.setState({classNameAcc1: "active", classNameAcc2: ""});
			}else{
				this.setState(this.setState({classNameAcc1: "", classNameAcc2: "active"}))
			}
		}          
	}

	overviewText = (literal) => {
		const replacedText = this.props.literals[literal].replace(/<\/?[a-z][a-z0-9]*[^<>]*>|<!--.*?-->/img);
		const trimmedText = `${this.props.literals[literal].substring(0, this.state.maxCharacters).split(" ").slice(0, -1).join(" ")}<span className="dots">...</span>`

		if (replacedText.length > this.state.maxCharacters) {
			return (
				<React.Fragment>
					{!this.state.isVisible ? (
						<div className="partial-text">
							{ReactHtmlParser(trimmedText)}
						</div>
					) : (
						ReactHtmlParser(this.props.literals[literal])
					)}
					<p className="see-more" onClick={this.toggleText}>
						{!this.state.isVisible ? <a className="see-more" >{this.props.literals.L480}</a> : null}
						{this.state.isVisible ? <a className="see-less" >{this.props.literals.L481}</a> : null}
					</p>
				</React.Fragment>
			)
		} else {
			return this.props.literals[literal]
		}
	}

	render()
	{
		let overviewByYear;

		if(this.state.year === "2009"){
			overviewByYear = (
				<section className="container">
					<div className="container grid-2">
						<div className="col-sm-6 col-xs-12  col-md-6 col-img">
							<div><img src={require("../../style/img/esener2009.png")} className="no-hover" /></div>
						</div>
						<div className="col-sm-6 col-xs-12  col-md-6 col-content">
							<h1 className="title--section main-color">{this.props.literals.L101007}</h1>
							{this.overviewText('L101004')}
						</div>
					</div>
					{/* <!-- Accordions section 2009 --> */}
					<h2 className="text-center">{this.props.literals.L101011}</h2>
					{/* <!-- First accordion -->
					<!-- Title of the accordion --> */}
					<h3 className={"title--section main-color accordion-title "+this.state.classNameAcc1} onClick={this.accordion("1")}>{this.props.literals.L101012}</h3>
					{/* <!-- Content of the accordion --> */}
					<div className={"accordion-content "+this.state.classNameAcc1}>{ReactHtmlParser(this.props.literals.L101014)}</div>

					{/* <!-- Second accordion --> */}
					{/* <!-- Title of the accordion --> */}
					<h3 className={"title--section main-color accordion-title "+this.state.classNameAcc2} onClick={this.accordion("2")}>{this.props.literals.L101013}</h3>
					{/* <!-- Content of the accordion --> */}
					<div className={"accordion-content "+this.state.classNameAcc2}>{ReactHtmlParser(this.props.literals.L101015)}</div>
				</section>
			)
		}else if(this.state.year === "2014"){
			overviewByYear = (
				<section className="container">
					<div className="container grid-2">
						<div className="col-sm-6 col-xs-12  col-md-6 col-img">
							<div><img src={require("../../style/img/esener2014.png")} className="no-hover" /></div>
						</div>
						<div className="col-sm-6 col-xs-12  col-md-6 col-content">
							<h1 className="title--section main-color">{this.props.literals.L101008}</h1>
							
							{this.overviewText('L101016')}
							
						</div>
					</div>

					{/* <!-- Accordions section 2014 --> */}

					<h2 className="text-center">{this.props.literals.L101011}</h2>
					<div className="accordion-summary">{ReactHtmlParser(this.props.literals.L101017)}</div>
					{/* <!-- First accordion --> */}
					{/* <!-- Title of the accordion --> */}
					<h3 className={"title--section main-color accordion-title "+this.state.classNameAcc1} onClick={this.accordion("1")}>{this.props.literals.L101012}</h3>
					{/* <!-- Content of the accordion --> */}
					<div className={"accordion-content "+this.state.classNameAcc1}>{ReactHtmlParser(this.props.literals.L101018)}</div>

					{/* <!-- Second accordion --> */}
					{/* <!-- Title of the accordion --> */}
					<h3 className={"title--section main-color accordion-title "+this.state.classNameAcc2} onClick={this.accordion("2")}>{this.props.literals.L101013}</h3>
					{/* <!-- Content of the accordion --> */}
					<div className={"accordion-content "+this.state.classNameAcc2}>{ReactHtmlParser(this.props.literals.L101019)}</div>
				</section>
			)
		}else if(this.state.year === "2019"){
			// if(literals.L101084 != "null"){

			// }
			overviewByYear = (
				<section className="container">
					<div className="container grid-2">
						<div className="col-sm-6 col-xs-12  col-md-6 col-img">
								<div><img src={require("../../style/img/esener2019.png")} className="no-hover" /></div>
							</div>
							<div className="col-sm-6 col-xs-12  col-md-6 col-content">
								<h1 className="title--section main-color" data-ng-bind="i18n.L101079">{this.props.literals.L101079}</h1>
								{this.overviewText('L101081')}
							</div>
					</div>
					{/* <!-- Accordions section 2019 --> */}
					<h2 className="text-center" data-ng-bind-html="i18n.L101011">{ReactHtmlParser(this.props.literals.L101011)}</h2>
					{/* <!-- First accordion --> */}
					{/* <!-- Title of the accordion --> */}
					<h3 className={"title--section main-color accordion-title "+this.state.classNameAcc1} onClick={this.accordion("1")} data-ng-bind-html="i18n.L101012">{this.props.literals.L101012}</h3>
					{/* <!-- Content of the accordion --> */}
					<div className={"accordion-content "+this.state.classNameAcc1} data-ng-bind-html="i18n.L101083">{ReactHtmlParser(this.props.literals.L101083)}</div>

					{/* <!-- Second accordion --> */}
					{/* <!-- Title of the accordion --> */}
					<h3 className={"title--section main-color accordion-title "+this.state.classNameAcc2} onClick={this.accordion("2")}>{this.props.literals.L101013}</h3>
					{/* <!-- Content of the accordion --> */}
					<div className={"accordion-content "+this.state.classNameAcc2}>{ReactHtmlParser(this.props.literals.L101084)}</div>
				</section>
			)
		}

		return(
			<div className="overview-page">
                {overviewByYear}
			</div>
		)
	}
}

function mapStateToProps(state){
    const {literals} = state;
    return {
        literals
    }
}

export default connect(mapStateToProps, null)(Overview);
// export default Overview;