import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LocaleSelect from '../common/LocaleSelect';
import { getQuestionFilters} from '../../api';
import { setLocale } from '../../actions';
import ReactHtmlParser from 'react-html-parser';
import $ from "jquery";

class Selector extends Component
{
    constructor(props){
        super(props);

        this.state = {
            localLanguage: this.props.locale,
            // filters: this.props.filters,
            questionFilters: {
                activitySectors: [],
                answers: [],
                countries: [],
                establishmentSizes: []
            },
            selectCountries: [],
            selectCountries2: [],
            selectActivitySectors: [],
            selectEstablishmentSizes: [],
            breadcrumb: "",
            langSize: [
                ["bg"],
                ["hr", "nl_1", "cy", "cz","is","nl","pt"],
                ["al", "fr_1","hr","da","el_1","al_1","mk","hu","et","ru_1",
                "sv_1","fr","el","it","lv","ru_2","fr_2","mt","no","pl","ro","sk","sl","es","sv","fr_3","it_1","tr"]
            ]
        }
    }

    //Function that calls the webservice to retrieve all the data for the select filters
    getQuestionFilters(){
        try{
            getQuestionFilters(this.props.filters.question, this.props.locale)
                .then((res) => {
                    this.setState({questionFilters: res.resultset});
                    this.rulesForSelects(this.state.questionFilters);
                })
        }catch (error) {
			console.log('Error fetching question filters data:', error)
		}
    }

    setQuestionBreadcrumb(){
        // console.log("setQuestionBreadcrumb",this.props.questionProperties);
        if (this.props.questionProperties != null)
        {
            var breadcrumb = "";
            if (this.props.questionProperties.level === 2)
            {
                if (this.props.questionProperties.name2LiteralID === 0 || this.props.questionProperties.name2LiteralID === undefined)
                {
                    breadcrumb = '<span class="level1-bread">' + this.props.localeLiterals["L"+this.props.questionProperties.fatherLiteralID] + '</span>' 
                        + " / " + '<span class="level2-bread">' + this.props.localeLiterals["L"+this.props.questionProperties.name3LiteralID] + "</span>";	
                }
                else
                {
                    breadcrumb = '<span class="level1-bread">' + this.props.localeLiterals["L"+this.props.questionProperties.fatherLiteralID] + '</span>' 
                        + " / " + '<span class="level2-bread">' + this.props.localeLiterals["L"+this.props.questionProperties.name2LiteralID] + "</span>";	
                }							
            }
            else if (this.props.questionProperties.level === 3)
            {
                breadcrumb = '<span class="level1-bread">' + this.props.localeLiterals["L"+this.props.questionProperties.grandfatherLiteralID] + '</span>' 
                    + " / " + '<span class="level2-bread">' + this.props.localeLiterals["L"+this.props.questionProperties.fatherLiteralID] + ": " 
                    + this.props.localeLiterals["L"+this.props.questionProperties.name2LiteralID]  + "</span>";
            }
        }
        this.setState({breadcrumb: breadcrumb});
    }

    componentDidMount(){
        // console.log("Selector.js componentDidMount");
        this.setQuestionBreadcrumb();
        this.getQuestionFilters();
    }

    componentDidUpdate(prevProps, prevState){
        // console.log("Selector.js componentDidUpdate", prevProps);
        if(prevProps.questionProperties != this.props.questionProperties){
            this.setQuestionBreadcrumb();
        }
        if(prevProps.filters.question != this.props.filters.question || prevProps.chart != this.props.chart || 
            prevProps.filters.activitySector != this.props.filters.activitySector || prevProps.filters.establishmentSize != this.props.filters.establishmentSize
            || prevProps.filters.country != this.props.filters.country || prevProps.filters.country2 != this.props.filters.country2){
            this.getQuestionFilters();
        }
    }

    //Function to set a title depending on the year selected
    setSelectorTitle = () => {
        // console.log("enters setSelectorTitle");
        if(this.props.year === "2009"){
            return this.props.localeLiterals.L101007;
        }else if(this.props.year === "2014"){
            return this.props.localeLiterals.L101008;
        }else if(this.props.year === "2019"){
            return this.props.localeLiterals.L101079;
        }
    }

    //Function to slice question name in case it is too long
    trim = (text,type) => {
        // console.log("text from trim",text);
        var trimText = '';
            if(this.props.questionProperties != null){
                if(this.props.questionProperties.nextCategory === null && type === 'n'){
                    text = this.props.localeLiterals.L496; //'An occupational health doctor'
                }

                if(this.props.questionProperties.previousCategory === null && type === 'p'){
                    text = this.props.localeLiterals.L556; //'Is there a health and safety committee in your establishment?';
                }
            }
            
            if(text != null){
                
                if(text.length > 10){
                    trimText = text.substring(0,10) + '...';
                    return trimText;
                }else{
                    return text;
                }
            }
    }

    //Function called to load the previous or next question of the actual one
    changeQuestion = (id, navType) => () => {
        // console.log("Selector.js changeQuestion function");
        let filters = {...this.props.filters};
        let question = id;
        if(question === null){
            if(this.props.year === "2009"){
                if(navType === 'n'){
                    filters.topic = "osh-management";
                    filters.question = 'MM150_1';
                    question = 'MM150_1';
                }else if(navType === 'p'){
                    filters.topic = "worker-participation";
                    filters.question = 'MM358';
                    question = 'MM358';
                }
            }else if (this.props.year === "2014") {
                if(navType === 'n'){
                    filters.topic = "osh-management";
                    filters.question = 'Q150_1';
                    question = 'Q150_1';
                }else if(navType === 'p'){
                    filters.topic = "worker-participation";
                    filters.question = 'Q358';
                    question = 'Q358';

                }
            } else {
                if(navType === 'n'){
                    filters.topic = "osh-management";
                    filters.question = 'E3Q151_1';
                    question = 'E3Q151_1';
                }else if(navType === 'p'){
                    filters.topic = "worker-participation";
                    filters.question = 'E3Q357';
                    question = 'E3Q357';
                }
            }
        }else{
            if(navType === 'n'){
                if(question === "MM202_1" || question === "Q201_1"){
                    filters.topic = "psychosocial-risks-and-their-management";
                }else if(question === "E3Q201_1"){
                    filters.topic = "emerging-risks-and-their-management";
                }else if(question === "MM171_1" || question === "Q264_1" || question === "E3Q262_1"){
                    filters.topic = "drivers-and-barriers";
                }
                else if(question === "MM350" || question === "Q166_1" || question === "E3Q258"){
                    filters.topic = "worker-participation";
                }
            }else{
                if(question === "MM200_7" || question === "Q356_5" || question === "E3Q355_6"){
                    filters.topic = "osh-management";
                }else if(question === "MM260" || question === "Q307"){
                    filters.topic = "psychosocial-risks-and-their-management";
                }else if(question === "E3Q312_8"){
                    filters.topic = "emerging-risks-and-their-management";
                }else if(question === "MM303a" || question === "Q166_1" || question === "E3Q308_4"){
                    filters.topic = "drivers-and-barriers";
                }
            }

            if(question.match("Q261")){
                filters.sectorSize = "company-size";
            }
        }

        /*if(topicId === 1){
			return "osh-management";
		}else if(topicId === 5){
			return "emerging-risks-and-their-management";
		}else if(topicId === 8){
			return "drivers-and-barriers";
		}else if(topicId === 11){
			return "worker-participation";
		} */

        //Call data load again with new question
        //Check if new question has exceptions
        //If not change url 
        var exception = this.props.rules(question);

        if(!exception){
            if(question != null){
                filters.question = question;
            }
            this.props.callback(filters, this.props.chart);
        }

    }

    //Function to change local language INCOMPLETED
    changeLocale = (event) => {
        // console.log("changeLocal function");
        const newLocale = event.target.value;

        this.props.setLocale(newLocale);

        var url = location.pathname.split('/');
        // The locale is in the same position in the data visualisation and in the comparisons pages
        url[6] = newLocale;
        url = url.join('/');

        /*history.push({
            pathname: url
        })*/
    }

    //Function to create title of previous and next question
    isNull = (questionName, type) => {
        var text = this.props.localeLiterals["L"+questionName];
        
        if(this.props.questionProperties != null){
            if(this.props.questionProperties.previousLevel != 1 && type === 'p'){
                if(this.props.questionProperties.previousLiteralID === null){
                    text = this.props.localeLiterals.L556; //Is there a health and safety committee in your establishment?
                }else{
                    // return this.props.literals['L'+this.props.questionProperties.previousFatherLiteralID] + ': ' + text;
                    text = this.props.localeLiterals['L'+this.props.questionProperties.previousFatherLiteralID] + ': ' + text;
                }
            }else if(this.props.questionProperties.nextLevel != 1 && type === 'n'){
                if(this.props.questionProperties.nextLiteralID === null && type === 'n'){
                    text = this.props.localeLiterals.L100003 + ': ' + this.props.localeLiterals.L496; //Use of health and safety services: An occupational health doctor
                }else{
                    // return this.props.literals['L'+this.props.questionProperties.nextFatherLiteralID] + ': ' + text;
                    text = this.props.localeLiterals['L'+this.props.questionProperties.nextFatherLiteralID] + ': ' + text;
                }
            }
        }
        
        return text;
    }

    updateChart = (filterChanged) => (event) => {
        event.preventDefault();
        let filters = {...this.props.filters};
        // console.log("updateChart function");
        // console.log("filters",filters);
        // console.log("event.target.value",event.target.value);
        $('.bar-header').removeClass('hide-header');
        if(filterChanged == 'activitySector'){
            filters.sectorSize = "activity-sector";
            filters.activitySector = event.target.value;
            filters.establishmentSize = "11";
        }else if(filterChanged == 'establishmentSize'){
            filters.sectorSize = "company-size";
            filters.activitySector = "14";
            filters.establishmentSize = event.target.value;
        }

        if(this.props.chart == 'european-map' || this.props.chart == 'european-bar-chart'){
            if(filterChanged == 'euOnly'){
                filters.euOnly = filters.euOnly === 0 ? 1 : 0;
            }else if(filterChanged === "answer"){
                // console.log(filterChanged,event.target.value);
                filters.answer = event.target.value;
            }else if(filterChanged === "sortBy"){
                filters.sortBy = event.target.value;
            }
        }/*else if(this.props.chart == 'national-comparisons'){
            console.log("Filters changed in national comparisons");
        }*/else{
            switch (filterChanged)
            {
                case "activitySector":
                    console.log("activitySector changed");
                    break;
                case "establishmentSize":
                    console.log("establishmentSize changed");
                    break;
                case "country":
                    console.log("country changed");
                    filters.country = event.target.value;
                    // scope.country = scope.filters.country;
                    break;
                case "country2":
                    console.log("country2 changed");
                    filters.country2 = event.target.value;
                    break;
                case "answer":
                    console.log("answer changed");
                    filters.answer = event.target.value;
                    break;
                case "activityToCompany":
                    console.log("activityToCompany");
                    filters.sectorSize = "company-size";
                    break;
                case "companyToActivity":
                    console.log("companyToActivity");
                    filters.sectorSize = "activity-sector";
                    break;
                case "euOnly":
                    console.log("eu only changed");
                    filters.euOnly = (euOnly === 0) ? 1 : 0;
                    
                    if(filters.euOnly === 1){
                        $('#eu-only').add('selected-chart');
                        // angular.element('#eu-only').addClass('selected-chart');
                    }else{
                        $('#eu-only').removeClass('selected-chart');
                        // angular.element('#eu-only').removeClass('selected-chart');
                    }
                    break;
                default:
                    console.log("No change detected");
            }
            // scope.filters.activitySector = scope.filters.activitySector == 0? null:scope.filters.activitySector;
			// scope.filters.establishmentSize = scope.filters.establishmentSize == 0? null:scope.filters.establishmentSize;
        }

        // console.log("local filters before callback",filters);
        this.props.callback(filters, this.props.chart);
    }

    changeChart = (newChart) => () => {
        let filters = {...this.props.filters};
        var exception = false;

        //ESENER 2009
        if(newChart === 'national-bar-chart' || newChart === 'national-comparisons' || newChart === 'pie-chart'){
            if(this.props.filters.country === undefined){
                filters.country = this.props.year === "2019" ? "EU27_2020" : "EU28";
            }

            // if(this.props.filters.question === 'MM303a' && (this.props.filters.country === 'CY' || this.props.filters.country === 'EE'
            //     || this.props.filters.country === 'MT') && newChart === 'pie-chart'){
            //     alert(this.props.localeLiterals.L100576);
            //     exception = true;
            // }
            // if(newChart === 'national-comparisons'){
            //     if(this.props.filters.question === 'MM303a' && (this.props.filters.country2 === 'CY' || this.props.filters.country2 === 'EE'
            //         || this.props.filters.country2 === 'MT')){
            //         alert(this.props.localeLiterals.L100576);
            //         exception = true;
            //     }
            // }
        }

        if(this.props.year === "2009"){
            if(this.props.filters.question === "MM303a"){
				if(newChart === 'national-comparisons' || newChart === 'national-bar-chart'){
					if(this.props.filters.country === "CY" || this.props.filters.country === "EE" || this.props.filters.country === "MT"){
						alert(this.props.literals.L100576);
						// return true;
                        exception = true;
					}
				}

				if(newChart === 'national-comparisons'){
					if(this.props.filters.country2 === "CY" || this.props.filters.country2 === "EE" || this.props.filters.country2 === "MT"){
						alert(this.props.literals.L100576);
						// return true;
                        exception = true;
					}
				}
			}else if(this.props.filters.question === "MM350"){
				if(newChart === 'national-comparisons' || newChart === 'national-bar-chart' || newChart === 'pie-chart'){
					if(this.props.filters.country === "CY" || this.props.filters.country === "MT" || this.props.filters.country === "SE"){
						alert(this.props.literals.L100576);
						// return true;
                        exception = true;
					}
				}

				if(newChart === 'national-comparisons'){
					if(this.props.filters.country2 === "CY" || this.props.filters.country2 === "MT" || this.props.filters.country2 === "SE"){
						alert(this.props.literals.L100576);
						// return true;
                        exception = true;
					}
				}
			}else if(this.props.filters.question === "MM351"){
				if(newChart === 'national-comparisons' || newChart === 'national-bar-chart'){
					if(this.props.filters.country === "AT" || this.props.filters.country === "DE" || this.props.filters.country === "LU"){
						alert(this.props.literals.L100576);
						// return true;
                        exception = true;
					}
				}

				if(newChart === 'national-comparisons'){
					if(this.props.filters.country2 === "AT" || this.props.filters.country2 === "DE" || this.props.filters.country2 === "LU"){
						alert(this.props.literals.L100576);
						// return true;
                        exception = true;
					}
				}
			}else if(this.props.filters.question === "MM355"){
				if(newChart === 'national-comparisons' || newChart === 'national-bar-chart'){
					if(this.props.filters.country === "CH"){
						alert(this.props.literals.L100576);
						// return true;
                        exception = true;
					}
				}

				if(newChart === 'national-comparisons'){
					if(this.props.filters.country2 === "CH"){
						alert(this.props.literals.L100576);
						// return true;
                        exception = true;
					}
				}
			}else if(this.props.filters.question === "MM358"){
				if(newChart === 'national-comparisons' && newChart === 'national-bar-chart'){
					if(this.props.filters.country === "LU" || this.props.filters.country === "SI"){
						alert(this.props.literals.L100576);
						// return true;
                        exception = true;
					}
				}

				if(newChart === 'national-comparisons'){
					if(this.props.filters.country2 === "LU" || this.props.filters.country2 === "SI"){
						alert(this.props.literals.L100576);
						// return true;
                        exception = true;
					}
				}
			}
        }

        //ESENER 2014
        if((this.props.filters.question == 'Q202_2' || this.props.filters.question == 'Q202_3' || this.props.filters.question == 'Q202_5'
            || this.props.filters.question == 'Q202_6' || this.props.filters.question == 'Q202_8' || this.props.filters.question == 'Q202_9'
            || this.props.filters.question == 'Q202_11' || this.props.filters.question == 'Q202_12' || this.props.filters.question == 'Q202_13'
            || this.props.filters.question == 'Q202_15' || this.props.filters.question.match('Q251') != undefined 
            || this.props.filters.question.match('Q252') != undefined || this.props.filters.question == 'Q254gr' 
            || this.props.filters.question == 'Q255' || this.props.filters.question.match('Q256') != undefined
            || this.props.filters.question == 'Q258b' || this.props.filters.question == 'Q259' || this.props.filters.question == 'Q308_1'
            || this.props.filters.question == 'Q354') && this.props.filters.activitySector == 6 && (newChart == 'european-map' 
            || newChart == 'european-bar-chart')){
            alert(this.props.localeLiterals.L100576);
            exception = true;
        }

        if(this.props.filters.question.match('Q261')){
            if(newChart == 'national-bar-chart'){
                exception = true;
            }

            if(this.props.filters.sectorSize == 'activity-sector'){
                filters.sectorSize = "company-size";
                filters.establishmentSize = "11";
            }

            if(newChart === "european-bar-chart" || newChart === "european-map"){
                if(this.props.filters.sectorSize == 'company-size' && 
                    (this.props.filters.establishmentSize === "14" || this.props.filters.establishmentSize === "10")){
                        exception = true;
                }
            }

            if(newChart == 'national-comparisons'){
                if(this.props.filters.sectorSize == 'company-size' && this.props.filters.establishmentSize === "10"){
                        exception = true;
                }
            }
        }

        //$log.warn(angular.element('.chart--selection--block li')[2]);
        // if(this.props.filters.question.match('Q261') && newChart == 'national-bar-chart'){
        //     exception = true;
        // }

        // if(this.props.filters.question.match('Q261') && this.props.filters.sectorSize == 'activity-sector'){
        //     filters.sectorSize = "company-size";
        //     filters.establishmentSize = "11";
        // }

        if(this.props.filters.country2 === undefined){
            filters.country2 = this.props.year == '2019' ? "EU27_2020" : "EU28" ;
        }

        if(newChart == 'national-comparisons'){
            if(this.props.filters.country == this.props.filters.country2){
                if(this.props.filters.country == 'EU28' || this.props.filters.country == 'EU27_2020'){
                    filters.country2 = "AT";
                }else{
                    if (this.props.year == "2019") {
                        filters.country2 = "EU27_2020";
                    }
                    else{
                        filters.country2 = this.props.filters.country2 === undefined ? "AT" : "EU28";
                    }								
                }
            }
        }

        if(!exception){
            if($("ul.chart--selection--block").hasClass("open")){
                $("ul.chart--selection--block").removeClass("open");
            }
            this.props.callback(filters, newChart);
        }else{
            alert(this.props.literals.L100576);
        }
    }
    
    //Open chart selector option when user is in mobile
    openOptions = () => (event) => {
        // console.log("openOptions function");
        var parentNode = event.target.parentElement;
        // var nodeName = event.target.nodeName;

        if( event.target.className == "no-event" ){
            parentNode = event.target.parentElement.parentElement;
        }

        if(parentNode.className.indexOf("open") != -1){
            // parentNode.removeClass('open');
            parentNode.classList.remove("open")
        } else {
            // parentNode.addClass('open');
            parentNode.className += " open";
        }
    }

    //ESENER RULES for the country selectors
    rulesForSelects = (generalDataList) => {
        // console.log("rulesForSelects function", generalDataList);
        var firstCountryList = [];
        var secondCountryList = [];
        var activitySectorList = [];
        var establishmentSizeList = [];
        var currentCountry = null;
        var currentActivity = null;
        var currentEstablishment = null;

        for(var i=0; i<generalDataList.countries.length;i++){
            currentCountry = generalDataList.countries[i];
            // console.log("currentCountry",currentCountry);
            switch(this.props.filters.question){
                case 'MM303a':
                    if(currentCountry.code != 'CY' && currentCountry.code != 'EE' && currentCountry.code != 'MT'){
                        if(this.props.chart == 'national-comparisons'){
                            if(currentCountry.code != this.props.filters.country2){
                                firstCountryList.push(currentCountry);
                            }

                            if(currentCountry.code != this.props.filters.country){
                                secondCountryList.push(currentCountry);
                            }
                        }
                    }

                    if (this.props.chart == 'pie-chart') {
                        firstCountryList.push(currentCountry);
                    }
                    
                    if(currentCountry.code != 'CY' && currentCountry.code != 'EE' && currentCountry.code != 'MT'){
                        if(this.props.chart == 'national-bar-chart' /*|| this.props.chart == 'pie-chart'*/){
                            firstCountryList.push(currentCountry);
                        }
                    }

                    break;
                case 'MM350':
                    if(currentCountry.code != 'CY' && currentCountry.code != 'MT' && currentCountry.code != 'SE'){
                        if(this.props.chart == 'national-comparisons'){
                            if(currentCountry.code != this.props.filters.country2){
                                firstCountryList.push(currentCountry);
                            }

                            if(currentCountry.code != this.props.filters.country){
                                secondCountryList.push(currentCountry);
                            }
                        }else if(this.props.chart == 'national-bar-chart' || this.props.chart == 'pie-chart'){
                            firstCountryList.push(currentCountry);
                        }
                    }
                    break;
                case 'MM351':
                    if(currentCountry.code != 'AT' && currentCountry.code != 'DE' && currentCountry.code != 'LU'){
                        if(this.props.chart == 'national-comparisons'){
                            if(currentCountry.code != this.props.filters.country2){
                                firstCountryList.push(currentCountry);
                            }

                            if(currentCountry.code != this.props.filters.country){
                                secondCountryList.push(currentCountry);
                            }
                        }else if(this.props.chart == 'national-bar-chart' || this.props.chart == 'pie-chart'){
                            firstCountryList.push(currentCountry);
                        }
                    }
                    break;
                case 'MM355all':
                    if(currentCountry.code != 'CH'){
                        if(this.props.chart == 'national-comparisons'){
                            if(currentCountry.code != this.props.filters.country2){
                                firstCountryList.push(currentCountry);
                            }

                            if(currentCountry.code != this.props.filters.country){
                                secondCountryList.push(currentCountry);
                            }
                        }else if(this.props.chart == 'national-bar-chart' || this.props.chart == 'pie-chart'){
                            firstCountryList.push(currentCountry);
                        }
                    }
                    break;
                case 'MM358':
                    if(currentCountry.code != 'LU' && currentCountry.code != 'SI'){
                        if(this.props.chart == 'national-comparisons'){
                            if(currentCountry.code != this.props.filters.country2){
                                firstCountryList.push(currentCountry);
                            }

                            if(currentCountry.code != this.props.filters.country){
                                secondCountryList.push(currentCountry);
                            }
                        }else if(this.props.chart == 'national-bar-chart' || this.props.chart == 'pie-chart'){
                            firstCountryList.push(currentCountry);
                        }
                    }
                    break;
                default:
                    if(this.props.chart == 'national-comparisons'){

                        if(this.props.year == "2009"){
                            if(currentCountry.code != this.props.filters.country2){
                                firstCountryList.push(currentCountry);
                            }

                            if(currentCountry.code != this.props.filters.country){
                                secondCountryList.push(currentCountry);
                            }

                        }else if(this.props.year == "2014"){
                            //If 2014, company size, 50-249 not all countries in the select
                            if(this.props.filters.establishmentSize == 14){
                                if(this.props.filters.question.match('Q261') != undefined){
                                    if(currentCountry.code != 'AL' && currentCountry.code != 'ME' && currentCountry.code != 'BG' 
                                        && currentCountry.code != 'DK' && currentCountry.code != 'EE' && currentCountry.code != 'ES' 
                                        && currentCountry.code != 'HR' && currentCountry.code != 'IT' && currentCountry.code != 'LV' 
                                        && currentCountry.code != 'RO' && currentCountry.code != 'SE' && currentCountry.code != 'SI' 
                                        && currentCountry.code != 'UK' && this.props.filters.sectorSize == 'company-size'){
                                        if(currentCountry.code != this.props.filters.country2){
                                            firstCountryList.push(currentCountry);
                                        }

                                        if(currentCountry.code != this.props.filters.country){
                                            secondCountryList.push(currentCountry);
                                        }
                                    }
                                }else{
                                    if(currentCountry.code != this.props.filters.country2){
                                        firstCountryList.push(currentCountry);
                                    }

                                    if(currentCountry.code != this.props.filters.country){
                                        secondCountryList.push(currentCountry);
                                    }
                                }
                            }else if(this.props.filters.activitySector == 6){
                                if(this.props.filters.question === ('Q251') || (this.props.filters.question.match('Q252') != undefined && this.props.filters.question != 'Q252_2')
                                    || this.props.filters.question == 'Q254gr' || this.props.filters.question == 'Q255' || this.props.filters.question.match('Q256') != undefined
                                    || this.props.filters.question == 'Q258b' || this.props.filters.question == 'Q259'){
                                    if(currentCountry.code != 'AL' && currentCountry.code != 'IS' && currentCountry.code != 'ME'
                                        && currentCountry.code != 'MK' && currentCountry.code != 'CY' && currentCountry.code != 'EL' 
                                        && currentCountry.code != 'HR' && currentCountry.code != 'LT' && currentCountry.code != 'LU' 
                                        && currentCountry.code != 'MT' ){
                                        if(currentCountry.code != this.props.filters.country2){
                                            firstCountryList.push(currentCountry);
                                        }

                                        if(currentCountry.code != this.props.filters.country){
                                            secondCountryList.push(currentCountry);
                                        }
                                    }
                                }else{
                                    if(currentCountry.code != this.props.filters.country2){
                                        firstCountryList.push(currentCountry);
                                    }

                                    if(currentCountry.code != this.props.filters.country){
                                        secondCountryList.push(currentCountry);
                                    }
                                }
                            }else{
                                if(currentCountry.code != this.props.filters.country2){
                                    firstCountryList.push(currentCountry);
                                }

                                if(currentCountry.code != this.props.filters.country){
                                    secondCountryList.push(currentCountry);
                                }
                            }
                        }
                        else if (this.props.year == "2019")
                        {
                            if(currentCountry.code != this.props.filters.country2){
                                firstCountryList.push(currentCountry);
                            }

                            if(currentCountry.code != this.props.filters.country){
                                secondCountryList.push(currentCountry);
                            }	
                        }
                    }else if(this.props.chart == 'national-bar-chart' || this.props.chart == 'pie-chart'){
                        firstCountryList.push(currentCountry);
                    }
                    break;
            }
        }

        // console.log("firstCountryList",firstCountryList);

        for(var i=0; i<generalDataList.activitySectors.length;i++){
            currentActivity = generalDataList.activitySectors[i];
            // console.log("currentActivity",currentActivity);
            if(currentActivity.id != 1){
                switch(true){
                    case this.props.filters.question.match('Q202') != undefined: 
                        if((this.props.filters.question == 'Q202_2' || this.props.filters.question == 'Q202_3' || this.props.filters.question == 'Q202_5'
                            || this.props.filters.question == 'Q202_6' || this.props.filters.question == 'Q202_8' || this.props.filters.question == 'Q202_9'
                            || this.props.filters.question == 'Q202_11' || this.props.filters.question == 'Q202_12' || this.props.filters.question == 'Q202_13'
                            || this.props.filters.question == 'Q202_15') ){
                            if(this.props.chart === "european-map" || this.props.chart === "european-bar-chart"){
                                if(currentActivity.id != 6){
                                    activitySectorList.push(currentActivity);
                                }
                            }else{
                                activitySectorList.push(currentActivity);
                            }

                        }else{
                            // scope.activitySectors.push({id:elem[0], literal:elem[1]});
                            activitySectorList.push(currentActivity);
                        }
                    break;
                    case (this.props.filters.question.match('Q251') != undefined || this.props.filters.question.match('Q252') != undefined 
                        || this.props.filters.question == 'Q254gr' || this.props.filters.question == 'Q255' || this.props.filters.question.match('Q256') != undefined
                        || this.props.filters.question == 'Q258b' || this.props.filters.question == 'Q259' || this.props.filters.question == 'Q308_1'
                        || this.props.filters.question == 'Q354'):
                        if(this.props.chart === 'national-comparisons'){
                            if(this.props.filters.question != 'Q252_2' && this.props.filters.question != 'Q308_1' && this.props.filters.question != 'Q354'){
                                if((this.props.filters.country != 'AL' && this.props.filters.country2 != 'AL' && this.props.filters.country != 'IS' 
                                && this.props.filters.country2 != 'IS' && this.props.filters.country != 'ME' && this.props.filters.country2 != 'ME' 
                                && this.props.filters.country != 'MK' && this.props.filters.country2 != 'MK' && this.props.filters.country != 'CY' 
                                && this.props.filters.country2 != 'CY' && this.props.filters.country != 'EL' && this.props.filters.country2 != 'EL' 
                                && this.props.filters.country != 'HR' && this.props.filters.country2 != 'HR' && this.props.filters.country != 'LT' 
                                && this.props.filters.country2 != 'LT' && this.props.filters.country != 'LU' && this.props.filters.country2 != 'LU' 
                                && this.props.filters.country != 'MT' && this.props.filters.country2 != 'MT')){
                                    // scope.activitySectors.push({id:elem[0], literal:elem[1]});
                                    activitySectorList.push(currentActivity);
                                }else{
                                    if(currentActivity.id != 6){
                                        // scope.activitySectors.push({id:elem[0], literal:elem[1]});
                                        activitySectorList.push(currentActivity);
                                    }
                                }
                            }else{
                                // scope.activitySectors.push({id:elem[0], literal:elem[1]});
                                activitySectorList.push(currentActivity);
                            }
                        }else{
                            if(currentActivity.id != 6){
                                // scope.activitySectors.push({id:elem[0], literal:elem[1]});
                                activitySectorList.push(currentActivity);
                            }
                        }
    
                    break;
                    case (this.props.filters.question.match('Q261') != undefined):
                        //If national comparisons public administration not in select
                        if(currentActivity.id != 6 && this.props.chart == 'national-comparisons'){
                            // scope.activitySectors.push({id:elem[0], literal:elem[1]});
                            activitySectorList.push(currentActivity);
                        }
                    break;
                    default: 
                        // scope.activitySectors.push({id:elem[0], literal:elem[1]});
                        activitySectorList.push(currentActivity);
                    break;
                }
            }
        }

        for(var i=0; i<generalDataList.establishmentSizes.length;i++){
            currentEstablishment = generalDataList.establishmentSizes[i];
            switch(true){
                case this.props.filters.question.match('Q202') != undefined:
                    // scope.establishmentSizes.push({id:elem[0], literal:elem[1]});
                    establishmentSizeList.push(currentEstablishment);
                break;
                case (this.props.filters.question.match('Q251') != undefined || this.props.filters.question.match('Q252') != undefined 
                || this.props.filters.question == 'Q254gr' || this.props.filters.question == 'Q255' || this.props.filters.question.match('Q256') != undefined
                || this.props.filters.question == 'Q258b' || this.props.filters.question == 'Q259' || this.props.filters.question == 'Q308_1'
                || this.props.filters.question == 'Q354'):
                    if(this.props.chart == 'national-comparisons'){
                        establishmentSizeList.push(currentEstablishment);
                        // scope.establishmentSizes.push({id:elem[0], literal:elem[1]});
                    }else{
                        establishmentSizeList.push(currentEstablishment);
                        // scope.establishmentSizes.push({id:elem[0], literal:elem[1]});
                    }
                break;
                case (this.props.filters.question.match('Q261') != undefined):
                    //European map, european bar chart
                    //If european map or european bar chart and not +250 or 50-249
                    if(this.props.chart == 'european-map' || this.props.chart == 'european-bar-chart'){
                        if(currentEstablishment.id != 14 && currentEstablishment.id != 10){
                            // scope.establishmentSizes.push({id:elem[0], literal:elem[1]});
                            establishmentSizeList.push(currentEstablishment);
                        }
                    }else{
                        //If national comparisons, +250 not in select
                        if(currentEstablishment.id != 10){
                            //If national comparisons, selected countries 50-249 not in select
                            if(currentEstablishment.id == 14 && (this.props.filters.country != 'AL' && this.props.filters.country2 != 'AL'
                                && this.props.filters.country != 'ME' && this.props.filters.country2 != 'ME' && this.props.filters.country != 'BG' 
                                && this.props.filters.country2 != 'BG' && this.props.filters.country != 'DK' && this.props.filters.country2 != 'DK'
                                && this.props.filters.country != 'EE' && this.props.filters.country2 != 'EE' && this.props.filters.country != 'ES' 
                                && this.props.filters.country2 != 'ES' && this.props.filters.country != 'HR' && this.props.filters.country2 != 'HR'
                                && this.props.filters.country != 'IT' && this.props.filters.country2 != 'IT' && this.props.filters.country != 'LV' 
                                && this.props.filters.country2 != 'LV' && this.props.filters.country != 'RO' && this.props.filters.country2 != 'RO'
                                && this.props.filters.country != 'SE' && this.props.filters.country2 != 'SE' && this.props.filters.country != 'SI' 
                                && this.props.filters.country2 != 'SI' && this.props.filters.country != 'UK' && this.props.filters.country2 != 'UK')){
                                // scope.establishmentSizes.push({id:elem[0], literal:elem[1]});
                                establishmentSizeList.push(currentEstablishment);
                            //Rest of countries
                            }else if(currentEstablishment.id != 14){
                                // scope.establishmentSizes.push({id:elem[0], literal:elem[1]});
                                establishmentSizeList.push(currentEstablishment);
                            }
                        }
                    }
                break;
                default:
                    // scope.establishmentSizes.push({id:elem[0], literal:elem[1]});
                    establishmentSizeList.push(currentEstablishment);
                break;
            }
        }
        // When in 2014 - reverse order of activity sector combo.
        if (this.props.year == '2014') {
            activitySectorList.reverse();
        }

        this.setState({
            selectCountries: firstCountryList,
            selectCountries2: secondCountryList,
            selectActivitySectors: activitySectorList,
            selectEstablishmentSizes: establishmentSizeList
        });
    }

    render(){
        // console.log("this.state",this.state);

        var localeLanguageSelect = "";
        //ACTIVITY SECTOR SELECT FILTERS
        var activitySectorSelect = "";
        //ESTABLISHMENT SIZE SELECT FILTERS
        var establishmentSizeSelect = "";
        //FIRST COUNTRY SELECT FILTER
        var firstCountrySelectFilter = "";
        //COMPARE WITH SELECT FILTER (COUNTRY 2)
        var compareWithCountrySelect = "";
        //ANSWER / SORT BY SELECT FILTER
        var answerSortByContainer = "";
        //BUTTONS FOR ACTIVITY SECTOR, COMPANY SIZE AND COUNTRY SELECT
        var nationalBarChartSelectFilters = "";
        
        if(this.props.chart === "european-map"){
            //ACTIVITY SECTOR SELECT FILTERS
            activitySectorSelect = (
                <div className={`filter-text activity-sector ${this.props.filters.sectorSize === "activity-sector" ? "active" : "" }`}>
                    <label htmlFor="sector" className="activity-sector">{this.props.localeLiterals.L100573}</label> 
                    <select id="sector" onChange={this.updateChart('activitySector')} value={this.props.filters.activitySector}
                        className={`${this.state.selectActivitySectors.length === 0 ? "disabled" : ""} `} 
                        disabled={`${this.props.filters.question.match("Q261") ? "disabled" : ""}`}>
                        {
                            this.state.selectActivitySectors.length <= 0 ? <option value="14">{this.props.localeLiterals["L661"]}</option> : (
                                this.state.selectActivitySectors.map((activity) => (
                                    <option key={activity.id} value={activity.id}>{this.props.localeLiterals["L"+activity.literalID]}</option>
                                ))
                            )
                        }
                    </select>
                </div>
            );

            //ESTABLISHMENT SIZE SELECT FILTERS
            establishmentSizeSelect = (
                <div className={`filter-text establishment-size ${this.props.filters.sectorSize == 'company-size' ? "active" : ""}
                ${(this.state.langSize[0].indexOf(this.state.localLanguage) != -1) ? "long1" : ""} 
                ${(this.state.langSize[1].indexOf(this.state.localLanguage) != -1) ? "long2" : ""} 
                ${(this.state.langSize[2].indexOf(this.state.localLanguage) != -1) ? "long3" : ""} `}>
                    <label htmlFor="establishment" className="establishment-size">{this.props.localeLiterals.L100574}</label> 
                    <select id="establishment" className="" onChange={this.updateChart('establishmentSize')} value={this.props.filters.establishmentSize}>
                        {
                            this.state.selectEstablishmentSizes.map((establishment) => (
                                <option key={establishment.id} value={establishment.id}>{this.props.localeLiterals["L"+establishment.literalID]}</option>
                            ))
                        }
                    </select>
                </div>
            );

            //FIRST COUNTRY SELECT FILTER
            firstCountrySelectFilter = (
                <div className="filter-text country-items disabled">
                    <label htmlFor="datafor" className="disabled">{this.props.localeLiterals.L100589}</label>
                    <select id="datafor" className="" disabled="disabled" value="0">
                        <option value="0">{this.props.localeLiterals.L100589}</option>
                    </select>
                </div>
            );

            //ANSWER / SORT BY SELECT FILTER
            answerSortByContainer = (
                <div className="filter-text sort-by">
                    <label htmlFor="sortBy">{this.props.localeLiterals.L100588}:</label>
                    <select id="sortBy" className="" onChange={this.updateChart('answer')} value={this.props.filters.answer}>
                        {
                            this.state.questionFilters.answers != undefined ? this.state.questionFilters.answers.map((answer) => (
                                <option key={answer.id} value={answer.id}>{this.props.localeLiterals["L"+answer.literalID]}</option>
                            )) : <option value="0">Answer</option>
                        }
                    </select>
                </div>
            )
        }else if(this.props.chart === "european-bar-chart"){
            //ACTIVITY SECTOR SELECT FILTERS
            activitySectorSelect = (
                <div className={`filter-text activity-sector ${this.props.filters.sectorSize === "activity-sector" ? "active" : "" } `}>
                    <label htmlFor="sector" className="activity-sector">{this.props.localeLiterals.L100573}</label> 
                    <select id="sector" onChange={this.updateChart('activitySector')} value={this.props.filters.activitySector}
                        className={`${this.state.selectActivitySectors.length === 0 ? "disabled" : ""} `}
                        disabled={`${this.props.filters.question.match("Q261") ? "disabled" : ""}`}>
                        {
                            this.state.selectActivitySectors.length <= 0 ? <option value="14">{this.props.localeLiterals["L661"]}</option> : (
                                this.state.selectActivitySectors.map((activity) => (
                                    <option key={activity.id} value={activity.id}>{this.props.localeLiterals["L"+activity.literalID]}</option>
                                ))
                            )
                        }
                    </select>
                </div>
            );

            //ESTABLISHMENT SIZE SELECT FILTERS
            establishmentSizeSelect = (
                <div className={`filter-text establishment-size ${this.props.filters.sectorSize == 'company-size' ? "active" : ""}
                ${(this.state.langSize[0].indexOf(this.state.localLanguage) != -1) ? "long1" : ""} 
                ${(this.state.langSize[1].indexOf(this.state.localLanguage) != -1) ? "long2" : ""} 
                ${(this.state.langSize[2].indexOf(this.state.localLanguage) != -1) ? "long3" : ""} `}>
                    <label htmlFor="establishment" className="establishment-size">{this.props.localeLiterals.L100574}</label> 
                    <select id="establishment" className="" onChange={this.updateChart('establishmentSize')} value={this.props.filters.establishmentSize}>
                        {
                            this.state.selectEstablishmentSizes.map((establishment) => (
                                <option key={establishment.id} value={establishment.id}>{this.props.localeLiterals["L"+establishment.literalID]}</option>
                            ))
                        }
                    </select>
                </div>
            );

            //FIRST COUNTRY SELECT FILTER
            firstCountrySelectFilter = (
                <div className="filter-text country-items disabled">
                    <label htmlFor="datafor" className="disabled">{this.props.localeLiterals.L100589}</label>
                    <select id="datafor" className="" disabled="disabled" value="0">
                        <option value="0">{this.props.localeLiterals.L100589}</option>
                    </select>
                </div>
            );

            //ANSWER / SORT BY SELECT FILTER
            answerSortByContainer = (
                <div className="filter-text sort-by">
                    <label htmlFor="sortBy">{this.props.localeLiterals.L101030}:</label>
                    <select id="sortBy" className="" onChange={this.updateChart('sortBy')} value={this.props.filters.sortBy}>
                        <option value="0">{this.props.localeLiterals.L101031}</option>
                        {
                            this.state.questionFilters.answers.map((answer) => (
                                // <option key={answer.id} value={answer.id}>{this.props.literals["L"+answer.literalID]}</option>
                                <option key={answer.id} value={answer.id}>{this.props.localeLiterals.L100588}: {this.props.localeLiterals["L"+answer.literalID]}</option>
                                
                            ))
                        }
                    </select>
                </div>
            )
        }else if(this.props.chart === "national-bar-chart"){
            //BUTTONS FOR ACTIVITY SECTOR, COMPANY SIZE AND COUNTRY SELECT
            nationalBarChartSelectFilters = (
                <div>
                    <form className={`block--filter--wrapper ${(this.state.langSize[0].indexOf(this.state.localLanguage) != -1 ) ? this.state.localeLanguage : ""}`}>
                        <p className="tags">
                            <span>{this.props.localeLiterals.L101026}</span>
                        </p>
                        {/* <!-- Activity sector --> */}
                        <div className="filter-text-button">
                            <button className={`${(this.props.filters.sectorSize === 'activity-sector') ? "active" : ""}`} onClick={this.updateChart('companyToActivity')} 
                                // ui-sref="detailpage-national-bar-chart({ pTopic: topic, pChart: 'national-bar-chart', pSectorSize: 'activity-sector', pCountry: filters.country, pQuestion: indicator, pLanguage: pLanguage, pLocale: pLocale, pAnswer: answer})"
                            >{this.props.localeLiterals.L100573}</button>
                        </div>
                        <p className="tags">
                            <span>{this.props.localeLiterals.L101027}</span>
                        </p>       	
                        {/* <!-- Establishment size --> */}
                        <div className="filter-text-button">
                            <button className={`${(this.props.filters.sectorSize === 'company-size') ? 'active' : ''}`} onClick={this.updateChart('activityToCompany')} 
                                // ui-sref="detailpage-national-bar-chart({ pTopic: topic, pChart: 'national-bar-chart', pSectorSize: 'company-size', pCountry: filters.country, pQuestion: indicator, pLanguage: pLanguage, pLocale: pLocale, pAnswer: answer})"
                            >{this.props.localeLiterals.L100574}</button>
                        </div>
                        {/* <!-- Country --> */}
                        <div className="filter-text-button country-select">
                            <select onChange={this.updateChart('country')} value={this.props.filters.country}>
                                {
                                    this.state.selectCountries.map((country) => (
                                        <option key={country.code} value={country.code}>{(country.code != 'EU28' && country.code != 'EU27_2020') ? `(${country.code}) ${this.props.localeLiterals["L"+country.literalID]}` : `${country.code}` }</option>
                                    ))
                                }
                            </select>
                        </div>
                    </form>
                </div>
            )
        }else if(this.props.chart === "national-comparisons"){
            //ACTIVITY SECTOR SELECT FILTER
            activitySectorSelect = (
                <div className={`filter-text activity-sector filter-national-comparisons ${this.props.filters.sectorSize === 'activity-sector' ? "active" : ""}`} >
                    <label htmlFor="sector" className="activity-sector">{this.props.localeLiterals.L100573}</label> 
                    <select id="sector" onChange={this.updateChart('activitySector')} value={this.props.filters.activitySector}
                        className={`${this.props.filters.question.match("Q261") ? "disabled" : ""} `} disabled={`${this.props.filters.question.match("Q261") ? "disabled" : ""}`}>
                        {
                            this.state.selectActivitySectors.map((activity) => (
                                <option key={activity.id} value={activity.id}>{this.props.localeLiterals["L"+activity.literalID]}</option>
                            ))
                        }
                    </select>
                </div>
            );
            
            //ESTABLISHMENT SIZE SELECT FILTER
            establishmentSizeSelect = (
                <div className={`filter-text establishment-size filter-national-comparisons ${this.props.filters.sectorSize === 'company-size' ? "active" : ""} 
                    ${(this.state.langSize[0].indexOf(this.state.localLanguage) != -1) ? "long1" : ""} 
                    ${(this.state.langSize[1].indexOf(this.state.localLanguage) != -1) ? "long2" : ""} 
                    ${(this.state.langSize[2].indexOf(this.state.localLanguage) != -1) ? "long3" : ""} `} >
                    <label htmlFor="establishment" className="establishment-size">{this.props.localeLiterals.L100574}</label> 
                    <select id="establishment" className="" onChange={this.updateChart('establishmentSize')} value={this.props.filters.establishmentSize}>
                        {
                            this.state.selectEstablishmentSizes.map((establishment) => (
                                <option key={establishment.id} value={establishment.id}>{this.props.localeLiterals["L"+establishment.literalID]}</option>
                            ))
                        }
                    </select>
                </div>
            );

            //FIRST COUNTRY SELECT FILTER
            firstCountrySelectFilter = (
                <div className="filter-text country-items countryComparison">
                    <label htmlFor="datafor" className="">{this.props.localeLiterals.L100589}</label>
                    <select className="comparisons" id="datafor" onChange={this.updateChart('country')} 
                    value={this.props.filters.country} >
                        {
                            this.state.selectCountries.map((country) => (
                                <option  key={country.code} value={country.code}>{(country.code != 'EU28' && country.code != 'EU27_2020') ? `(${country.code}) ${this.props.localeLiterals["L"+country.literalID]}` : `${country.code}`}</option>
                            ))
                        }
                    </select>
                </div>
            );
            //COMPARE WITH SELECT FILTER (COUNTRY 2)
            compareWithCountrySelect = (
                <div className="filter-text country-items countryComparisonWith">
                    <label htmlFor="comparewith" className="">{this.props.localeLiterals.L100590}</label>
                    <select id="comparewith" onChange={this.updateChart('country2')} value={this.props.filters.country2}>
                        {
                            this.state.selectCountries2.map((country2) => (
                                <option key={country2.code} value={country2.code}>{(country2.code != 'EU28' && country2.code != 'EU27_2020') ? `(${country2.code}) ${this.props.localeLiterals["L"+country2.literalID]}` : `${country2.code}` }</option>
                            ))
                        }
                    </select>
                </div>
            )
        }else if(this.props.chart === "pie-chart"){
            //ACTIVITY SECTOR SELECT FILTER
            activitySectorSelect = (
                <div className="filter-text activity-sector disabled">
                    <label htmlFor="sector" className="activity-sector disabled">{this.props.localeLiterals.L100573}</label> 
                    <select id="sector" className="disabled" onChange={this.updateChart('activitySector')} disabled value="0">
                        <option value="0">{this.props.localeLiterals.L100573}</option>
                    </select>
                </div>
            );
            
            //ESTABLISHMENT SIZE SELECT FILTER
            establishmentSizeSelect = (
                <div className="filter-text establishment-size disabled">
                    <label htmlFor="sector" className="establishment-size disabled">{this.props.localeLiterals.L100574}</label>
                    <select id="sector" className="disabled" onChange={this.updateChart('establishmentSize')} disabled value="0">
                        <option value="0">{this.props.localeLiterals.L100574}</option>
                    </select>
                </div>
            );

            //FIRST COUNTRY SELECT FILTER
            firstCountrySelectFilter = (
                <div className="filter-text country-items">
                    <label htmlFor="datafor" className="">{this.props.localeLiterals.L100589}</label>
                    <select id="datafor" onChange={this.updateChart('country')} 
                    value={this.props.filters.country}>
                        {
                            this.state.selectCountries.map((country) => (
                                <option key={country.code} value={country.code}>{(country.code != 'EU28' && country.code != 'EU27_2020') ? `(${country.code}) ${this.props.localeLiterals["L"+country.literalID]}` : `${country.code}` }</option>
                            ))
                        }
                    </select>
                </div>
            );
        }

        //LOCAL LANGUAGE SELECTS DEPENDING ON YEAR
        if(this.props.year === "2009"){
            localeLanguageSelect = (
                <div id="searchFilter">
                    <select style={{width: 140}} className="lang-dropdown-select-element form-select" id="lang-dropdown-select-language_content" 
                        accessKey="8" title="Language" name="lang_dropdown_select" onChange={this.changeLocale} value={this.state.localLanguage}>
                            {/* <!-- Select esener 2009 languages --> */}
                            <option value="bg">Български</option> {/* <!-- Bulgary --> */}
                            <option value="cs">Čeština</option>
                            <option value="da">Dansk</option>
                            <option value="de">Deutsch</option>
                            <option value="et">Eesti</option>
                            <option value="el">Ελληνικά</option>
                            <option value="en">English</option>
                            <option value="es">Español</option>
                            <option value="fr">Français</option>
                            <option value="it">Italiano</option>
                            <option value="hr">Hrvatski</option>
                            <option value="lv">Latviešu</option>
                            <option value="lt">Lietuvių</option>
                            <option value="hu">Magyar</option>
                            <option value="mt">Malti</option>
                            <option value="nl">Nederlands</option>
                            <option value="no">Norsk</option>
                            <option value="pl">Polski</option>
                            <option value="pt">Português</option>
                            <option value="ro">Română</option>
                            <option value="sk">Slovenčina</option>
                            <option value="sl">Slovenščina</option>
                            <option value="sv">Svenska</option>
                            <option value="fi">Suomi</option>
                            <option value="tr">Türkçe</option>
                    </select>
                </div>
            );
        }else{
            localeLanguageSelect = (
                <div>
                    <select style={{width:140}} className="lang-dropdown-select-element form-select" id="lang-dropdown-select-language_content" 
                        accessKey="8" title="Language" name="lang_dropdown_select" onChange={this.changeLocale} value={this.state.localLanguage}>
                        {/* <!-- Select esener 2014 languages --> */}
                        {
                            this.props.year === "2014" ? (
                                <option value="al">(AL) Shqipëria</option>
                            ) : "" 
                        }
                        <option value="de_1">(AT) Österreich</option>
                        <option value="fr_1">(BE) Belgique</option>
                        <option value="nl_1">(BE) België</option>
                        <option value="bg">(BG) България</option>
                        <option value="de_3">(CH) Schweiz</option>
                        <option value="fr_3">(CH) Suisse</option>
                        <option value="it_1">(CH) Svizzera</option>
                        <option value="el_1">(CY) Κύπρος</option>
                        <option value="cs">(CZ) Česko</option>                       
                        <option value="de">(DE) Deutschland</option>
                        <option value="da">(DK) Danmark</option>
                        <option value="et">(EE) Eesti</option>
                        <option value="ru_1">(EE) Эстония</option>
                        <option value="el">(EL) Ελλάδα</option>
                        <option value="es">(ES) España</option>
                        <option value="fi">(FI) Suomi</option>
                        <option value="sv_1">(FI) Finland</option>                    
                        <option value="fr">(FR) France</option> 
                        <option value="hr">(HR) Hrvatska</option>
                        <option value="hu">(HU) Magyarország</option>
                        <option value="en_1">(IE) Ireland</option>
                        <option value="is">(IS) Ísland</option>
                        <option value="it">(IT) Italia</option>
                        <option value="lt">(LT) Lietuva</option>
                        <option value="ru_3">(LT) Литва</option>
                        <option value="de_2">(LU) Luxemburg</option>
                        <option value="fr_2">(LU) Luxembourg</option>
                        <option value="lu">(LU) Lëtzebuerg</option>
                        <option value="lv">(LV) Latvija</option>
                        <option value="ru_2">(LV) Латвия</option>
                        {
                            this.props.year === "2014" ? (
                                <option value="me">(ME) Crna Gora</option>
                            ) : "" 
                        }
                        {
                            this.props.year === "2014" ? (
                                <option value="al_1">(MK) Maqedonia e Veriut</option>
                            ) : "" 
                        }
                        <option value="mk">(MK) Северна Македонија</option>
                        <option value="en_2">(MT) Malta (EN)</option>
                        <option value="mt">(MT) Malta (MT)</option>
                        <option value="nl">(NL) Nederland</option>
                        <option value="no">(NO) Norge</option>
                        <option value="pl">(PL) Polska</option>
                        <option value="pt">(PT) Portugal</option>
                        <option value="ro">(RO) România</option>
                        <option value="sr">(RS) Srbija</option>
                        <option value="sv">(SE) Sverige</option>
                        <option value="sl">(SI) Slovenija</option>
                        <option value="sk">(SK) Slovensko</option>
                        {
                            this.props.year === "2014" ? (
                                <option value="tr">(TR) Türkiye</option>
                            ) : "" 
                        }
                        <option value="en">(UK) United Kingdom</option>
                    </select>
                </div>
            );
        }

        // console.log("this.props.filters",this.props.filters)

        return(
            <div className="selector--page--elements">            
                {/* <!-- Title and introduction --> */}
                <div className="content--intro--wrapper">
                    <h1 className="title--section second-color">{this.setSelectorTitle()}</h1>
                    {/* <!-- Breadcrumb --> */}
                    <p>{ReactHtmlParser(this.state.breadcrumb)}</p>
                    <ul className="question--by--question">
                        <li><a onClick={this.changeQuestion(this.props.questionProperties.previousCategory, 'p')} title={this.isNull(this.props.questionProperties.previousLiteralID,'p')}>
                            {this.trim(this.props.localeLiterals["L"+this.props.questionProperties.previousLiteralID],'p')}</a>
                        </li>
                        <li><a onClick={this.changeQuestion(this.props.questionProperties.nextCategory, 'n')} title={this.isNull(this.props.questionProperties.nextLiteralID,'n')}>
                            {this.trim(this.props.localeLiterals["L"+this.props.questionProperties.nextLiteralID],'n')}</a>
                        </li>
                    </ul>
                </div>

                <div className="content--lang--wrapper">
                    {/* <!-- National version select --> */}
                    <div className="national-version-select">
                        <form action="" className="compare--block--form">
                            <label htmlFor="searchFilter">{this.props.localeLiterals.L101032}</label>
                            {/*localeLanguageSelect*/}
                            <LocaleSelect year={this.props.year} locale={this.props.locale} />
                        </form>
                    </div>      
                </div>

                {/* <!-- Chart section and indicator selection --> */}
                <div className="content--intro chart--wrapper">
                    {/* <!-- Question title --> */}
                    <h2 className="title-question main-color">{this.props.localeLiterals["L"+this.props.questionProperties.name3LiteralID]}</h2>
                    <p  className="description--question">{ReactHtmlParser(this.props.localeLiterals["L"+this.props.questionProperties.bottomTextLiteralID])}</p>
                </div>

                {/* SELECTORS */}
                <div className="content--filter--wrapper">
                    {
                        (this.props.chart != 'national-bar-chart') ? (
                            <div>
                                {
                                    (this.props.chart != 'national-bar-chart' && this.props.chart != 'pie-chart') ? (
                                        <p>{this.props.localeLiterals.L101026}</p>  
                                    ) : ""
                                }
                                <form className={`block--filter--wrapper ${(this.state.langSize[0].indexOf(this.state.localLanguage) != -1) ? "form--full--items" : ""} 
                                    ${this.props.chart === "national-comparisons" ? "national-comparisons-wrapper" : ""} 
                                    ${this.props.chart === "national-bar-chart" ? "national-bar-chart-wrapper" : ""}                                    
                                    `}>
                                    {/* ACTIVITY SECTOR FILTER */}
                                    {activitySectorSelect}

                                    {
                                        (this.props.chart == 'national-comparisons') ? (
                                            <span className="or-text">{this.props.localeLiterals.L101027}</span>
                                        ) : (this.props.chart != 'pie-chart' && this.props.chart != 'national-bar-chart') ? (
                                            <span className="or-text">{this.props.localeLiterals.L101027}</span>
                                        ) : ""
                                    }

                                    {/* ESTABLISHMENT SIZE FILTER */}
                                    {establishmentSizeSelect}

                                    {/* COUNTRY FILTER */}
                                    {firstCountrySelectFilter}

                                    {/* COUNTRY COMPARE WITH FILTER ONLY DISPLAYING ON MOBILE */}
                                    {compareWithCountrySelect}
                                </form>        
                            </div>
                        ) : ""
                    }

                    {/* SPECIFIC NATIONAL BAR CHAT SELECT FILTERS */}
                    {nationalBarChartSelectFilters}
                    <hr/>

                    {/* <!-- Select split answers and charts --> */}
                    <div className="filters--and--typechart">
                        {/* <!-- Country national comparison --> */}
                        {compareWithCountrySelect}
                        <form className="block--typechart--wrapper">
                            {/* <!-- ANSWER FILTER --> */}
                            {answerSortByContainer}
                            
                            {/* <!-- Chart selection --> */}
                            <ul className="chart--selection--block">
                                <li className={`${(this.props.chart === 'european-map') ? 'selected-chart' : ''}`} onClick={this.openOptions()}>
                                    <div className="no-event"></div>
                                    <a title={this.props.localeLiterals.L100623} className={`${(this.props.chart == 'european-map') ? 'selected-chart' : ''}`} 
                                        onClick={this.changeChart('european-map')}>
                                            <span className="visible-xs">{this.props.localeLiterals.L100623}</span>
                                    </a>
                                </li>

                                <li className={`${(this.props.chart === 'european-bar-chart') ? 'selected-chart' : ''}`} onClick={this.openOptions()}>
                                    <div className="no-event"></div>
                                    <a title={this.props.localeLiterals.L100622} className={`${(this.props.chart === 'european-bar-chart') ? 'selected-chart' : ''}`} 
                                        onClick={this.changeChart('european-bar-chart')}>
                                            <span className="visible-xs">{this.props.localeLiterals.L100622}</span>
                                    </a>
                                </li>

                                {/* ${this.props.filters.question.toString().match('Q261') ? 'exception' : ''} */}
                                <li className={`${(this.props.chart === 'national-bar-chart') ? 'selected-chart' : ''} 
                                    ${this.props.filters.question.match("Q261") ? "exception" : ""}
                                `} 
                                onClick={this.openOptions()}>
                                    <div className="no-event"></div>
                                    <a title={this.props.localeLiterals.L100624} className={`${(this.props.chart === 'national-bar-chart') ? 'selected-chart' : ''}`} 
                                        onClick={this.changeChart('national-bar-chart')}>
                                            <span className="visible-xs">{this.props.localeLiterals.L100624}</span>
                                    </a>

                                </li>

                                <li className={`${(this.props.chart === 'national-comparisons') ? 'selected-chart' : ''}`} onClick={this.openOptions()}>
                                    <div className="no-event"></div>
                                    <a title={this.props.localeLiterals.L100621} className={`${(this.props.chart === 'national-comparisons') ? 'selected-chart' : ''}`} 
                                        onClick={this.changeChart('national-comparisons')}>
                                            <span className="visible-xs">{this.props.localeLiterals.L100621}</span>
                                    </a>
                                </li>

                                <li className={`${(this.props.chart === 'pie-chart') ? 'selected-chart' : ''}`} onClick={this.openOptions()}>
                                    <div className="no-event"></div>
                                    <a title={this.props.localeLiterals.L101033} className={`${(this.props.chart === 'pie-chart') ? 'selected-chart' : ''}`} 
                                        onClick={this.changeChart('pie-chart')}>
                                            <span className="visible-xs">{this.props.localeLiterals.L101033}</span>
                                    </a>
                                </li>
                                
                                <li className={`${(this.props.filters.euOnly == 1) ? "selected-chart" : ""}`}>
                                    <div className="no-event"></div>
                                    {
                                        (this.props.chart == 'european-map' || this.props.chart == 'european-bar-chart') ? (
                                            <a className={`${(this.props.filters.euOnly == 1) ? "selected-chart" : ""}`} id="eu-only" title={this.props.localeLiterals.L100580} 
                                                onClick={this.updateChart('euOnly')}>{this.props.localeLiterals.L100634}</a>
                                        ) : ""
                                    }
                                </li> 
                            </ul>
                            {
                                (this.props.chart == 'european-map' || this.props.chart == 'european-bar-chart') ? (
                                <a className={`${(this.props.filters.euOnly == 1) ? "selected-chart" : ""}`} id="eu-only-mobile" title={this.props.localeLiterals.L100580}
                                    onClick={this.updateChart('euOnly')}>{this.props.localeLiterals.L100634}</a>
                                ) : ""
                            }
                        </form>
                    </div>        
                </div>
                
            </div>
        )
    }
}

function mapStateToProps(state){
    const {literals} = state;
    const { localeLiterals } = state;
    return {
        literals,
        localeLiterals
    }
}

export default connect(mapStateToProps, null)(Selector);