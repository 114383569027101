import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { accessibilityLabel } from '../../model/PageLabels';

class Accesibility extends Component
{
	componentDidMount() {
		document.title = accessibilityLabel
	}
	
	render()
	{
		return(
			<div>
				<h1 className="title-section second-color text-center">{this.props.literals.L101038}</h1>
				<div className="container legal--notice--block">
					<h2 className="title-section main-color">{this.props.literals.L101040}</h2>
					<div>
						{ReactHtmlParser(this.props.literals.L101039)}
						{/* <p>Access keys are a navigation device enabling you to get around this web site using your keyboard.</p><p>In-depth information about access keys can be found at&nbsp;<a href="http://www.w3.org/TR/WCAG10-HTML-TECHS/#link-accesskey" target="_blank">W3C Accessibility Guidelines.</a></p> */}
					</div>
					
					<h3 className="title--card second-color">{this.props.literals.L101041}</h3>
					<div className="list--tag--block">
						{ReactHtmlParser(this.props.literals.L101042)}
						{/* <p>This site uses a setup that closely matches most international recommendations on access keys. These are:</p>\r\n<ul>\r\n<li>0 - Home</li>\r\n<li>Survey\r\n<ul>\r\n<li>1 - ESENER 2009</li>\r\n<li>2 - ESENER 2014</li>\r\n<li>3 - ESENER 2019</li>\r\n</ul>\r\n</li>\r\n<li>4 - Methodology</li>\r\n<li>M - Site map</li>\r\n<li>C - Contact Us</li>\r\n<li>K - Accessibility</li>\r\n<li>P - Privacy notice</li>\r\n<li>N - Legal notice</li>\r\n</ul> */}
					</div>
					
					<h3 className="title--card second-color">{this.props.literals.L101043}</h3>
					<div className="tableAccesibility">
						{ReactHtmlParser(this.props.literals.L101044)}
						{/* <table><thead><tr><th>Browser</th><th>Windows</th><th>Linux</th><th>Mac</th></tr></thead><tbody><tr><td>Internet explorer</td><td>[Alt] + accesskey</td><td>N/A</td><td>&nbsp;</td></tr><tr><td>Chrome</td><td>[Alt] + accesskey</td><td>[Alt] + accesskey</td><td>[Control] [Alt] + accesskey</td></tr><tr><td>Firefox</td><td>[Alt][Shift] + accesskey</td><td>[Alt][Shift] + accesskey</td><td>[Control] [Alt] + accesskey</td></tr><tr><td>Safari</td><td>[Alt] + accesskey</td><td>N/A</td><td>[Control] [Alt] + accesskey</td></tr><tr><td>Opera</td><td colSpan="3"><p>Opera 15 or newer: [Alt] + accesskey</p><p>Opera 12.1 or older: [Shift] [Esc] + accesskey</p></td></tr></tbody></table> */}
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state){
    return {
        literals: state.literals
    }
}

export default connect(mapStateToProps, null)(Accesibility);
// export default Accesibility;