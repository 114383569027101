import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import Carousel from 'react-bootstrap/Carousel';
import { homeLabel } from '../../model/PageLabels';
// import { setLanguage } from '../../actions/';

class Home extends Component
{
	constructor(props){
		super(props);
	}

	componentDidMount() {
		document.title = homeLabel
	}

	render()
	{
		// console.log("this.props",this.props);
		let controls = false;

		return(
			<div className="home">
				<section className="container-fluid slide-container">    
					<div className="container grid-2">
						<div className="col-sm-12 col-xs-12  col-md-6 col-img">
							<div> 
								<img src={require("../../style/img/EU-map.png")} className="no-hover" />
							</div>
						</div>
						<div className="col-sm-12 col-xs-12 col-md-6  col-content">						
								{/* Wrapper for slides */}
								<Carousel className="carousel--home " interval={null} controls={controls}>
									<Carousel.Item className="title--section multiline">
										<Carousel.Caption>
											{ReactHtmlParser(this.props.literals.L101001)}
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item className="title--section">
										<Carousel.Caption>
											{ReactHtmlParser(this.props.literals.L101002)}
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item className="title--section">
										<Carousel.Caption>
											{ReactHtmlParser(this.props.literals.L101003)}
										</Carousel.Caption>
									</Carousel.Item>
								</Carousel>
						</div>
					</div>
				</section>

				{/* ESENER 2019 */}
				<section className="container-fluid background-orange-light">
					<div className="container grid-2">
						<div className="col-sm-12 col-xs-12 col-md-6 col-content">
							<h2 className="title--section second-color">
								{/* <a ui-sref="overview({pLanguage: pLanguage, pIndicator: '2019'})" data-ng-bind="i18n.L101079">ESENER 2019</a> */}
								<NavLink to={"/"+this.props.lang+"/survey/overview/2019"} data-ng-bind="i18n.L101079" >{this.props.literals.L101079}</NavLink>
							</h2>
							{ReactHtmlParser(this.props.literals.L101085)}
							{/* <p data-ng-bind-html="i18n.L101085">
								<p>The Third European Survey of Enterprises on New and Emerging Risks asked those ‘who know best about health and safety in the establishments’ about the way health and safety risks are managed at their workplace, with a particular focus on psychosocial risks, i.e. work-related stress, violence and harassment.</p>
							</p> */}
							<p><NavLink to={"/"+this.props.lang+"/survey/overview/2019"}  className="btn-default btn-orange"><span data-ng-bind="i18n.L480">{this.props.literals.L480}</span></NavLink></p>
						</div>
						<div className="col-sm-12 col-xs-12 col-md-6 col-img">
							<NavLink to={"/"+this.props.lang+"/survey/overview/2019"}><img src={require("../../style/img/esener2019.png")} /></NavLink>
						</div>
					</div>
				</section>

				{/* ESENER 2014 */}
				<section className="container-fluid background-main-light">
					<div className="container grid-2">
						<div className="col-sm-12 col-xs-12  col-md-6 col-img">
							<NavLink to={"/"+this.props.lang+"/survey/overview/2014"}><img src={require("../../style/img/esener2014.png")} /></NavLink>
						</div>
						<div className="col-sm-12 col-xs-12  col-md-6 col-content">
							<h2 className="title--section main-color">
								<NavLink to={"/"+this.props.lang+"/survey/overview/2014"} data-ng-bind="i18n.L101008" >{this.props.literals.L101008}</NavLink>
								{/* <a ui-sref="overview({pLanguage: pLanguage, pIndicator: '2014'})" data-ng-bind="i18n.L101008">ESENER 2014</a> */}
							</h2>
							{ReactHtmlParser(this.props.literals.L101005)}
							{/* <p data-ng-bind-html="i18n.L101005">
								<p>The fieldwork for the second wave of ESENER was carried out in the summer-autumn of 2014. The survey provides an invaluable snapshot of how workplace risks, and especially new and emerging risks, are being managed across Europe. </p>
							</p> */}
							<p><NavLink to={"/"+this.props.lang+"/survey/overview/2014"}  className="btn-default btn-main-color"><span data-ng-bind="i18n.L480">{this.props.literals.L480}</span></NavLink></p>
						</div>
					</div>
				</section>

				{/* ESENER 2009 */}
				<section className="container-fluid background-orange-light">
					<div className="container grid-2">
						<div className=" col-sm-12 col-xs-12 col-md-6 col-content">
							<h2 className="title--section second-color">
								<NavLink to={"/"+this.props.lang+"/survey/overview/2009"} data-ng-bind="i18n.L101007">{this.props.literals.L101007}</NavLink>
								{/* <a ui-sref="overview({pLanguage: pLanguage, pIndicator: '2009'})" data-ng-bind="i18n.L101007">ESENER 2009</a> */}
							</h2>
							<p data-ng-bind-html="i18n.L101004">
								{this.props.literals.L101004}
								{/* The first ESENER, carried out in 2009, involved nearly 36,000 interviews with managers and OSH representatives. All EU Member States were covered, as were Croatia, Turkey, Norway and Switzerland — 31 countries in all. The interviewees worked in private and public sector organisations with 10 or more employees. */}
							</p>
							<p><NavLink to={"/"+this.props.lang+"/survey/overview/2009"}  className="btn-default btn-orange"><span data-ng-bind="i18n.L480">{this.props.literals.L480}</span></NavLink></p>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-6 col-img">
							<NavLink to={"/"+this.props.lang+"/survey/overview/2009"}><img src={require("../../style/img/esener2009.png")} /></NavLink>
						</div>
					</div>
				</section>
			
			</div>
		)
	}
}

function mapStateToProps(state){
    return {
        literals: state.literals
    }
}

export default connect(mapStateToProps, null)(Home);

// export default Home;