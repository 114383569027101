import { SET_LANGUAGE } from '../constants';
import literalFiles from '../model/literals';

export default (state = require(`../model/Literals.json`), action) => {
    switch (action.type) {
       case SET_LANGUAGE:
        const { language } = action;
        if(language === "en"){
            return require(`../model/Literals.json`);
        }else{
            literalFiles[language];
            return literalFiles[language];
        }
       default:
          return state;
    }
 }