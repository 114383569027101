import React from 'react';
import { NavLink } from 'react-router-dom';
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton, FacebookIcon, XIcon, LinkedinIcon} from 'react-share';

function setTitleShare (props) {
    const { year } = props;
    if(year === "2009"){
        return 'ESENER-1 | Safety and health at work - EU-OSHA';
    }else if(year === "2014"){
        return 'ESENER-2 | Safety and health at work - EU-OSHA';
    }else if(year === "2019"){
        return 'ESENER-3 | Safety and health at work - EU-OSHA';
    }
     return 'ESENER | Safety and health at work - EU-OSHA';
}

function setBreadcrumb(history){
    if(history.location.pathname.substring(3) == ""){
        return 'home';
    }
    else if (history.location.pathname.includes("page-not-found"))
    {
        return 'page-not-found';
    }
    else{
        if(history.location.pathname.substring(4).lastIndexOf('/') != -1){
            if(history.location.pathname.includes("detailpage")){
                // console.log("location has detail page");
                return "detailpage";
            }else if(history.location.pathname.includes("comparisons")){
                // console.log("location has comparisons");
                // console.log("location.pathname",location.pathname.substring(4, location.pathname.indexOf('/',11)));
                return "survey/comparisons";
            }
            // console.log("location.pathname",location.pathname.substring(4, location.pathname.lastIndexOf('/')));
            return history.location.pathname.substring(4, history.location.pathname.lastIndexOf('/'));
        }else{
            // console.log("location.pathname",location.pathname.substring(4));
            return history.location.pathname.substring(4);
        }
    }
}

// var page = setBreadcrumb();

const Breadcrumb = props => {
    const breadcrumb = require('../../model/breadcrumb.json');
    // console.log('Page init', page);
    const {history} = props;
    // console.log('history', history);

    let page = setBreadcrumb(history);

    history.listen(() => { 
        // console.log(`You changed the page to: ${location.pathname}`);
        page = setBreadcrumb(history);
        // console.log('Page history listen', page);
    })

    let breadCrumbStructure;
    let esenerYear;

    if(props.year === "2009"){
        esenerYear = (
            <span className='current-page'>
                <NavLink to={"/"+props.lang+"/survey/overview/2009"}>{props.literals.L101007}</NavLink>
            </span>
        )
    }else if(props.year === "2014"){
        esenerYear = (
            <span className='current-page'>
                <NavLink to={"/"+props.lang+"/survey/overview/2014"}>{props.literals.L101008}</NavLink>
            </span>
        )
    }else if(props.year === "2019"){
        esenerYear = (
            <span className='current-page'>
                <NavLink to={"/"+props.lang+"/survey/overview/2019"}>{props.literals.L101079}</NavLink>
            </span>
        )
    }

    if(breadcrumb[page].level === "0"){
        breadCrumbStructure = <span className='current-page'>{props.literals[breadcrumb[page].name]}</span>;
    }else if(breadcrumb[page].level === "1"){
        breadCrumbStructure = (
            <span>
                <span><NavLink to={"/"+props.lang}>{props.literals.L355}</NavLink></span>
                    <i className='fa fa-angle-right' aria-hidden='true'></i>
                <span className=''>{props.literals[breadcrumb[page].name]}</span>
            </span>
        );
    }else if(breadcrumb[page].level === "2"){
        breadCrumbStructure = (
            <span>
                <span>
                    <NavLink to={"/"+props.lang}>{props.literals.L355}</NavLink>
                </span>
                <i className='fa fa-angle-right' aria-hidden='true'></i>
                {esenerYear}
                <i className='fa fa-angle-right' aria-hidden='true'></i>
                <span>{props.literals[breadcrumb[page].name]}</span>
            </span>
        )
    }

    return (
        <section className="breadcrumbs--social--network">
            <div className="breadcrumbs">

            <p className="path">
                {breadCrumbStructure}
                {/* {
                    (breadcrumb[page].level === "0") ? (
                        <span className='current-page'>{props.literals[breadcrumb[page].name]}</span>
                    ) : ""
                }
                {
                    (breadcrumb[page].level === "1") ? (
                        <span>
                            <span><NavLink to={"/"+props.lang}>{props.literals.L355}</NavLink></span>
                                <i className='fa fa-angle-right' aria-hidden='true'></i>
                            <span className=''>{props.literals[breadcrumb[page].name]}</span>
                        </span>
                    ) : ""
                }
                {
                    (breadcrumb[page].level === "2") ? (
                        <span>
                            <span>
                                <NavLink to={"/"+props.lang}>{props.literals.L355}</NavLink>
                            </span>
                            <i className='fa fa-angle-right' aria-hidden='true'></i>
                            {
                                (props.year === "2009") ? (
                                    <span className='current-page'>
                                        <NavLink to={"/"+props.lang+"/overview/2009"}>{props.literals.L101007}</NavLink>
                                    </span>
                                ) : ("")
                            }{
                                (props.year === "2014") ? (
                                    <span className='current-page'>
                                        <NavLink to={"/"+props.lang+"/overview/2014"}>{props.literals.L101008}</NavLink>
                                    </span>
                                ) : ("")
                            }{
                                (props.year === "2019") ? (
                                    <span className='current-page'>
                                        <NavLink to={"/"+props.lang+"/overview/2019"}>{props.literals.L101079}</NavLink>
                                    </span>
                                ) : ("")
                            }
                            <i className='fa fa-angle-right' aria-hidden='true'></i>
                            <span>{props.literals[breadcrumb[page].name]}</span>
                        </span>
                    ) : ""
                } */}
            </p>
            </div>
            <div className="social--network--nav" id="osha-menu-social">
                <label>{props.literals.L369}</label>
                <ul>
                    <li title={props.literals.L370}>
                        <TwitterShareButton url={window.location.href} title={setTitleShare(props)}>
                            <XIcon size={40} bgStyle={{fill: 'transparent'}} iconFillColor={'#00a1a3'} />
                        </TwitterShareButton>
                    </li>
                    <li title={props.literals.L371}>
                        <FacebookShareButton url={window.location.href} quote={setTitleShare(props)} >
                            <FacebookIcon size={40} bgStyle={{fill: 'transparent'}} iconFillColor={'#00a1a3'} />
                        </FacebookShareButton>
                    </li>
                    <li title={props.literals.L372}>
                        <LinkedinShareButton url={window.location.href} title={setTitleShare(props)} >
                            <LinkedinIcon size={40} bgStyle={{fill: 'transparent'}} iconFillColor={'#00a1a3'} />
                        </LinkedinShareButton>
                    </li>
                </ul>
            </div>
        </section>
    )
};

export default Breadcrumb;