import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PageNotFound extends Component
{
	render()
	{
		return(
			<div className="text-center error-404">
				<div class="img-404"></div>
				<h1 class="title-section second-color ng-binding">Page not found</h1>
 				<p>Sorry, we can't find what you are looking for.</p>
				<p>You could use the search above, instead or go back to <Link to="/"><i class="fa fa-home fa-2x" aria-hidden="true"></i>Home</Link></p>
			</div>
		)
	}
}

export default PageNotFound;