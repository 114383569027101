import React, { useState, useEffect } from 'react';
import { connect, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import $ from "jquery";
import { getSelectorData, getComparisonSelectorData } from '../../api';
import QuestionListSelect from './questioListSelect/QuestionListSelect';
import questionnaries from '../../model/questionnaries_name.json';

const QuestionList = props => 
{
	const history = useHistory();
	const store = useStore();

	const [questionList, setQuestionList] = useState([]);

	useEffect(() => {
		// console.log("QuestionList.js Component did update");
		// console.log("props.filters", props.filters);
		try {
			if(props.page === "detailpage"){
				getSelectorData(props.year, props.lang)
					.then((res) => {
						// console.log("res",res.resultset.selectorData);
						setQuestionList(res.resultset.selectorData);
					})
            }else{
                // getComparisonSelectorData(props.year, props.lang)
				getComparisonSelectorData('2019', props.lang)
				.then((res) => {
					// console.log("res",res.resultset.selectorData);
					setQuestionList(res.resultset.selectorData);
				})
            }
			
		} catch (error) {
			console.log('Error fetching selector data:', error)
		}		
	},[props.lang, props.page]);

	// props.filters.topic, props.filters.question, props.filters.sectorSize, props.filters.answer, props.year, 

	function openAccordion(event) {
		// console.log("openAccordion", event);
		var parentNode = event.target.parentElement.parentElement;
		var subQuestion = parentNode.parentElement.parentElement;
		//TODO test more times if this works
		if(parentNode.className.indexOf("open") != -1){
			parentNode.classList.remove("open");
		} else {
			if(subQuestion.className.indexOf("has-level3") == -1){
				$('.questions--tree li').removeClass('open');
			} else {
				$('.questions--tree li .has-level3').removeClass('open');
			}
			// parentNode.classList.add('open');
			parentNode.className += " open";
			if(subQuestion != undefined){
				subQuestion.className += " open";
			}
		}
	}
	
	
		// console.log("WINDOW", window.innerWidth )
		if(window.innerWidth > 1024){
			$('.questions--list--wrapper').removeClass('open');
		}else{
			$('.questions--list--wrapper').addClass('open');
		}


	const changeToQuestion = (questionParameter, topicId) => (event) => {
		// console.log('change to question', {questionParameter, topicId})
		// console.log("Enters changeToQuestion");
		// console.log("topicId",topicId);
		var topicLocal = getTopicName(topicId);
		// console.log("topicLocal",topicLocal);
		var answerLocal = props.answer;
		var questionName = (questionParameter.categoryName != undefined)?questionParameter.categoryName:questionParameter;
		var exception = props.rules(questionName);
		// console.log("exception",exception);

		// if (props.year != "2009")
		// {
		// 	$rootScope.question = question.category;
		// }

		if (props.page == 'detailpage') {
			if(!exception){
				// console.log("Exception rule?",exception);
				if(props.chart === 'european-bar-chart'){
					answerLocal = '0';
				}
	
				// setQuestion(questionParameter.categoryName);
	
				props.callback(questionParameter.categoryName, topicLocal);
	
				//Temporal
				history.push({
					pathname: constructUrl(questionName, topicLocal)
				})
	
				// if(anchor != undefined){
				// 	setAnswer(question.answerID);
				// 	setTopic(anchor);
				// 	history.push({
				// 		pathname: constructUrl()
				// 	});
				// }else{
				// 	//call to web service 
				// }
			}else{
				// console.log("Exception rule?",exception);
				// history.push({
				// 	pathname: constructUrl(props.filters.question, props.filters.topic)
				// })

				// var actualTopic =  getTopicName(props.filters.topic);
				// $('li#'+actualTopic).addClass('open');
			}
		} else if (exception) {
			event.preventDefault()
		} else {
			if (!exception) {
				//TODO: Leave in case of being needed, otherwise, delete
				// props.callback(questionParameter.categoryName, topicLocal)
				
				history.push({
					pathname: constructUrl(questionName, topicLocal)
				})
			}
		}
		

	}

	function getTopicByQuestionId(questionId){
		// console.log("getTopicByQuestionId");
		let topicId;
		const result = questionList.find(question => question.categoryName === questionId);
		if(result){
			if(result.level === 2){
				topicId = getTopicName(result.father);
			}else{
				let result2 = questionList.find(question => question.questionID === result.father);
				// console.log("result2",result2);
				topicId = getTopicName(result2.father);
			}
		}
		// console.log("result",result);
		return topicId;
	}

	function getTopicName(topicId){
		// console.log("topicId",topicId);
		// console.log("getTopicName function");
		if(topicId === 1 || topicId === 12 || topicId === 210){
			return "osh-management";
		}else if(topicId === 5 || topicId === 22){
			return "psychosocial-risks-and-their-management";
		}else if(topicId === 218){
			return "emerging-risks-and-their-management";
		}else if(topicId === 8 || topicId === 26 || topicId === 223){
			return "drivers-and-barriers";
		}else if(topicId === 11 || topicId === 30 || topicId === 227){
			return "worker-participation";
		}
	}

	function getTopicId(){
		if(props.filters.topic === "osh-management"){
			if(props.year === "2009"){
				return 1;
			}else if(props.year === "2014"){
				return 12;
			}else if(props.year === "2019"){
				return 210;
			}
		}else if(props.filters.topic === "psychosocial-risks-and-their-management"){
			if(props.year === "2009"){
				return 5;
			}else if(props.year === "2014"){
				return 22;
			}
		}else if(props.filters.topic === "emerging-risks-and-their-management"){
			if(props.year === "2019"){
				return 218;
			}
		}else if(props.filters.topic === "drivers-and-barriers"){
			if(props.year === "2009"){
				return 8;
			}else if(props.year === "2014"){
				return 26;
			}else if(props.year === "2019"){
				return 223;
			}
		}else if(props.filters.topic === "worker-participation"){
			if(props.year === "2009"){
				return 11;
			}else if(props.year === "2014"){
				return 30;
			}else if(props.year === "2019"){
				return 227;
			}
		}
		return 0;
	}

	function constructUrl(questionName, topicLocal) {
		// console.log("QuestionList constructUrl");
		if (props.page == 'detailpage') {
			var url = "/"+props.lang+"/survey/"+"detailpage-"+props.chart+"/"+props.year+"/"+topicLocal+"/"+props.locale+"/"+questionName;
			if(props.chart === "european-map"){
				url = url+"/"+props.filters.sectorSize+"/"+props.filters.activitySector+"/"+props.filters.establishmentSize+"/"+props.filters.answer;
			}else if(props.chart === "european-bar-chart"){
				url = url+"/"+props.filters.sectorSize+"/"+props.filters.activitySector+"/"+props.filters.establishmentSize;
			}else if(props.chart === "national-bar-chart"){
				url = url+"/"+props.filters.sectorSize+"/"+props.filters.country;
			}else if(props.chart === "national-comparisons"){
				url = url+"/"+props.filters.sectorSize+"/"+props.filters.activitySector+"/"+props.filters.establishmentSize+"/"+props.filters.country+"/"+props.filters.country2;
			}else{
				url = url+"/"+props.filters.country;
			}
	
			// console.log("url", url);
	
			return url;
		} else {
			let newUrl = `/${props.lang}/survey/comparisons/${props.year}/${topicLocal}/${props.locale}/${props.filters.sectorSize}/${questionName}/${props.filters.country}/${props.filters.answer}`;
			
			return newUrl;
		}
	}

	function firstLevel(){
		var items = [];

		questionList.filter(question => question.level === 1).map(filteredQuestion => {
			items.push(filteredQuestion);
		})

		// console.log("first level list", items);
		return items;
	}

	function children(questionId){
		// console.log("Enters children function",questionId);
		var children = [];

		questionList.filter(question => question.father === questionId).map(filteredQuestion => {
			children.push(filteredQuestion);
		})

		return children;
	}

	function findParent(questionId,child){
		// console.log("findParent",questionId,child);
		var parent = 0;
		
		questionList.filter(question => question.categoryName === questionId).map(filteredQuestion => {
			parent = filteredQuestion.father;
		})
		return parent;
	}

	function addOpenClass(questionLiteral){
		// console.log("addOpenClass",questionLiteral);
		// console.log("props.filters.topic",props.filters.topic);
		// console.log("questionLiteral",questionLiteral);
		if(props.filters.topic === "osh-management" && (questionLiteral === 100001 || questionLiteral === 100023 || questionLiteral === 102001)){
			return "open";
		}else if((props.filters.topic === "emerging-risks-and-their-management" || props.filters.topic === "psychosocial-risks-and-their-management") 
			&& (questionLiteral === 100009 || questionLiteral === 100043 || questionLiteral === 102017)){
			return "open";
		}else if(props.filters.topic === "drivers-and-barriers" && (questionLiteral === 100015 || questionLiteral === 100051 || questionLiteral === 102027)){
			return "open";
		}else if(props.filters.topic === "worker-participation" && (questionLiteral === 100021 || questionLiteral === 100059 || questionLiteral === 102035)){
			return "open";
		}
		return "";
	}

	const onCallbackSelect = (callback) => {
		// console.log("onCallbackSelect",callback);
		let topicId = getTopicByQuestionId(callback.value);
		props.callback(callback, topicId);
	}

	// console.log("questionList",questionList);
	// console.log("props",props);

	return(
		<section className="col-content questions--list--wrapper">
			<div className="filter--questions--group--wrapper">
				<p onClick={openAccordion}>{props.localeLiterals.L100663}</p>
				<div className="filter--questions--group">
				{/* <!-- Search Bar Component --> */}
				<QuestionListSelect 
					onCallback={onCallbackSelect} 
					questionList={questionList} 
					filters={props.filters} 
					locale={props.locale} 
					/>
				{/* <!-- Question Hierarchy --> */}
				<div className="questions--tree">
					{/* <!-- First level --> */}
					<ul>
						{
						firstLevel().map((questionInList, index) => (
							// <li className={`first-level {props.topic === question.anchor ? "open" : "" }`}>
							<li tabIndex={index} id={questionInList.questionID} key={questionInList.questionID} className={`first-level ${addOpenClass(questionInList.nameLiteralID)}`}>
								<span><a tabIndex={index} onClick={openAccordion}>{store.getState().localeLiterals["L"+questionInList.nameLiteralID]}</a></span>
								{/* <!-- Second Level --> */}
								<ul>
								{
								children(questionInList.questionID).map((child, indexChild) => (
									<li id={child.questionID} key={child.questionID} className={`${child.questionID === findParent(props.filters.question,child) ? "open" : ""} ${child.categoryName === null ? "has-level3" : ""}`}>
										{
										child.categoryName != null ? (
											<span>
												<a onClick={changeToQuestion(child, questionInList.questionID)} className={`${child.categoryName === props.filters.question ? "selected" : ""}`}>
													{store.getState().localeLiterals["L"+child.nameLiteralID]}
												</a>
											</span>
										) : (
											<span>
												<a tabIndex={indexChild} onClick={openAccordion} >{store.getState().localeLiterals["L"+child.nameLiteralID]}</a>
											</span>
										)
										}
										{
											(child.categoryName == null) ? (
												<ul>
													{
													children(child.questionID).map((grandchild) => (
														<li id={grandchild.questionID} key={grandchild.questionID} onClick={openAccordion}>
															<span>
																<a onClick={changeToQuestion(grandchild, questionInList.questionID)} className={`${grandchild.categoryName === props.filters.question ? "selected" : ""}`}>
																	{store.getState().localeLiterals["L"+grandchild.nameLiteralID]}
																</a>
															</span>
														</li>
													))
													}
												</ul>
											) : ""
										}
									</li>
								))
								}
								</ul>
							</li>
						))
						}
					</ul>
					{/* <!-- DOWNLOAD QUESTIONNARIE --> */}
					{/* <!-- Shows Questionnaire download button on detail page component  --> */}
					{props.page == 'detailpage' && (
						<ul className="download--button">
							<li id="surveyMenuItem" className="active-trail finalquestion">
							{
								props.year === "2009" ? (
									<a href={"/esener/2009/esener-questionnaire-2009_"+props.locale.toLowerCase()+".zip"} target="_blank"
									title="Download questionnaire">{props.localeLiterals.L100625}</a>
								) : (
									<a href={"/esener/"+props.year+"/"+questionnaries[props.year][props.locale.toLowerCase()]+".pdf"} target="_blank"
									title="Download questionnarie">{props.localeLiterals.L100625}</a>
								)
							}
							</li>
						</ul>
					)}
				</div>
				</div>
			</div>
		</section>
	)
}

function mapStateToProps(state){
    const {literals} = state;
	const {localeLiterals} = state;
    return {
        literals,
		localeLiterals
    }
}

export default connect(mapStateToProps, null)(QuestionList);
// export default DetailPage;