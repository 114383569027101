import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { legalNoticeLabel } from '../../model/PageLabels';

class LegalNotice extends Component
{
	componentDidMount() {
		document.title = legalNoticeLabel
	}
	
	render()
	{
		return(
			<div>
				<h1 className="title-section second-color ng-binding text-center">{this.props.literals.L360}</h1>
				<div className="container legal--notice--block">
					<h2 className="title-section main-color">{this.props.literals.L101034}</h2>
					<div>
						{ReactHtmlParser(this.props.literals.L101035)}
						{/* <p>The European Agency for Safety and Health at Work's maintains this website to enhance public access to information about its activities and as a tool to access information on occupational safety and health. Our goal is to keep this information timely and accurate. If errors are brought to our attention, we will try to correct them. However, the Agency accepts no responsibility or liability whatsoever with regard to the information on this site.</p><p>This information is:</p><ul type="disc"><li>of a general nature only and is not intended to address the specific circumstances of any particular individual or entity;</li><li>not necessarily comprehensive, complete, accurate or up to date;</li><li>sometimes linked to external sites over which the Agency has no control and for which the Agency assumes no responsibility;</li><li>not professional or legal advice (if you need specific advice, you should always consult a suitably qualified professional).</li></ul><p>It is our goal to minimise disruption caused by technical errors. However some data or information on our site may have been created or structured in files or formats that are not error-free and we cannot guarantee that our service will not be interrupted or otherwise affected by such problems. The Agency accepts no responsibility with regard to such problems incurred as a result of using this site or any linked external sites.</p><p>This disclaimer is not intended to limit the liability of the Agency in contravention of any requirements laid down in applicable national law nor to exclude its liability for matters which may not be excluded under that law.</p><p>Translation provided by the Translation Centre (CdT, Luxembourg), based on an English original text.</p> */}
					</div>

					<h2 className="title-section main-color">{this.props.literals.L101036}</h2>
					<div>
						{ReactHtmlParser(this.props.literals.L101037)}
						{/* <p>© European Agency for Safety and Health at Work</p><p>Reproduction is authorised, provided the source is acknowledged, save where otherwise stated.<br />Where prior permission must be obtained for the reproduction or use of textual and multimedia information (sound, images, software, etc.), such permission shall cancel the above-mentioned general permission and shall clearly indicate any restrictions on use.</p> */}
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state){
    const {literals} = state;	
    return {
        literals
    }
}

export default connect(mapStateToProps, null)(LegalNotice);

// export default LegalNotice;