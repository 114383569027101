import React, { Component } from 'react';
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton, FacebookIcon, XIcon, LinkedinIcon} from 'react-share';
import Highcharts from 'highcharts'
import HighchartsMaps from 'highcharts/highmaps';
// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);
import $ from "jquery";
import CsvData from './csvData/CsvData';

class Export extends Component {
    constructor(props){
        super(props);
        this.state = {
            showPopUpSocialMedia: false,
            showPopUpExportData: false,
            pageUrlActive: false,
            pageCitationActive: false,
            pageDownloadCSVActive: false,
            pathURLDVT: window.location.href
        }
    }

    componentDidUpdate(prevProps){
        // console.log("prevProps",prevProps.filters);
        // console.log("props",this.props.filters);

        if(prevProps.filters != this.props.filters){
            this.setState({
                pathURLDVT: window.location.href
            })
        }
    }

    showSharePopUp = (event) => {
        // console.log("showSharePopUp",event.currentTarget.id);
        if(event.currentTarget.id === 'btnSocial')
            this.setState({
                showPopUpSocialMedia: true
            })
        else
            this.setState({
                showPopUpExportData: true
            })
    }

    ok = (event) => {
        // console.log("ok function", event);
        var target = event.target;
        var parent = event.target.parentNode;
        // console.log("event.target", event.target);
        if(target.className == 'btn pull-right' || target.className == 'fa fa-2x fa-times' || target.id == 'popUpMessage'){
            this.setState({
                showPopUpSocialMedia: false,
                showPopUpExportData: false
            });
        }

        if(parent.id === "urlOption"){
            this.setState({pageUrlActive: !this.state.pageUrlActive,pageCitationActive:false,pageDownloadCSVActive:false})
        }

        if(parent.id === "pageOption"){
            this.setState({pageCitationActive: !this.state.pageCitationActive,pageUrlActive:false,pageDownloadCSVActive:false})
        }

        if(parent.id === "csvOption"){
            this.setState({pageDownloadCSVActive: !this.state.pageDownloadCSVActive,pageCitationActive:false,pageUrlActive:false})
        }
    }

    setTitleShare = () => {
        if(this.props.year === "2009"){
            return 'ESENER-1 | Safety and health at work - EU-OSHA';
        }else if(this.props.year === "2014"){
            return 'ESENER-2 | Safety and health at work - EU-OSHA';
        }else if(this.props.year === "2019"){
            return 'ESENER-3 | Safety and health at work - EU-OSHA';
        }
        return 'ESENER | Safety and health at work - EU-OSHA';
    }

    exportPNG = () => {
        var fileName = "";
        var sectorId = this.props.filters.sectorSize === "activity-sector" ? this.props.filters.activitySector : this.props.filters.establishmentSize;

        if(this.props.chart === "european-map"){
            fileName = "heatMap-esener"+this.props.year+"-"+this.props.filters.sectorSize+"-"+sectorId+"-"+this.props.filters.question+"-"+this.props.filters.answer;
        }else if(this.props.chart === "european-bar-chart"){
            fileName = "euBars-esener"+this.props.year+"-"+this.props.filters.sectorSize+"-"+sectorId+"-"+this.props.filters.question;
        }else if(this.props.chart === "national-bar-chart"){
            fileName = "inCountry-esener"+this.props.year+"-"+this.props.filters.sectorSize+"-"+this.props.filters.question+"-"+this.props.filters.country;
        }else if(this.props.chart === "national-comparisons"){
            fileName = "crossCountry-esener"+this.props.year+"-"+this.props.filters.sectorSize+"-"+sectorId+"-"+this.props.filters.question
                +"-"+this.props.filters.country+"-"+this.props.filters.country2;
        }else if(this.props.chart === "pie-chart"){
            fileName = "pieChart-esener"+this.props.year+"-"+this.props.filters.question;
        }

        if(this.props.chart === "european-map"){
            // this.exportCharts(HighchartsMaps.charts, {filename: fileName});
            this.exportChartsLocal(HighchartsMaps.charts, {filename: fileName});
        }else{
            var charts = Highcharts.charts.filter(function( element ) {
                return element !== undefined;
            });

            this.exportCharts(charts, {filename: fileName});
        }
    }

    /** Function that exports multiple charts locally */
    exportChartsLocal = function(charts, options) {
        options = Highcharts.merge(Highcharts.getOptions().exporting, options);
        
        // Get SVG asynchronously and then download the resulting SVG
        this.getSVGLocal(charts, options, function(svg) {
            Highcharts.downloadSVGLocal(svg, options, function() {
                console.log("Failed to export on client side");
            });
        });
    };

    /** Function that merges multiple charts in one svg */
    getSVGLocal = function (charts, options, callback) {
        var svgArr = [],
            top = 0,
            width = 0,
            addSVG = function (svgres) {
                // Grab width/height from exported chart
                var svgWidth = +svgres.match(
                        /^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/
                    )[1],
                    svgHeight = +svgres.match(
                        /^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/
                    )[1],
                    // Offset the position of this chart in the final SVG
                    svg = svgres.replace('<svg', '<g transform="translate(0,' + top + ')" ');
                svg = svg.replace('</svg>', '</g>');
                top += svgHeight;
                width = Math.max(width, svgWidth);
                svgArr.push(svg);
            },
            exportChart = function (i) {
                if (i === charts.length) {
                    return callback('<svg height="' + top + '" width="' + width +
                      '" version="1.1" xmlns="http://www.w3.org/2000/svg">' + svgArr.join('') + '</svg>');
                }
                charts[i].getSVGForLocalExport(options, {}, function () {
                    console.log("Failed to get SVG");
                }, function (svg) {
                    addSVG(svg);
                    return exportChart(i + 1); // Export next only when this SVG is received
                });
            };
        exportChart(0);
    };

    /* Create a global exportCharts method that takes an array of charts as an
     * argument, and exporting options as the second argument
     */
    exportCharts = function (charts, options) {
        // Merge the options
        options = Highcharts.merge(Highcharts.getOptions().exporting, options);

        // Post to export server
        Highcharts.post(options.url, {
            filename: options.filename || 'chart',
            type: options.type,
            width: options.width,
            svg: this.getSVG(charts)
        });
    };

    /** Create a global getSVG method that takes an array of charts as an
     * argument */
    getSVG = function (charts) {
        let top = 0;
        let width = 0;
    
        const groups = charts.map(chart => {
            let svg = chart.getSVG();
            // Get width/height of SVG for export
            const svgWidth = +svg.match(
                /^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/
            )[1];
            const svgHeight = +svg.match(
                /^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/
            )[1];
    
            svg = svg
                .replace(
                    '<svg',
                    '<g transform="translate(0,' + top + ')" '
                )
                .replace('</svg>', '</g>');
    
            top += svgHeight;
            width = Math.max(width, svgWidth);
    
            return svg;
        }).join('');
    
        return `<svg height="${top}" width="${width}" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
                ${groups}
            </svg>`;
    };

    selectTextArea = () => {
        $("#urlTextArea").select();
        document.execCommand("copy");
    }

    createCitation = () => {
        var type = this.props.year === "2009" ? 1 : 2;
        var date = new Date();
        var chartCitation = "";
        var paramsUsed = this.state.pathURLDVT.substring(this.state.pathURLDVT.indexOf(this.props.filters.question));
        paramsUsed = paramsUsed.substring(paramsUsed.indexOf("/")+1);
        var textParams = "";
        
        var title = '';
        if(this.props.questionProperties.grandfatherLiteralID == 0){
            title = this.props.literals['L'+this.props.questionProperties.name3LiteralID]+' ('+this.props.literals['L'+this.props.questionProperties.fatherLiteralID]+')';
        }else{
            title = this.props.literals['L'+this.props.questionProperties.name2LiteralID] + ': ' + this.props.literals['L'+this.props.questionProperties.name3LiteralID] 
            +' ('+this.props.literals['L'+this.props.questionProperties.grandfatherLiteralID]+')';
        }

        if(this.props.chart == 'european-map'){
            chartCitation = 'L100623';
        }else if(this.props.chart == 'european-bar-chart'){
            chartCitation = 'L100622';
        }else if(this.props.chart == 'national-bar-chart'){
            chartCitation = 'L100624';
        }else if(this.props.chart == 'national-comparisons'){
            chartCitation = 'L100621';
        }else if(this.props.chart == 'pie-chart'){
            chartCitation = 'L101033';
        }else if(this.props.chart == 'comparisons'){
            chartCitation = 'L101087';
        }

        if(this.props.filters.sectorSize == 'activity-sector' && paramsUsed.indexOf('activity-sector') != -1){
            textParams = textParams + 'Activity sector ';
        }
        
        if(this.props.filters.sectorSize == 'company-size'  && paramsUsed.indexOf('company-size') != -1){
            textParams = textParams + 'Establishment size ';
        }

        if(this.props.filters.sectorSize == 'activity-sector' && paramsUsed.indexOf(this.props.filters.activitySector) != -1){
            textParams = textParams + ', ' + this.props.filters.activitySector + ' ';
            paramsUsed = paramsUsed.replace('/'+this.props.filters.activitySector,'');
        }
        // console.log("paramsUsed",paramsUsed);

        if(this.props.filters.sectorSize == 'company-size' && paramsUsed.indexOf(this.props.filters.companySize) != -1){
            textParams = textParams + ', ' + this.props.filters.companySize + ' ';
            paramsUsed = paramsUsed.replace(this.props.filters.activitycompanySizeSector,'');
        }
        // console.log("paramsUsed",paramsUsed);

        if(paramsUsed.indexOf(this.props.filters.country) != -1){
            if(this.props.chart == 'pie-chart'){
                textParams = textParams + 'country : ' + this.props.filters.country + ' ';
            }else{
                textParams = textParams + ', country : ' + this.props.filters.country + ' ';
            }
        }

        if(paramsUsed.indexOf(this.props.filters.country2) != -1){
            textParams = textParams + ', compare with : ' + this.props.filters.country2 + ' ';
        }

        if(paramsUsed.indexOf('/'+this.props.filters.answer) != -1){
            textParams = textParams + ', answer : ' + this.props.filters.answer + ' ';
        }

        var text = '@ONLINE{EU-OSHA:'+ date.getFullYear() +':Online,\n' +
				'author = {EU-OSHA},\n' +
				'title = {'+title+' '+this.props.literals.L100591 +' : '+
				' '+this.props.literals[chartCitation]+' '+ this.props.literals.L100593 + ' : ' + textParams +
				'- ESENER-'+type+
				'},\n' +
				'year = {'+(new Date().getFullYear())+'},\n' +
				'url = {'+this.state.pathURLDVT+'}' +
			'}'
        return text;
    }

    render(){
        let popUpMessage = "";

        if(this.state.showPopUpSocialMedia){
            popUpMessage = (
                <section id="popUpMessage" onClick={this.ok}>
                    <div className="modal fade dvt-modal-social in" 
                        index="0" style={{zIndex: 1050}}>
                        <div className="modal-wrapper">
                            <div className="modal-body">
                                <span className="btn pull-right" role="button" aria-hidden="true" title="Close window" onClick={this.ok}>
                                    <i className="fa fa-2x fa-times" aria-hidden="true"></i>
                                </span>
                                <div className="popUpMessage--text--block">
                                    <p className="text-center title-share">{this.props.literals.L101028}</p>
                                    <div className="social--network--modal" id="osha-menu-social">
                                        <TwitterShareButton url={window.location.href} title={this.setTitleShare()}>
                                            <XIcon size={70} bgStyle={{fill: 'transparent'}} iconFillColor={'#00a1a3'} />
                                        </TwitterShareButton>
                                        <FacebookShareButton url={window.location.href} quote={this.setTitleShare()}>
                                            <FacebookIcon size={40} bgStyle={{fill: '#00a1a3'}} borderRadius={10} iconFillColor={'#FFFFFF'} />
                                        </FacebookShareButton>
                                        <LinkedinShareButton url={window.location.href} title={this.setTitleShare()} >
                                            <LinkedinIcon size={70} bgStyle={{fill: 'transparent'}} iconFillColor={'#00a1a3'} />
                                        </LinkedinShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }

        if(this.state.showPopUpExportData){
            popUpMessage = (
                <section id="popUpMessage" onClick={this.ok}>
                    <div className="modal fade dvt-modal-social in" 
                        // ng-className="{in: animate}" ng-style="{'z-index': 1050 + index*10}" ng-transclude="" modal-window="" window-className="dvt-modal" 
                        index="0" style={{zIndex: 1050}}>
                        <div className="modal-wrapper">
                            <div className="modal-body">
                                <span className="btn pull-right" role="button" aria-hidden="true" title="Close window" onClick={this.ok}>
                                    <i className="fa fa-2x fa-times" aria-hidden="true"></i>
                                </span>
                                <div className="popUpMessage--exportData--text--block">
                                    <p className="text-center">{this.props.literals.L101029}</p>
                                    <div className="export--data--modal" id="osha-menu-social">
                                        {/* <!-- URL --> */}
                                        <a id="urlOption" href className={`main-color text-center ${this.state.pageUrlActive ? "active" : ""}`} target="_blank" 
                                            // onClick={this.downloadOptionVisible} 
                                            title={`${this.props.literals.L100662} > ${this.props.literals.L100660}`}>
                                            <i className="fa fa-link" aria-hidden="true"></i><span>{this.props.literals.L100660}</span>
                                        </a>
                                        {/* <!-- PNG --> */}
                                        <a id="pngOption" href className="main-color text-center" target="_blank" title={`${this.props.literals.L100662} > ${this.props.literals.L100657}`}
                                            onClick={this.exportPNG} data-ng-if="isIE == false">
                                            <i className="fa fa-file-image-o" aria-hidden="true"></i> <span>{this.props.literals.L100657}</span>
                                        </a>
                                        {/* <!-- PAGE CITATION --> */}
                                        <a id="pageOption" href className={`main-color text-center ${this.state.pageCitationActive ? "active" : ""}`} target="_blank" 
                                            title={`${this.props.literals.L100662} > ${this.props.literals.L100651}`}>
                                            <i className="fa fa-file-code-o" aria-hidden="true"></i> <span>{this.props.literals.L100651}</span>
                                        </a>
                                        {/* <!-- CSV --> */}
                                        <a id="csvOption" href className={`main-color text-center ${this.state.pageDownloadCSVActive ? "active" : ""}`} target="_blank" 
                                            title={`${this.props.literals.L100662} > ${this.props.literals.L101088}`}>
                                            <i className="fa fa-file-excel-o" aria-hidden="true"></i> <span>{this.props.literals.L101088}</span>
                                        </a>
                                    </div>

                                    {
                                        !this.state.pageUrlActive ? ("") : (
                                            <div id="panel--url" data-ng-if="pageUrlActive">
                                                <button id="btnSocial" className="btn-default btn-main-color" onClick={this.selectTextArea}>
                                                    <span>{this.props.literals.L100640}</span>
                                                </button>
                                                <textarea id="urlTextArea" readOnly cols="80" rows="3" value={this.state.pathURLDVT}></textarea>
                                                <div className="pageUrl">
                                                    <p>{this.props.literals.L100587}: {this.props.literals.L100660}</p>
                                                    <a id="btnSocial" className="btn-default btn-main-color" href={this.state.pathURLDVT} target="_blank">
                                                        <span>{this.props.literals.L100641}</span>
                                                    </a>
                                                    {/* <!--<button id="btnSocial" className="btn-default" data-ng-bind="i18n.L100638" onClick=""></button>--> */}
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        !this.state.pageCitationActive ? ("") : (
                                            <div id="panel--url" data-ng-if="pageCitationActive">
                                                <button id="btnSocial" className="btn-default btn-main-color" onClick={this.selectTextArea}>
                                                    <span>{this.props.literals.L100640}</span>
                                                </button>
                                                <textarea id="urlTextArea" readOnly cols="80" rows="5" value={this.createCitation()}></textarea>
                                                <div className="pageUrl">
                                                    <p>{this.props.literals.L100587}: {this.props.literals.L100660}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                    
                                    {
                                        !this.state.pageDownloadCSVActive ? "" : (
                                            <div id="panel--url">
                                                <CsvData 
                                                    csvData={this.props.csvData} 
                                                    filters={this.props.filters} 
                                                    chart={this.props.chart} 
                                                    page={this.props.page}
                                                    csvChartName={this.props.csvChartName}
                                                    />
                                                <div className="pageUrl">
                                                    <p>{this.props.literals.L101089}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }

        return(
            <div>
                <div className="export--button--wrapper">
                    <button id="btnSocial" className="btn-default btn-main-color" onClick={this.showSharePopUp}>
                        <span>{this.props.literals.L101028}</span>
                        <span><i className="fa fa-share-alt" aria-hidden="true"></i></span>
                    </button>
                    <button id="btnExport" className="btn-default btn-main-color" onClick={this.showSharePopUp}>
                        <span>{this.props.literals.L101029}</span>
                    </button>
                </div>

                {popUpMessage}
            </div>
        )
    }
}

export default Export;