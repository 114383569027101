import React, { useEffect } from 'react';
import { connect, useStore } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { setLanguage, setLocale, setLocaleValue, setLocaleValueWithLanguage } from './actions/';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import Header from './components/common/Header';
import Footer from './components/common/Footer';
import CookiesComponent from './components/common/cookies/CookiesComponent';

import './style/App.scss';

const App = ({children}) => 
{
	const history = useHistory();
	const store = useStore();
	const { lang, year, locale } = useParams();	
	const { trackPageView, enableLinkTracking } = useMatomo();

	// Run enableLinkTracking to track with Matomo the different pages visited by the user
	enableLinkTracking();

	useEffect(() => {
		store.dispatch(setLanguage(lang));
		store.dispatch(setLocaleValueWithLanguage(lang, year))
		store.dispatch(setLocale(store.getState().localeValue));

		trackPageView();
	},[lang]);

	useEffect(() => {
		if (locale != null && locale != undefined)
		{
			store.dispatch(setLocaleValue(locale));
		}
		else if ((locale == null || locale == undefined) && (store.getState().localeValue == undefined || store.getState().localeValue == ""))
		{
			store.dispatch(setLocaleValueWithLanguage(lang, year))
		}
		else if ((year != undefined && year != null) && (store.getState().localeValue == "en" || store.getState().localeValue == "en_1" || store.getState().localeValue == "is"))
		{
			store.dispatch(setLocaleValueWithLanguage(lang, year));
		}		
		store.dispatch(setLocale(store.getState().localeValue));

		trackPageView();
	},[year, locale]);

	// Track with Matomo the current page
	useEffect(() => {
		trackPageView();
	}, [])

	return(
		<div>
			<CookiesComponent literals={useStore().getState().literals} lang={lang} />

			<Header history={history} lang={lang} year={year} literals={useStore().getState().literals} locale={useStore().getState().localeValue} />
			
			{children}

			<Footer lang={lang} literals={useStore().getState().literals}/>
		</div>
	)
}

function mapStateToProps(state){
    const {literals} = state;
	const {localeLiterals} = state;
	const {localeValue} = state;
    return {
        literals,
		localeLiterals,
		localeValue
    }
}

export default connect(mapStateToProps, { setLanguage, setLocale, setLocaleValue, setLocaleValueWithLanguage })(App);

// export default App;
