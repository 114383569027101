import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { dataVisualisationLabel } from '../../model/PageLabels';

class DataVisualisation extends Component
{
	constructor(props){
		super(props);
		this.state = {
			topic: "",
			chart: "",
			isTopicSelected: false,
			isChartSelected: false,
			previousTopic:"",
			previousChart: "",
			question: "",
			answer: "",
			country1: "",
			country2: ""
		}
	}

	componentDidMount() {
		document.title = dataVisualisationLabel;
	}

	componentDidUpdate(prevProps, prevState){
		var question = "";
		var answer = "";
		var country1 = "EU28";
		var country2 = "";
		
		if(prevState.topic != this.state.topic){
			if(this.state.topic == 'osh-management'){
				if (this.props.year == '2009')
				{
					question = 'MM161';
				}
				else if (this.props.year == '2014')
				{
					question = 'Q250';
					// $rootScope.question = 'E3Q250';
				}
				else
				{
					question = 'E3Q250';
					// $rootScope.question = 'E3Q250';
				}
				answer = 1;
			}else if(this.state.topic == 'psychosocial-risks-and-their-management'){
				if (this.props.year == '2009')
				{
					question = 'MM250';
				}
				else if (this.props.year == '2014')
				{
					question = 'Q300';
					// $rootScope.question = 'E3Q300';
				}
				// else if(this.props.year == '2019'){
				// 	question = "E3Q300";
				// }
				answer = 1;
			}else if(this.state.topic == 'emerging-risks-and-their-management'){
				question = "E3Q300";
				// $rootScope.question = 'E3Q300';
				answer = 1;
			}else if(this.state.topic == 'drivers-and-barriers'){
				question = this.props.year == '2009' ? 'MM171_1':'Q264_1';
				if (this.props.year == '2009')
				{
					question = 'MM171_1';
					answer = 100;
				}
				else if (this.props.year == '2014')
				{
					question = 'Q264_1';
					// $rootScope.question = 'E3Q262_1';
					answer = 44;
				}
				else
				{
					question = 'E3Q262_1';
					// $rootScope.question = 'E3Q262_1';
					answer = 44;
				}
				
			}else if(this.state.topic == 'worker-participation'){
				question = this.props.year == '2009' ? 'MM355all':'Q358';
				if (this.props.year == '2009')
				{
					question = 'MM355all';
				}
				else if (this.props.year == '2014')
				{
					question = 'Q358';
				}
				else
				{
					question = 'E3Q357';
				}
				answer = 1;
			}
			this.setState({
				question: question,
				answer: answer
			});
		}

		if(prevState.chart != this.state.chart){
			if(this.props.year === "2019"){
				country1 = "EU27_2020";
			}

			if(this.state.chart === "detailpage-national-comparisons"){
				country2 = "AT";
			}

			if(this.state.chart === "detailpage-european-map" || this.state.chart === "detailpage-european-bar-chart"){
				country1 = "";
			}

			this.setState({
				country1: country1,
				country2: country2
			});
		}
	}

	onClickLink = (e) => {
		if (this.state.isTopicSelected == '' || this.state.isChartSelected == '') {
			e.preventDefault()
		}
	}

	changeTopic = (topicSelected) => (event) => {
		console.log('changeTopic');
		// console.log('event',event);
		// console.log('topicSelected',topicSelected);
		let topicYearDepending = topicSelected;

		if(topicSelected === "risks"){
			if(this.props.year === "2019"){
				topicYearDepending = "emerging-risks-and-their-management";
			}else{
				topicYearDepending = "psychosocial-risks-and-their-management"; 
			}
		}

		this.setState({topic: topicYearDepending})

		if(this.state.previousTopic != topicYearDepending){
			// console.log('this.state.previousTopic != topicSelected');
			this.setState({topic: topicYearDepending, isTopicSelected: true, previousTopic: topicYearDepending});
		}else{
			// console.log('same chart than previous one');
			this.setState({topic: "", isTopicSelected: false, previousTopic: ""});
		}

		// angular.element('.section-topic .card--item').removeClass('selected');
		// angular.element('.section-topic .card--item').removeClass('tablet');
		// angular.element('.section-topic .card--item').addClass('disabled');

		// if( $event.target.nodeName == "SPAN" ){
		// 	$event.target.parentElement.parentElement.classList.remove('disabled');
		// 	$event.target.parentElement.parentElement.classList.add('selected');

		// 		if(configService.isMobile()) {
		// 			$event.target.parentElement.parentElement.classList.add('tablet');
		// 		}

		// } else {
		// 	$event.target.parentElement.classList.remove('disabled');
		// 	$event.target.parentElement.classList.add('selected');

		// 	if(configService.isMobile()) {
		// 		$event.target.parentElement.classList.add('tablet');
		// 	}

		// }
	}

	changeChart = (chartSelected) => (event) => {
		// console.log('event',event);
		// console.log('chartSelected',chartSelected);

		if(this.state.previousChart != chartSelected){
			// console.log('this.state.previousChart != chartSelected');
			this.setState({chart: chartSelected, isChartSelected: true, previousChart: chartSelected});
		}else{
			// console.log('same chart than previous one');
			this.setState({chart: "", isChartSelected: false, previousChart: ""});
		}
		
		// angular.element('.section-chart .card--item').removeClass('selected');
		// angular.element('.section-chart .card--item').removeClass('tablet');
		// angular.element('.section-chart .card--item').addClass('disabled');
		// if( $event.target.nodeName == "SPAN" ){
		// 	$event.target.parentElement.parentElement.classList.remove('disabled');
		// 	$event.target.parentElement.parentElement.classList.add('selected');

		// 		if(configService.isMobile()) {
		// 			$event.target.parentElement.parentElement.classList.add('tablet');
		// 		}

		// } else {
		// 	if( $event.target.nodeName == "LI" ){
		// 		$event.target.classList.remove('disabled');
		// 		$event.target.classList.add('selected');
		// 	}
		// 	else{
		// 		$event.target.parentElement.classList.remove('disabled');
		// 		$event.target.parentElement.classList.add('selected');
		// 	}

		// 	if(configService.isMobile()) {
		// 		$event.target.parentElement.classList.add('tablet');
		// 	}
		// }
	}

	constructUrl(){
		let sectorSize = "activity-sector";
		if(this.state.chart === "detailpage-national-bar-chart"){
			sectorSize = "company-size";
		}
		var url = "/"+this.props.lang+"/survey/"+this.state.chart+"/"+this.props.year+"/"+this.state.topic+"/"+this.props.localeValue+"/"+this.state.question;
		if(this.state.chart === "detailpage-european-map"){
			url = url+"/"+sectorSize+"/14/11/"+this.state.answer;
		}else if(this.state.chart === "detailpage-european-bar-chart"){
			url = url+"/"+sectorSize+"/14/11";
		}else if(this.state.chart === "detailpage-national-bar-chart"){
			url = url+"/"+sectorSize+"/"+this.state.country1;
		}else if(this.state.chart === "detailpage-national-comparisons"){
			url = url+"/"+sectorSize+"/14/11/"+this.state.country1+"/"+this.state.country2;
		}else{
			url = url+"/"+this.state.country1;
		}
		return url;
	}

	render()
	{
		// console.log('this.state',this.state);
		var url = this.constructUrl();
		// console.log("Going to url: "+url);

		return(
			<div className={`datavisualisation-page ${this.props.lang}`}>
                <h1 className="title--section main-color outleft">{this.props.literals.L101007}</h1>
				{/* <!-- Step 1 --> */}
				<section className="container section--page section-topic">
					<h2 className="title--section main-color text-center">{this.props.literals.L101021}</h2>
					<p className="text-center subtitle--section">{this.props.literals.L101023}</p>
					<ul className="card--grid min-height">
						<li className={`card--item ${this.state.isTopicSelected && this.state.topic === "osh-management" ? "selected" : !this.state.isTopicSelected ? '' : 'disabled'}`}>               
							<a className="icon--card osh-management-icon" onClick={this.changeTopic('osh-management')}>
								<span>{this.props.literals.L100001}</span>
							</a>
						</li>
						<li className={`card--item ${this.state.isTopicSelected && (this.state.topic === "emerging-risks-and-their-management" 
							|| this.state.topic === "psychosocial-risks-and-their-management") ? "selected" : !this.state.isTopicSelected ? '' : 'disabled'}`}>
							<a className="icon--card psychosocial-risk-icon" onClick={this.changeTopic('risks')}>
								<span >{this.props.year != "2019" ? this.props.literals.L100009 : this.props.literals.L102017}</span>
							</a> 
						</li>
						<li className={`card--item ${this.state.isTopicSelected && this.state.topic === "drivers-and-barriers" ? "selected" : !this.state.isTopicSelected ? '' : 'disabled'}`}>
							<a className="icon--card drivers-barriers-icon" onClick={this.changeTopic('drivers-and-barriers')}>
								<span>{this.props.literals.L100015}</span>
							</a>
						</li>
						<li className={`card--item ${this.state.isTopicSelected && this.state.topic === "worker-participation" ? "selected" : !this.state.isTopicSelected ? '' : 'disabled'}`}>
							<a className="icon--card workers-participation-icon" onClick={this.changeTopic('worker-participation')}>
								<span>{this.props.literals.L100021}</span>
							</a>
						</li>
					</ul>
				</section>
				{/* <!-- Step 2 --> */}
				<section className="container section--page  section-chart">
					<h2 className="title--section main-color text-center">{this.props.literals.L101022}</h2>
					<p className="text-center subtitle--section">{this.props.literals.L101024}</p>
					<ul className="card--grid min-height">
						<li className={`card--item ${this.state.isChartSelected && this.state.chart === "detailpage-european-map" ? "selected" : !this.state.isChartSelected != false ? '' : 'disabled'}`} onClick={this.changeChart('detailpage-european-map')}>                
							<a className="icon--card european-maps-icon" onClick={this.changeChart('detailpage-european-map')}>
								<span>{this.props.literals.L100623}</span>
							</a>
							<p className="text-center" onClick={this.changeChart('detailpage-european-map')}>{this.props.literals.L100614}</p>
						</li>
						<li className={`card--item ${this.state.isChartSelected && this.state.chart === "detailpage-european-bar-chart" ? "selected" : !this.state.isChartSelected != false ? '' : 'disabled'}`} onClick={this.changeChart('detailpage-european-bar-chart')}>
							<a className="icon--card eu-bar-chart-icon" onClick={this.changeChart('detailpage-european-bar-chart')}>
								<span>{this.props.literals.L100622}</span>
							</a>
							<p className="text-center" onClick={this.changeChart('detailpage-european-bar-chart')}>{this.props.literals.L100612}</p>
						</li>
						<li className={`card--item ${this.state.isChartSelected && this.state.chart === "detailpage-national-bar-chart" ? "selected" : !this.state.isChartSelected != false ? '' : 'disabled'}`} onClick={this.changeChart('detailpage-national-bar-chart')}>
							<a className="icon--card national-bar-chart-icon" onClick={this.changeChart('detailpage-national-bar-chart')}>
								<span>{this.props.literals.L100624}</span>
							</a>
							<p className="text-center" onClick={this.changeChart('detailpage-national-bar-chart')}>{this.props.literals.L100616}</p>
						</li>
						<li className={`card--item ${this.state.isChartSelected && this.state.chart === "detailpage-national-comparisons" ? "selected" : !this.state.isChartSelected != false ? '' : 'disabled'}`} onClick={this.changeChart('detailpage-national-comparisons')}>
							<a className="icon--card national-comparision-icon" onClick={this.changeChart('detailpage-national-comparisons')}>
								<span>{this.props.literals.L100621}</span>
							</a>
							<p className="text-center" onClick={this.changeChart('detailpage-national-comparisons')}>{this.props.literals.L100610}</p> 
						</li>
						<li className={`card--item ${this.state.isChartSelected && this.state.chart === "detailpage-pie-chart" ? "selected" : !this.state.isChartSelected != false ? '' : 'disabled'}`} onClick={this.changeChart('detailpage-pie-chart')}>
							<a className="icon--card pie-chart-icon" onClick={this.changeChart('detailpage-pie-chart')}>
								<span>{this.props.literals.L101033}</span>
							</a>
							<p className="text-center" onClick={this.changeChart('detailpage-pie-chart')}>{this.props.literals.L101025}</p>
						</li>
					</ul>
				</section>

				<p className="text-center">
					<Link className={(this.state.topic === "" || this.state.chart === "") ? "disabled" : ""}
						to={url} onClick={(e) => this.onClickLink(e)}>
							<button disabled={(this.state.topic === "" || this.state.chart === "") ? "disabled" : ""} 
								className={`btn-default btn-main-color ${(this.state.topic === "" || this.state.chart === "") ? "disabled" : ""}`}>
									{this.props.literals.L101020}
							</button>
					</Link>
				</p>
			</div>
		)
	}
}

function mapStateToProps(state){
    const {literals} = state;
	const {localeValue} = state;
    return {
        literals,
		localeValue
    }
}

export default connect(mapStateToProps, null)(DataVisualisation);
// export default DataVisualisation;