import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
//Doesn't work
// import { CSVDownload, CSVLink } from 'react-csv'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

const CsvData = (props) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const [csvData, setCsvDataTest] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [fileName, setFileName] = useState('');
    const [sheetsName, setSheetsName] = useState('');

    // Sets the name for the file
    useEffect(() => {
        const page = props.page;
        const csvChartName = props.csvChartName;
        let filename;
        if (props.page == 'comparisons') {
            filename = `${page}_${props.filters.sectorSize}_${props.filters.question}_${props.filters.country}`;
        } else if (props.chart == 'european-bar-chart') {
            filename = `${csvChartName}-${props.filters.sectorSize}-${props.filters.activitySector}-${props.filters.question}`;
        } else if (props.chart == 'national-bar-chart') {
            filename = `${csvChartName}-${props.filters.sectorSize}-${props.filters.question}-${props.filters.country}`;
        } else if (props.chart == 'national-comparisons') {
            filename = `${csvChartName}-${props.filters.sectorSize}-${props.filters.activitySector}-${props.filters.question}-${props.filters.country}-${props.filters.country2}`;
        } else if (props.chart == 'pie-chart') {
            filename = `${csvChartName}-${props.filters.question}`;
        } else {
            filename = `${csvChartName}-${props.filters.sectorSize}-${props.filters.activitySector}-${props.filters.question}-${props.filters.answer}`;
        }
        
        setSheetsName(filename.slice(0, 31));
        setFileName(filename)
    }, [props.filters.sectorSize, props.filters.question, props.filters.country, props.page, props.chart])

    // Sets the data to transform to xlsx
    useEffect(() => {
        let auxSeries;
        let data = [];
        let answer = '';

        try {
            setDataLoaded(true)
            if (props.page == 'comparisons') {
                if (props.csvData && props.csvData.dataForCsv != null) {
                    auxSeries = props.csvData.dataForCsv;
                    if (props.filters.answer == '1') {
                        answer = 'Yes'
                    } else {
                        answer = 'No'
                    }
                    if (props.filters.sectorSize == 'activity-sector') {
                        auxSeries.forEach((element) => {
                            data.push({Question: element.question, Sector: element.name, Answer: answer, Value: element.value });
                        })
                    } else {
                        auxSeries.forEach((element) => {
                            data.push({Question: element.question, Size: element.name, Answer: answer, Value: element.value });
                        })
                    }
                }
            }

            if (props.chart == 'european-map' || props.chart == 'european-bar-chart') {
                if (props.csvData && props.csvData != null) {
                    const dataArray = props.csvData.sort((a,b) => a.country < b.country ? -1 : 1)

                    dataArray.forEach((element) => {
                        data.push({Country: element.country, Question: props.filters.question, Subset: element.subset, Answer: element.answer, Percentage: element.value })
                    })
                }
            }

            if (props.chart == 'national-bar-chart') {
                if (props.csvData && props.csvData != null) {
                    const dataArray = props.csvData.sort((a,b) => a.subset < b.subset ? 1 : -1);

                    dataArray.forEach((element) => {
                        data.push({ Country: props.filters.country, Question: props.filters.question, ...element })
                    })
                }
            }

            if (props.chart == 'national-comparisons') {
                if (props.csvData && props.csvData != null) {
                    props.csvData.forEach((element) => {
                        data.push({ Country: element.country, Question: element.question, SubSector: element.subsector, Answer: element.answer, Percentage: element.value });
                    })
                }
            }

            if (props.chart == 'pie-chart') {
                if (props.csvData && props.csvData != null) {

                    props.csvData.forEach((element) => {
                        data.push({ country: props.filters.country, ...element })
                    })
                    
                    data.reverse();
                }
            }

            setCsvDataTest(data);

        } catch (error) {
            console.log('Error on csvData', error.message)
        } finally {
            setDataLoaded(false);
        }

    }, [props.csvData])

    const exportToCSV = (csvData, fileName, sheetsName) => {
        return () => {
            const ws = XLSX.utils.json_to_sheet(csvData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, sheetsName);
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    //early return
    if (dataLoaded) {
        return <div>Loading Data....</div>
    }

    return (
        <button id="btnSocial" className="btn-default btn-main-color" onClick={exportToCSV(csvData, fileName, sheetsName)} >
            <span>{props.literals.L101090}</span>
        </button>
    );
};

const mapStateToProps = (state) => {
    return {
        literals: state.literals
    }
}

export default connect(mapStateToProps)(CsvData);