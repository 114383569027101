import { nodeName } from 'jquery';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getComparisonQuestionID } from '../../api';

class Submenu extends Component {
    constructor(props){
        super(props);
        this.state = {
            height: window.innerHeight, 
            width: window.innerWidth, 
            class: "", 
            section:'overview', 
            questionComparison: {question: 'E3Q250', topic:'osh-management', answer: 1},
            countryComparison: 'EU27_2020'
        };
    }

    isHomeOrFooterPage = () => {
        if(location.pathname.includes("accessibility")
            || location.pathname.includes("privacy-notice")
            || location.pathname.includes("cookies")
            || location.pathname.includes("legal-notice")
            || location.pathname.includes("sitemap")
            || location.pathname.includes("about-tool")
            || location.pathname.includes("page-not-found") || location.pathname === "/esener/"+this.props.lang){
            return true;
        } else {
            return false;
        }
    }

    isDetailPage = () => {
        if(location.pathname.includes("datavisualisation")
            || location.pathname.includes("detailpage-european-map")
            || location.pathname.includes("detailpage-national-bar-chart")
            || location.pathname.includes("detailpage-national-comparisons")
            || location.pathname.includes("detailpage-pie-chart")
            || location.pathname.includes("detailpage-european-bar-chart")){
            return "active";
        } else {
            return "";
        }
    }

    hideArrow = (section) => {
        if(location.pathname.includes("datavisualisation")
            || location.pathname.includes("detailpage-european-map")
            || location.pathname.includes("detailpage-national-bar-chart")
            || location.pathname.includes("detailpage-national-comparisons")
            || location.pathname.includes("detailpage-pie-chart")
            || location.pathname.includes("detailpage-european-bar-chart")
            || location.pathname.includes(section)){
            return "hide";
        } else {
            return "";
        }
    }

    isSectionSelected = (section) => {
        if(location.pathname.includes(section) && !location.pathname.includes('detailpage-national-comparisons')){
            return "active";
        } else {
            return "";
        }
    }

    isYearSelected = (year) => {
        if(this.props.year === year){
            return "active";
        } else {
            return "";
        }
    }

    openIndicatorsList = event => {
        //console.log('event.target',event.target.nodeName);

        if( this.state.width < 768 ){
            // console.log('this.state.width is less than 768');
            var isActive = false;
            var parentTag = null;
            // console.log('event.target',event.target.nodeName, event.target.className.indexOf("active"), event.target.parentElement.className);
            if(event.target.nodeName == "A"){
                parentTag = event.target.parentElement.className;
                if (event.target.className.indexOf("active") > 0)
                {
                    isActive = true;

                } else {
                    isActive = false;
                    this.setState({class: ""});
                }
            } else {
                parentTag = event.target.parentElement.className;
                isActive = true;
            }
            
            if( parentTag.indexOf('open-list') < 0 && isActive){
                this.setState({class: " open-list"});
            } else if (isActive) {
                this.setState({class: ""});
            }
        }
    }

    updateComparisonQuestion = question => {
        try{
            getComparisonQuestionID(question)
                .then((res) => {
                    let questionComparison = {question: 'E3Q250', topic:'osh-management', answer: 1}
					if (res.resultset.name != null)
                    {
                        let topic = res.resultset.topic.toLowerCase().replace(/[\,\ ]/g, '-');
                        questionComparison = {question: res.resultset.name, topic: topic, answer: res.resultset.answer}
                    }
                    this.setState({ questionComparison: questionComparison });
				})
        } catch (error) {
			console.log('Error fetching selector data:', error)
		}
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps.comparisonProps != this.props.comparisonProps)
        {
            // comparisonProps updated, check if the question has changed
            if (this.props.comparisonProps.question != prevProps.comparisonProps.question)
            {
                this.updateComparisonQuestion(this.props.comparisonProps.question);
            }

            if (this.props.comparisonProps.country != undefined)
            {
                this.setState({countryComparison: this.props.comparisonProps.country})
            }
        }
    }

    updateSection (section)
    {
        this.setState({section: section});
    }

    render(){
        // console.log('location',location.pathname);
        // console.log('location.pathname.includes',location.pathname.includes("overview"));
        const overviewOrComparison = (location.pathname.includes("overview") || location.pathname.includes("comparisons")) ? "hide" : "" ;
        const overviewOrComparisonOr2009 = (location.pathname.includes("overview") || location.pathname.includes("comparisons") || this.props.year === "2009") ? "hide" : "" ;
        const is2009 = this.props.year === "2009" ? "hide" : "";

        let showFullSection;

        if(!this.isHomeOrFooterPage()){
            showFullSection = (
                <div>
                    <section className="container-fluid level2--wrapper">
                        <div className="container submenu--level2">
                        <ul className={"submenu--items--wrapper"+this.state.class}>
                            {/* <!-- ESENER YEAR SELECTION --> */}
                            <li onClick={this.openIndicatorsList} className={"submenu--item "+this.isYearSelected('2019')}>
                                <NavLink to={"/"+this.props.lang+"/survey/overview/2019"} className={this.isYearSelected('2019')} onClick={this.openIndicatorsList}>{this.props.literals.L101079}</NavLink>
                            </li>
                            <li onClick={this.openIndicatorsList} className={"submenu--item "+this.isYearSelected('2014')}>
                                <NavLink to={"/"+this.props.lang+"/survey/overview/2014"} className={this.isYearSelected('2014')} onClick={this.openIndicatorsList}>{this.props.literals.L101008}</NavLink>
                            </li>
                            <li onClick={this.openIndicatorsList} className={"submenu--item "+this.isYearSelected('2009')}>
                                <NavLink to={"/"+this.props.lang+"/survey/overview/2009"} className={this.isYearSelected('2009')} onClick={this.openIndicatorsList}>{this.props.literals.L101007}</NavLink>
                            </li>
                        </ul>
                        </div>
                    </section>
                    <div className="line"></div>
                    <section className="container-fluid level3--wrapper">
                        <div className="submenu--level3 container">
                        <ul  className="submenu--arrows--wrapper show-mobile">
                            <li className={"left "+overviewOrComparison} onClick={() => this.updateSection('overview')}>
                                <NavLink to={"/"+this.props.lang+"/survey/overview/"+this.props.year} title="OVERVIEW">
                                    <i className="fa fa-angle-left" aria-hidden="true"></i> 
                                    <span className="sr-only">Previous option</span>
                                </NavLink>
                            </li>
                            <li className={"left "+this.hideArrow("overview")} onClick={() => this.updateSection('detail-page')}>
                                <NavLink to={"/"+this.props.lang+"/survey/datavisualisation/"+this.props.year} title="DATA VISUALISATION">
                                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                                    <span className="sr-only">Previous option</span>
                                </NavLink>
                            </li>

                            <li className={"right "+this.hideArrow("comparisons")} onClick={() => this.updateSection('detail-page')}>
                                <NavLink to={"/"+this.props.lang+"/survey/datavisualisation/"+this.props.year} title="DATA VISUALISATION">
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                    <span className="sr-only">Next option</span>
                                </NavLink>
                            </li>
                            <li className={"right "+overviewOrComparisonOr2009} onClick={() => this.updateSection('comparison')}>
                                <NavLink title="COMPARISONS" to={"/"+this.props.lang+"/survey/comparisons/"+this.props.year+"/"+this.state.questionComparison.topic+"/"+this.props.locale+"/company-size/"+this.state.questionComparison.question+"/EU27_2020/"+this.state.questionComparison.answer} >
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                    <span className="sr-only">Next option</span>
                                </NavLink>
                            </li>
                        </ul>
                        <ul className={`submenu--items--wrapper ${this.props.lang}`}>
                            <li className={`submenu--item ${this.isSectionSelected("overview")}`} onClick={() => this.updateSection('overview')}>
                                <NavLink to={"/"+this.props.lang+"/survey/overview/"+this.props.year} 
                                className={"submenu--item "+this.isSectionSelected("overview")}>
                                    {this.props.literals.L101009}
                                </NavLink>
                            </li>
                            <li className={`submenu--item ${this.isDetailPage()}`} onClick={() => this.updateSection('detail-page')}>
                                    <NavLink to={"/"+this.props.lang+"/survey/datavisualisation/"+this.props.year} 
                                    className={this.isDetailPage()}>
                                        {this.props.literals.L101010}
                                    </NavLink>
                            </li>
                            <li className={`submenu--item ${ location.pathname.includes("comparisons") && !location.pathname.includes('detailpage-national-comparisons') ? 'active' : '' } ${is2009}`} onClick={() => this.updateSection('comparisons')}>
                                <NavLink to={"/"+this.props.lang+"/survey/comparisons/"+this.props.year+"/"+this.state.questionComparison.topic+"/"+this.props.locale+"/company-size/"+this.state.questionComparison.question+"/"+this.state.countryComparison+"/"+this.state.questionComparison.answer}
                                className={this.isSectionSelected("comparisons")}>
                                    {this.props.literals.L101080}
                                </NavLink>
                            </li>
                        </ul>
                        </div>
                    </section>
                </div>
            );
        }
    
        return(
            <div>
                {showFullSection}
            </div>
        )
    }
}

function mapStateToProps(state){
    const {comparisonProps} = state;
    return {
        comparisonProps
    }
}

export default connect(mapStateToProps, null)(Submenu);