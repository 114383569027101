import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCurrentComparisonQuestionData } from '../../api/index';
import { comparisonLabel } from '../../model/PageLabels';
import ComparisonColumnChart from '../common/charts/ComparisonColumnChart';
import ComparisonSelector from '../common/ComparisonSelector';
import Export from '../common/Export';
import QuestionList from '../common/QuestionList';
import UrlChanger from '../common/urlChanger/UrlChanger';
class Comparison extends Component
{
	constructor(props) {
		super(props);
		
		this.state = {
			isLoading: true,
			questionObject: {
				answerID: "", //answer_id
				bottomTextLiteralID: "", //bottomText
				fatherLiteralID: "", //father
				grandfatherLiteralID: "", //grandfather
				level: "", //level
				name2LiteralID: "", //name2
				name3LiteralID: "", //name3
				nextCategory: "", //nextID
				nextFatherID: "", //nextFatherID
				nextFatherLiteralID: "", //nextFatherName
				nextLevel: "", //nextLevel
				nextLiteralID: "", //nextName
				previousCategory: "", //previousID
				previousFatherID: "", //previousFatherID
				previousFatherLiteralID: "", //previousFatherName
				previousLevel: "", //previousLevel
				previousLiteralID: "" //previousName
			},
			filters: {
				question: this.props.question,
				topic: this.props.topic,
				country: this.props.country,
				answer: this.props.answer == undefined ? '1' : this.props.answer,
				sectorSize: this.props.sectorSize,
				locale: this.props.locale,
			},
			csvData: null,
			questionRef: React.createRef()
		}
	}

	//TODO: Leave in case of being needed, otherwise, delete
	changeToQuestion = (questionSelected, topicSelected) => {

		if (topicSelected == null) {
			this.setState({ filters: {...this.state.filters, question: questionSelected.value} })
		}

		if (questionSelected && topicSelected) {
			this.setState({ filters: {...this.state.filters, question: questionSelected.value, topic: topicSelected} })
		}

	}

	getComparisonQuestionData = () => {
		this.setState({ isLoading: true })
		try {
			getCurrentComparisonQuestionData(this.state.filters.question)
				.then((data) => {
					this.setState({ questionObject: data.resultset });
					this.state.questionRef.current = this.props.question;
				})

		} catch {
			console.log('error fetching: ', error)
		} finally {
			this.setState({ isLoading: false })
		}
	}

	rulesForTooltip = (question) => {
		// RULES ESENER COMPARISON 2014 and 2019
		if (this.props.year == '2014' || this.props.year == '2019') {
			if ((question == 'E3Q350_1') && (this.state.filters.country == 'MK' || this.state.filters.country == 'CY'
			|| this.state.filters.country == 'EE' || this.state.filters.country == 'SE')) {
				alert(this.props.literals.L100576);
				return true;
			} else if ((question == 'E3Q350_2') && (this.state.filters.country == 'AT' || this.state.filters.country == 'DE' 
			|| this.state.filters.country == 'LU' )) {
				alert(this.props.literals.L100576);
				return true;
			} else if ((question == 'E3Q650_3') && (this.state.filters.country == 'MK' || this.state.filters.country == 'SI')) {
				alert(this.props.literals.L100576);
				return true;
			} else if (['E3Q260_1','E3Q260_2','E3Q260_3','E3Q260_4'].indexOf(question)>-1 && this.state.filters.country != 'EU27_2020') {
				if (this.state.filters.sectorSize == 'activity-sector') {
					alert(this.props.literals.L100576);
					return true;
				}
			} else if ((question == 'E3Q350_3') && (this.state.filters.country == 'MK' || this.state.filters.country == 'SI')) {
				alert(this.props.literals.L100576);
				return true
			}
			return false
		}
	}

	callbackSeries = (callbackSeries) => {
		this.setState({ csvData: callbackSeries })
	}

	changeToNewFilters = (newFilters) => {
		this.setState({ filters: newFilters });
	}
	
	componentDidMount() {
		document.title = comparisonLabel;

		this.getComparisonQuestionData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.question != this.props.question) {
			this.setState({ filters: {...this.state.filters, question: this.props.question} })
		}

		if (prevProps.locale != this.props.locale)
		{
			this.setState({ filters: {...this.state.filters, locale: this.props.locale } })
		}

		if (prevState.filters != this.state.filters) {
			this.getComparisonQuestionData();
		}

		if (prevState.questionObject.name3LiteralID != this.state.questionObject.name3LiteralID) {
			if (this.state.filters.question != this.state.questionRef.current && this.state.questionRef.current != undefined) {
				if (this.state.filters.answer != this.state.questionObject.answerID && this.state.questionObject.answerID != '') {
					this.setState({ filters: { ...this.state.filters, answer: this.state.questionObject.answerID } })
				}
			}
		}
	}

	render()
	{
		return(
			<div className="comparisons en">
                <div className="container-fluid detail--page--wrapper">
					<UrlChanger filters={this.state.filters} year={this.props.year} lang={this.props.lang} locale={this.props.locale} />
					<QuestionList 
						filters={this.state.filters} 
						year={this.props.year}
						lang={this.props.lang}
						locale={this.props.locale}
						callback={this.changeToQuestion} 
						rules={this.rulesForTooltip}	
					/>
					<section className="detail--content--section">
						<ComparisonSelector 
							year={this.props.year}
							dataforYears={['2014', '2019']}
							filters={this.state.filters} 
							questionProperties={this.state.questionObject}
							question={this.props.question}
							lang={this.props.lang}
							locale={this.props.locale}
							callback={this.changeToNewFilters}
							rules={this.rulesForTooltip}
							csvData={this.state.csvData}
						/>
						<div className="chart--section">
							<div className="comparisons-chart">
								<ComparisonColumnChart 
									filters={this.state.filters} 
									lang={this.props.lang}
									type={'column'}
									percentage={true}
									callbackSeries={this.callbackSeries}
								/>
							</div>
						</div>
						<Export 
							literals={this.props.localeLiterals}
							year={this.props.year} 
							filters={this.state.filters} 
							questionProperties={this.state.questionObject} 
							page={'comparisons'} 
							chart={'comparisons'}
							csvData={this.state.csvData} />
						
					</section>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const {literals} = state;
	const {localeLiterals} = state;
	return {
		literals,
		localeLiterals
	}
}

export default connect(mapStateToProps)(Comparison);


/**
 * <CsvData csvData={this.state.csvData} filters={this.state.filters} page={'comparisons'} />
 */