import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Submenu from './Submenu';
import Breadcrumb from './Breadcrumb';
import LanguageSelect from '../common/LanguageSelect';
import EURibbon from './EURibbon';
import logos from '../../style/img/eu-osha-logo/headerLogos';
import $ from 'jquery'; 


class Header extends Component
{
    constructor(props){
        super(props);
        this.state = {
            languageSelected: ""
        }
    }

    zoomSmall = () => {
        $('html').css('font-size','8px');
        $('body').removeClass('plus').addClass('minor');
    }

    zoomMedium = () => {
        $('html').css('font-size','10px');
        $('body').removeClass('plus');
        $('body').removeClass('minor');
    }

    zoomBig = () => {
        $('html').css('font-size','12px');
        $('body').removeClass('minor');
        $('body').addClass('plus');
    }

    printWindow = () => {
        window.print();
    }

    componentDidMount(){
		window.onscroll = function() {myFunction()};
		var prevScrollpos = window.pageYOffset;

		document.addEventListener('click', this.closeModalOnClick, true);

		function myFunction() {
            $('.ribbon-eu').removeClass("open");
            // $('.our-websites-menu .container').hide();
		  	if (document.body.scrollTop > 90 || document.documentElement.scrollTop > 90) {
				var gotopVisible = window.height + window.height/2;
				var currentScrollPos = window.pageYOffset;
				var resolution = screen.width;
				if (prevScrollpos > currentScrollPos) {
					$(".bar-header").addClass('show-header');
					$(".affix").addClass('show-header');
					$(".affix").removeClass('hide-header');
					$(".bar-header").removeClass('hide-header');
				} else {
					$(".bar-header").addClass('hide-header');
					$(".affix").addClass('hide-header');
					$(".affix").removeClass('show-header');
					$(".bar-header").removeClass('show-header');
				}
				prevScrollpos = currentScrollPos;

				$('.go-to').css('display','block');			
			} else {		

				$(".bar-header").addClass('show-header');
				$(".affix").addClass('show-header');
				$(".affix").removeClass('hide-header');
				$(".bar-header").removeClass('hide-header');

				$('.go-to').css('display','none');
		  	}
              $(".navbar-toggle").removeClass("exposed");
              $(".bar-main-menu").removeClass("exposed");
              $("#main-menu").removeClass("exposed");
              $(".navbar-nav li.dropdown").removeClass("open");		  
		}

        window.onresize = function() {
            $('.ribbon-eu').removeClass("open");
			if (window.innerWidth <= 767) {  
				$('.our-websites-menu nav').hide();
			 } else {
				$('.our-websites-menu nav').show();
			 }
		}

		$(".view-our-webs").click(function() {  
			if($('.our-websites-menu nav:visible').length){
				$('.our-websites-menu nav').hide();
				$('.our-websites-menu nav ul li:first-child').show(); 
			} else {
				$('.our-websites-menu nav').show();
				$('.our-websites-menu nav ul li:first-child').hide(); 
			}
				   
		});

        $("#EUAddresses").click(function() {  
            $('.ribbon-eu').toggleClass("open");
        });


        $(".navbar-toggle").on('click', function (){
            $(this).toggleClass("exposed");
            $(".bar-main-menu").toggleClass("exposed");
            $("#main-menu").toggleClass("exposed");
            $(".navbar-nav li.dropdown").removeClass("open");
        })
        $(".navbar-nav li.dropdown .dropdown-toggle").on('click', function (){
            if( $(this).parent().attr('class').indexOf("open") > 0 ){
                $(this).parent().toggleClass("open");
            } else {
                $(".navbar-nav li.dropdown").removeClass('open');
                $(this).parent().toggleClass("open");
            }
        })
        $(".navbar-nav li.dropdown .dropdown-menu li").on('click', function(){
            $(".navbar-toggle").removeClass("exposed");
            $(".bar-main-menu").removeClass("exposed");
            $("#main-menu").removeClass("exposed");
            $(".navbar-nav li.dropdown").removeClass("open");
        })
    }

    isCurrentSection = (id) => {
        let currentPath = "";
        
        if(this.props.history.location.pathname.substring(3) == ""){
            currentPath = 'home';
        }else{
            if(this.props.history.location.pathname.substring(4).lastIndexOf('/') != -1){
                currentPath = this.props.history.location.pathname.substring(4, 10);
            }else{
                currentPath = this.props.history.location.pathname.substring(4);
            }
        }

        if(id === currentPath){
            return "main-menu-selected";
        }else if( id === "survey" && (currentPath === "datavisualisation" || currentPath === "comparisons"
            || currentPath === "detailpage-european-map"
            || currentPath === "detailpage-national-bar-chart"
            || currentPath === "detailpage-national-comparisons"
            || currentPath === "detailpage-pie-chart"
            || currentPath === "detailpage-european-bar-chart"
            || currentPath === "overview")){
            return "main-menu-selected";
        }
    }
    
	render()
	{
        const menu = require('../../model/menu.json');
        return(
            <div>
                <header>
                    <section className="bar-header container-fluid">
						<EURibbon literals={this.props.literals} lang={this.props.lang} />
                        <div className="header--top">
                            <div className={"eu-osha-logo "+this.props.lang}>
                                <Link to={"/"+this.props.lang} >
                                    <img src={require("../../style/img/esener-logo.png")} alt={this.props.literals.L502} />
                                </Link>
                                <a href={"https://osha.europa.eu/"+this.props.lang} role="link" title={this.props.literals.L363} tabIndex="0">
                                    <picture>
                                        <img src={`${logos[this.props.lang]}`} alt={this.props.literals.L502} />
                                    </picture>
                                </a>
                                <div className="logos-mobile">
                                    <Link to={"/"+this.props.lang} >
                                        <img src={require("../../style/img/esener-logo.png")} alt={this.props.literals.L502} />
                                    </Link>
                                    <a href={"https://osha.europa.eu/"+this.props.lang} role="link" title={this.props.literals.L363}>                                        
                                        <img src={require("../../style/img/eu-osha-landscape.png")} alt={this.props.literals.L502} />                                        
                                    </a>
                                </div>
                            </div>

                            <div className="nav--tools--menu">
                                {/* nav tools top */}
                                <nav className="menu-tools-top">
                                    <ul className="menu-tools-top--items list-inline">
                                        <li className="zoom--text">
                                            <span className="a_small"><a onClick={this.zoomSmall} title={this.props.literals.L365}>a</a></span>
                                            <span className="a_medium"><a onClick={this.zoomMedium} title={this.props.literals.L366}>a</a></span>
                                            <span className="a_big"><a onClick={this.zoomBig} title={this.props.literals.L367}>a</a></span>
                                        </li>
                                        <li className="print--block"><a title={this.props.literals.L364} onClick={this.printWindow}><i className="fa fa-print" aria-hidden="true"></i><label htmlFor="search" className="sr-only">{this.props.literals.L364}</label></a></li>
                                        <li className="lang--block">
                                            <LanguageSelect year={this.props.year} lang={this.props.lang}/>
                                        </li>
                                    </ul>
                                </nav>
                                {/* /. nav tools top */}
                            </div>
                            {/* main menu */}
                            <nav className="bar-main-menu navbar">
                                {/* Brand and toggle get grouped for better mobile display */}
                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#main-menu" aria-expanded="false">
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar bar1"></span>
                                        <span className="icon-bar bar2"></span>
                                        <span className="icon-bar bar3"></span>
                                    </button>
                                </div>

                                {/* Collect the nav links, forms, and other content for toggling */}
                                <div className="collapse navbar-collapse" id="main-menu">
                                    <ul className="nav navbar-nav">
                                        {
                                            menu.map((level0, index) => (
                                                <li key={index} className={"dropdown "+this.isCurrentSection(level0.id)}>
                                                    {
                                                        !!level0.levels ? (
                                                            <a className="dropdown-toggle" data-toggle="dropdown" role="button"
                                                            aria-haspopup="true" target="_self" aria-expanded="false" tabIndex={level0.tabindex}>
                                                                <span>{this.props.literals[level0.name]}</span>
                                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                                            </a>
                                                        ) : (
                                                            <NavLink to={"/"+this.props.lang+(level0.id === "home" ? "" : "/"+level0.sref)} 
                                                                className="dropdown-toggle" data-toggle="dropdown" role="button"
                                                                aria-haspopup="true" target="_self" aria-expanded="false" tabIndex={level0.tabindex} accessKey={level0.accesskey}>
                                                                <span>{this.props.literals[level0.name]}</span>
                                                            </NavLink>
                                                            
                                                        )
                                                    }
                                                    {
                                                        !!level0.levels ? (
                                                            <ul className="dropdown-menu">
                                                                <li>
                                                                    <span>{this.props.literals[level0.name]}</span>
                                                                </li>
                                                                {
                                                                    level0.levels.map((level1, index2) => (
                                                                        <li key={index2}>
                                                                            <NavLink to={"/"+this.props.lang+level1.sref+"/"+level1.indicator} accessKey={level1.accesskey} id={level1.id}>
                                                                                <span>{this.props.literals[level1.name]}</span>
                                                                            </NavLink>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        ) : ""
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </nav>
                            {/* <!-- /. main menu -->  */}
                        </div>
                    </section>
                    <Link to={"/"+this.props.lang} >
                        <section className="home-cover" title={this.props.literals.L385} >
                        <div className="container-fluid">
                            <h1 className="main-title" >
                                <img src={require("../../style/img/esener-survey.svg")} alt="ESENER" />
                                <span className="main-text sr-only">ESENER Survey</span><span className="lead-title">{this.props.literals.L101078}</span>
                            </h1>
                        </div>
                        </section>
                    </Link>

                    <Breadcrumb history={this.props.history} lang={this.props.lang} year={this.props.year} literals={this.props.literals}/>

                    <Submenu lang={this.props.lang} year={this.props.year} literals={this.props.literals} locale={this.props.locale} />

                </header>
            </div>
        )        
    }
}

export default Header;