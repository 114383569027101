import { combineReducers } from 'redux';
import literals from '../reducers/languageReducer';
import localeLiterals from '../reducers/localeReducer';
import localeValue from '../reducers/localeValueReducer';
import comparisonProps from './comparisonPropsReducer';

export default combineReducers({
    literals,
    localeLiterals,
    localeValue,
    comparisonProps
});