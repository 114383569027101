import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import $ from "jquery";
import { useCookies } from 'react-cookie';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { cookiesLabel } from '../../model/PageLabels';

const CookiesPage = props => 
{
	const { pushInstruction } = useMatomo();
    const [cookies, setCookie, removeCookie] = useCookies([]);
    const [textBox, setTextBox] = useState(props.literals.L101061);
    const [textCheck, setTextCheck] = useState(props.literals.L101062);
    const [checked, setChecked] = useState(true);

	useEffect(() => {
		// Update the title of the page
		document.title = cookiesLabel;

		let check = $('#box1 > div:nth-child(2) > input[type=checkbox]')[0];

		if(check != null){
            if(cookies.mtm_cookie_consent != undefined){
                setChecked(true);
                setTextBox(props.literals.L101061);
        	    setTextCheck(props.literals.L101062);
            }else{
                setChecked(false);
                setTextBox(props.literals.L101074);
                setTextCheck(props.literals.L101075);
            }
        }
	},[])

	function toogleMatomo (val) {
        if(val==true) {
            pushInstruction("rememberCookieConsentGiven",360);
            pushInstruction("setConsentGiven");
            pushInstruction("setCookieConsentGiven");

            if(cookies.mtm_consent_removed != undefined){
                removeCookie("mtm_consent_removed");
            }
        } else {
            pushInstruction("forgetConsentGiven");
            pushInstruction("forgetCookieConsentGiven");
        }
    }

	function checkCookies (event) {
        //Functionality to check if cookie exists or not
        if(event.target.checked){
            toogleMatomo(true);
            setChecked(!checked);
            setTextBox(props.literals.L101061);
        	setTextCheck(props.literals.L101062);
        }else{
            toogleMatomo(false);
            setChecked(!checked);
            setTextBox(props.literals.L101074);
            setTextCheck(props.literals.L101075);
        }
    }

	return(
		<div className="mainBody">
			<h1 className="title-section second-color ng-binding text-center">
				{props.literals.L390}
			</h1>
			<div className="container legal--notice--block">
				<div>{ReactHtmlParser(props.literals.L101059)}</div>

                <div><p>{props.literals.L101060}</p></div>

				<div className="box">
					<div id='box1'>
						<div>{ReactHtmlParser(textBox)}</div>
						<div>
							<input type='checkbox' onChange={checkCookies} checked={checked} /> <strong>{ReactHtmlParser(textCheck)}</strong>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
CookiesPage.displayName = 'CookiesPage';
export default CookiesPage;