import { SET_COMPARISON_PROPS } from '../constants';

export default (state = [], action) => {
    switch (action.type) {
       case SET_COMPARISON_PROPS:
        const { comparisonProps } = action;
        return comparisonProps;
       default:
          return state;
    }
}