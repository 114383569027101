import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

const UrlChanger = (props) => {
    const history = useHistory();

    useEffect(() => {
        setUrl();
    }, [props.filters]);

    const setUrl = () => {
        history.push({
            pathname: constructUrl()
        })
    }

    const constructUrl = () => {

        //TODO: Maybe transform to a full component that organizes the urls with props being passed down
        let newUrl = `/${props.lang}/survey/comparisons/${props.year}/${props.filters.topic}/${props.locale}/${props.filters.sectorSize}/${props.filters.question}/${props.filters.country}/${props.filters.answer}`

        return newUrl
    }

    return (
        null
    );
};

export default UrlChanger;