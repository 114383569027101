import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

import { getNationalBarChartData } from '../../../api';
import { nationalBarChartLabel } from '../../../model/PageLabels';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

class NationalBarChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            splits: [],
            auxSeries: [],
            chartConfig: {
                title: {
                    text: "",
                    align: 'left'
                },
                credits: {
                    enabled: false
                },
                colors: [],
                chart: {
                    type: "bar",
                    backgroundColor: '#FFF'
                },
                exporting: {
                    enabled: false,
                    buttons: {
                        contextButton: {
                            menuItems: ['viewFullscreen']
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                tooltip: {},
                plotOptions: {},
                series: []
            },
            chartConfigs: [],
            isLoading: false
        }
    }

    componentDidMount() {
        document.title = nationalBarChartLabel
        // console.log("National Bar Chart did mount");
        this.getLoadData(this.props.filters.question, this.props.filters.sectorSize, this.props.filters.country, this.props.filters.locale);
    }

    componentDidUpdate(prevProps) {
        // console.log("prevProps.filters",prevProps.filters,"this.props.filters",this.props.filters);
        if(prevProps.filters.question != this.props.filters.question || prevProps.filters.sectorSize != this.props.filters.sectorSize
            || prevProps.filters.country != this.props.filters.country || prevProps.filters.locale != this.props.filters.locale){
            this.getLoadData(this.props.filters.question, this.props.filters.sectorSize, this.props.filters.country,this.props.filters.locale);
        }
    }

    createColorPalette = () => {
        let colorArray = [];
        colorArray.push("#f6a400");
        colorArray.push("#cbe2e3");
        colorArray.push("#58585A");
        colorArray.push("#FFE300");
        colorArray.push("#449FA2");
        colorArray.push("#F3C564");
        colorArray.push("#16983E");

        return colorArray;
    }

    getLoadData = (question, sectorSize, country,locale) => {
        let categories = [];
        let auxSeries = [];
        let eachChartConfig = {};
        let chartsConfig = [];
        let seriesNames = [];
        let splits = [];

        let csvData = [];

        getNationalBarChartData(question, sectorSize, country, locale)
            .then((data) => {
                try{
                    this.setState({ ...this.state, isLoading: true });
                    // console.log("data", data);
                    data.resultset.forEach(element => {
                        // console.log("element", element);
                        if (categories.indexOf(element.split1) == -1) {
                            categories.push(element.split1);
                        }

                        let split2 = element.split2;
                        if (!(split2 in auxSeries)) {
                            splits.push(split2);
                            auxSeries[split2] = [];
                        }

                        // auxSeries[split2].push(element.value);

                        if(element.value != undefined){
                            auxSeries[split2].push(element.value);
                        }

                        csvData.push({ Subset: element.split2, Answer: element.split1, Percentage: element.value })
                    });
                    
                    if (this.props.filters.sectorSize == 'activity-sector') {
                        // When in activity sector - reverse the data so it appears correctly - also, in 2009 the data already comes correctly ordered
                        if ((this.props.year != '2009') ) {
                            splits.reverse();
                        }
                    }

                    categories.reverse();

                    // console.log("auxSeries",auxSeries);
                    // console.log("categories",categories);

                    let heightResp = 200;
                    let answerNumber = auxSeries[splits[0]].length;

                    if(answerNumber === 2){
                        heightResp = 140;
                    }else if(answerNumber === 3){
                        heightResp = 160;
                    }else if(answerNumber > 5){
                        heightResp = 250;
                    }
                    
                    this.props.csvDataCallback(csvData);

                    for (let serie in auxSeries) {
                        // console.log("auxSeries[serie].length",auxSeries[serie].length);
                        auxSeries[serie].reverse();
                        
                        if(auxSeries[serie].length>0){
                            eachChartConfig = {
                                chart: {
                                    type: 'bar',
                                    height: heightResp,
                                    // height: serie.length > 60 && window.innerWidth <= 768 ? (serie.length > 90 ? 280 : 200 ) : (serie.length > 40 ? 160 : 150 ),
                                    // height: auxSeries[serie].length === 2 ? 200 : 280,
                                    marginRight: 0
                                },
                                credits: {
                                    enabled: false
                                },
                                colors: this.createColorPalette(),
                                title: {
                                    text: ''
                                },
                                exporting: {
                                    enabled: false
                                },
                                xAxis: {
                                  labels: {
                                    style: {
                                      fontFamily: "OpenSans",
                                      fontWeight: "Bold",
                                      fontSize: "14px"
                                    }
                                  },
                                  categories: categories,
                                  lineWidth: 0
                                },
                                yAxis: {
                                    min: 0,
                                    gridLineWidth: 0,
                                    visible:false,
                                    max: 100
                                },
                                plotOptions: {
                                    series: {
                                        colorByPoint: true,
                                        // pointPadding: 0,
                                        pointWidth: 30,
                                        dataLabels: {
                                            enabled:true,
                                            crop:false,
                                            overflow: "allow",
                                            allowOverlap: true,
                                            style: {
                                                fontFamily: "OpenSans",
                                                fontWeight: "Bold",
                                                fontSize: "14px"
                                            },
                                            format: '{point.y} %'
                                            // formatter: function() {
                                            //     console.log("this",this);
                                            //     if (this.y) {
                                            //       return this.y;
                                            //     }
                                            // }
                                        }
                                    }
                                },
                                legend: {
                                    enabled: false
                                },
                                tooltip: {
                                    // headerFormat: '<b>{point.x}</b><br/><b>{series.name}</b><br/>',
                                    // pointFormat: '<b>{point.y}%</b> ',
                                    enabled:true,
                                    useHTML: true,
                                    opacity:0.9,
                                    backgroundColor: '#333333',
                                    borderColor: '#000000',
                                    zIndex: 100,
                                    style: {
                                        width: 450,
                                        color: 'white',
                                        fontFamily: 'OpenSans',
                                        fontWeight: 'normal',
                                        fontSize:'12px'
                                    },
                                    formatter: function () {
                                        return '<ul class="tooltip-item">'+
                                        '<li>'+ this.x +' </li>' +
                                        '<li><strong class="tooltip-value"> </strong> '+ this.y +'%</li>' +
                                        '</ul>';
                                    }
                                },
                                series: [{
                                    name: serie,
                                    data: auxSeries[serie]
                                }]
                            };
    
                            chartsConfig[serie] = {};
                            chartsConfig[serie] = eachChartConfig;
                        }
                    }                        

                    this.setState({
                        splits: splits,
                        auxSeries: auxSeries,
                        chartConfigs: chartsConfig
                    });

                    // console.log(this.state.chartConfigs[this.state.splits[1]]);
                    // console.log(this.state.splits);
                } catch (error) {
                    console.log('error fetching data', error)
                } finally {
                    this.setState({ ...this.state, isLoading: false })
                }
            })
    }

    render()
	{
		return(
			<div className="national-bar-chart-wrapper">
                <div className="xxs-w1 xs-w1 w1 center-text">
                    <div className="card--block--chart">
                        <div className="chart--block">
                            {
                                this.state.splits.map(serie => {
                                    if(this.state.chartConfigs[serie] != undefined){
                                        return (
                                            <div key={serie}>
                                                <h3 className="title--card">{serie}</h3>
                                                <HighchartsReact 
                                                    highcharts={Highcharts}
                                                    options={this.state.chartConfigs[serie]}
                                                    containerProps={{ className: 'chart--block' }}
                                                />
                                            </div>
                                            
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
			</div>
		)
	}
}

export default NationalBarChart;