import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

import { getPieChartData } from '../../../api';
import { pieChartLabel } from '../../../model/PageLabels';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

class PieChart extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            chartConfig: {
                title: {
                    text: "",
                    align: 'left'
                },
                credits: {
                    enabled: false
                },
                colors: [],
                chart: {
                    type: this.props.type,
                    backgroundColor: '#FFF'
                },
                exporting: {
                    enabled: false,
                    buttons: {
                        contextButton: {
                            menuItems: ['viewFullscreen']
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                tooltip: {},
                plotOptions: {},
                series: []
            },
            isLoading: false
        }
    }

    componentDidMount() {
        document.title = pieChartLabel
        this.getLoadData(this.props.filters.question, this.props.filters.country,this.props.filters.locale);
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps.filters.question != this.props.filters.question || prevProps.filters.country != this.props.filters.country
            || prevProps.filters.locale != this.props.filters.locale){
            console.log("Pie Chart componentDidUpdate");
            this.getLoadData(this.props.filters.question, this.props.filters.country,this.props.filters.locale);
        }
    }

    createColorPalette = (size) => {
        let colorArray = [];
        if(size === 1){
            colorArray.push("#f6a400");
        }else if(size === 2){
            colorArray.push("#f6a400");
            colorArray.push("#cbe2e3");
        }else if(size === 3){
            colorArray.push("#f6a400");
            colorArray.push("#cbe2e3");
            colorArray.push("#58585A");
        }else if(size === 4){
            colorArray.push("#f6a400");
            colorArray.push("#cbe2e3");
            colorArray.push("#58585A");
            colorArray.push("#FFE300");
        }else if(size === 5){
            colorArray.push("#f6a400");
            colorArray.push("#cbe2e3");
            colorArray.push("#58585A");
            colorArray.push("#FFE300");
            colorArray.push("#449FA2");
        }else if(size === 6){
            colorArray.push("#f6a400");
            colorArray.push("#cbe2e3");
            colorArray.push("#58585A");
            colorArray.push("#FFE300");
            colorArray.push("#449FA2");
            colorArray.push("#F3C564");
        }else if(size === 7){
            colorArray.push("#f6a400");
            colorArray.push("#cbe2e3");
            colorArray.push("#58585A");
            colorArray.push("#FFE300");
            colorArray.push("#449FA2");
            colorArray.push("#F3C564");
            colorArray.push("#16983E");
        }

        //colorArray.reverse();
        
        return colorArray;
    }

    // Init chart config so the Chart loads correctly
    initChart = () => {
        this.setState({ chartConfig: {
            title: {
                text: "",
                align: 'left'
            },
            credits: {
                enabled: false
            },
            // colors: ['#58585A','#cbe2e3','#f6a400'],
            colors: [],
            chart: {
                // width: 330,
                height: 500,
                type: "pie",
                backgroundColor: '#FFF'
            },
            exporting: {
                enabled: false,
                buttons: {
                    contextButton: {
                        menuItems: ['viewFullscreen', 'downloadPNG', 'downloadCSV']
                    }
                }
            },
            legend: {
                symbolRadius: 0,
                itemStyle: {
                    // color: '#000000',
                    font: "14px OpenSans",
                    fontWeight: 'normal'
                },
                itemDistance: 10,
                margin: 0
                //reversed: true
            },
            tooltip: {
                // headerFormat: '<b>{point.x}</b><br/><b>{series.name}</b><br/>',
                // pointFormat: '<b>{point.y}%</b> ',
                enabled:true,
                useHTML: true,
                opacity:0.9,
                backgroundColor: '#333333',
                borderColor: '#000000',
                zIndex: 100,
                style: {
                    width: 450,
                    color: 'white',
                    fontFamily: 'OpenSans',
                    fontWeight: 'normal',                    
                    fontSize:'12px'
                },
                formatter: function () {
                    return '<ul class="tooltip-item">'+
                    '<li>'+ this.point.name  +' </li>' +
                    '<li><strong class="tooltip-value"> </strong> '+ this.y +'%</li>' +
                    '</ul>';
                }
            },
            plotOptions: {
                series: {
                    states: {
                        hover: {
                            brightness: 0,
                            halo: {
                                size: 0,
                                attributes: {
                                    'stroke-width': 0 //to keep default setting
                                }
                            }
                        }
                    }
                },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: window.innerWidth > 600 ? '80%' : '60%',
                    dataLabels: {
                        distance: window.innerWidth > 600 ? 30 : 10,
                        enabled: true, 
                        style: {
                            font: "14px OpenSans",
                            fontSize: "14px"
                        },
                        alignTo: window.innerWidth > 600 ? undefined : "toPlotEdges",
                        crop:false,
                        overflow: "allow",
                        format: '{point.y:.1f} %'
                    },
                    showInLegend: {
                        enabled:true,
                        style:{
                            
                        }
                    }
                }
            },
            series: []
        } })
    }

    getLoadData = (question, country, locale) => {
        // console.log("Pie chart getLoadData");
        let categories = [];
        let series = [];
        let auxSeries = [];
        let euValue = null
        
        const csvData = [];

        this.initChart();
        getPieChartData(question, country, locale.toUpperCase())
        .then((data) => {
            try {
                this.setState({ ...this.state, isLoading: true });
                data.resultset.forEach(element => {
                    series.push({name: element.split1, y: element.value});
                    csvData.push({ answer: element.split1, value: element.value })
                });

                this.props.csvDataCallback(csvData);

                // console.log("categories",categories);
                // console.log("auxSeries",auxSeries);
                // console.log("series",series);
            } catch (error) {
                console.log('error fetching data', error)
            } finally {
                this.setState({ ...this.state, isLoading: false })
            }

            this.setState({
                chartConfig: {
                    ...this.state.chartConfig, 
                    colors: this.createColorPalette(series.length),
                    series: [{
                        name: "Values",
                        colorByPoint: true,
                        data: series
                    }]
                }
            });
        })
    }

    render()
	{
        // console.log("PIE CHART FILTER PROPS", this.props);
        // console.log("this.state",this.state);
		return(
			<div className="pie-chart">
                <div className="xxs-w1 xs-w1 w1 center-text">
                    <div className="card--block--chart">
                        {/* <div className="chart--block"> */}
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={this.state.chartConfig}
                                containerProps={{ className: 'chart--block', id: 'chartContainer' }}
                            />
                        {/* </div> */}
                    </div>
                </div>
			</div>
		)
	}

}

export default PieChart;