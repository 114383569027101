const homeLabel = 'European Survey of Enterprises on New and Emerging Risks (ESENER) results visualisation - Safety and health at work - EU - OSHA'
const overviewLabel = 'Overview | ESENER'
const dataVisualisationLabel = 'Data Visualisation | ESENER'
const comparisonLabel = 'Data Comparison | ESENER'
const methodologyLabel = 'Methodology | ESENER'
const siteMapLabel = 'Site map | ESENER'
const accessibilityLabel = 'Accessibility | ESENER'
const cookiesLabel = 'Cookies | ESENER'
const legalNoticeLabel = 'Legal notice | ESENER'
const europeanMapLabel = 'European map | ESENER'
const europeanBarChartLabel = 'European bar chart | ESENER'
const nationalBarChartLabel = 'National bar chart | ESENER'
const nationalComparisonLabel = 'National comparisons | ESENER'
const pieChartLabel = 'Pie chart | ESENER'

export { 
    homeLabel, 
    overviewLabel, 
    dataVisualisationLabel, 
    comparisonLabel, 
    methodologyLabel, 
    siteMapLabel, 
    accessibilityLabel, 
    cookiesLabel, 
    legalNoticeLabel, 
    europeanMapLabel,
    europeanBarChartLabel,
    nationalBarChartLabel,
    nationalComparisonLabel,
    pieChartLabel
}