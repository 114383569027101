import axios from 'axios';

// const BASEURL = process.env.BASE_URL;
const BASEURL = process.env.BASE_URL;

//Returns all data related to the question hierarchy for general detail page
export function getSelectorData(year, lang) {
    const URL = `${BASEURL}qualitative/getSelectorData`;
    const response = axios.get(URL, {
        params: {
            year: year,
            lang: lang
        },
        paramsSerializer: params => {
            let urlWithParams = new URLSearchParams()

            if(params.year) {
                urlWithParams.append('year', params.year);
            }

            if(params.lang) {
                urlWithParams.append('lang', params.lang);
            }

            return urlWithParams
        }
    })
    .then((res) => res.data);

    return response
}

//Returns all data related to the question hierarchy for comparisons page
export function getComparisonSelectorData(year, lang) {
    const URL = `${BASEURL}qualitative/getComparisonSelectorData`;
    const response = axios.get(URL, {
        params: {
            year: year,
            lang: lang
        },
        paramsSerializer: params => {
            let urlWithParams = new URLSearchParams()

            if(params.year) {
                urlWithParams.append('year', params.year);
            }

            if(params.lang) {
                urlWithParams.append('lang', params.lang);
            }

            return urlWithParams
        }
    })
    .then((res) => res.data);

    return response
}

//Returns all the data relative to the current question selected for general detail page
export function getCurrentQuestionData(question){
    const URL = `${BASEURL}qualitative/getQuestionData`
    const response = axios.get(URL, {
        params: {
            question: question
        },
        paramsSerializer: params => {
            let urlWithParams = new URLSearchParams()

            if(params.question) {
                urlWithParams.append('question', params.question);
            }

            return urlWithParams
        }
    })
    .then((res) => res.data);

    return response
}

//Returns all the data relative to the current question selected for comparisons page
export function getCurrentComparisonQuestionData(question){
    const URL = `${BASEURL}qualitative/getComparisonQuestionData`
    const response = axios.get(URL, {
        params: {
            question
        }
    })
    .then((res) => res.data);

    return response
}

//Returns all the data to fill in the selectors for general detail page
export function getQuestionFilters(question, lang){
    const URL = `${BASEURL}qualitative/getQuestionFilters`
    const response = axios.get(URL, {
        params: {
            question: question,
            lang: lang
        },
        paramsSerializer: params => {
            let urlWithParams = new URLSearchParams()

            if(params.question) {
                urlWithParams.append('question', params.question);
            }

            if(params.lang) {
                urlWithParams.append('lang', params.lang);
            }

            return urlWithParams
        }
    })
    .then((res) => res.data);

    return response
}

//Returns all the data to fill in the selectors for comparisons page
export function getComparisonQuestionFilters(question, lang){
    const URL = `${BASEURL}qualitative/getComparisonQuestionFilters`
    const response = axios.get(URL, {
        params: {
            question,
            lang
        }
    })
    .then((res) => res.data);

    return response
}

export function getEuropeanMapData(question, sectorSize, sectorSizeId, answer, euOnly, lang){
    // console.log(question, sectorSize, sectorSizeId, answer, euOnly, lang);
    const URL = `${BASEURL}quantitative/getChartData`;

    let option = "";
    if(sectorSize === "activity-sector"){
        option = "activitySector";
    }else{
        option = "companySize";
    }

    const response = axios.get(URL, {
        params: {
            question: question,
            sectorSize: sectorSize,
            sectorSizeId: sectorSizeId,
            answer: answer,
            lang: lang,
            euOnly: euOnly
        },
        paramsSerializer: params => {
            let urlWithParams = new URLSearchParams()

            if(params.question) {
                urlWithParams.append('question', params.question);
            }

            if(params.sectorSize) {
                urlWithParams.append('sectorSize', params.sectorSize);
            }

            if(params.sectorSizeId) {
                urlWithParams.append(option, params.sectorSizeId);
            }

            if(params.answer) {
                urlWithParams.append("answer", params.answer);
            }

            if(params.lang) {
                urlWithParams.append("lang", params.lang);
            }

            if(params.lang) {
                urlWithParams.append("EUOnly", params.euOnly);
            }

            return urlWithParams
        }
    })
    .then((res) => res.data);

    return response
}

export function getEuropeanBarChartData(question, sectorSize, sectorSizeId, euOnly, lang, sortBy){
    // console.log(question, sectorSize, sectorSizeId, lang);
    const URL = `${BASEURL}quantitative/getChartData`;

    let option = "";
    if(sectorSize === "activity-sector"){
        option = "activitySector";
    }else{
        option = "companySize";
    }

    const response = axios.get(URL, {
        params: {
            question: question,
            sectorSize: sectorSize,
            sectorSizeId: sectorSizeId,
            lang: lang,
            euOnly: euOnly,
            sortBy: sortBy
        },
        paramsSerializer: params => {
            let urlWithParams = new URLSearchParams()

            if(params.question) {
                urlWithParams.append('question', params.question);
            }

            if(params.sectorSize) {
                urlWithParams.append('sectorSize', params.sectorSize);
            }

            if(params.sectorSizeId) {
                urlWithParams.append(option, params.sectorSizeId);
            }

            if(params.lang) {
                urlWithParams.append("lang", params.lang);
            }

            if(params.lang) {
                urlWithParams.append("EUOnly", params.euOnly);
            }

            if(params.sortBy) {
                urlWithParams.append("answer", params.sortBy);
            }

            return urlWithParams
        }
    })
    .then((res) => res.data);

    return response
}

export function getNationalBarChartData(question, sectorSize, country, lang){
    const URL = `${BASEURL}quantitative/getChartData`;
    // console.log(question, sectorSize, country, lang);

    const response = axios.get(URL, {
        params: {
            question: question,
            sectorSize: sectorSize,
            country: country,
            lang: lang
        },
        paramsSerializer: params => {
            let urlWithParams = new URLSearchParams()

            if(params.question) {
                urlWithParams.append('question', params.question);
            }

            if(params.sectorSize) {
                urlWithParams.append('sectorSize', params.sectorSize);
            }

            if(params.country) {
                urlWithParams.append("country", params.country);
            }

            if(params.lang) {
                urlWithParams.append("lang", params.lang);
            }

            return urlWithParams
        }
    })
    .then((res) => res.data);

    return response
}

export function getNationalComparisonsChartData(question, sectorSize, sectorSizeId, country1, country2, lang){
    const URL = `${BASEURL}quantitative/getChartData`;

    let option = "";
    if(sectorSize === "activity-sector"){
        option = "activitySector";
    }else{
        option = "companySize";
    }

    const response = axios.get(URL, {
        params: {
            question: question,
            sectorSize: sectorSize,
            sectorSizeId: sectorSizeId, 
            country1: country1,
            country2: country2,
            lang: lang
        },
        paramsSerializer: params => {
            let urlWithParams = new URLSearchParams()

            if(params.question) {
                urlWithParams.append('question', params.question);
            }

            if(params.sectorSize) {
                urlWithParams.append('sectorSize', params.sectorSize);
            }

            if(params.sectorSizeId) {
                urlWithParams.append(option, params.sectorSizeId);
            }

            if(params.country1) {
                urlWithParams.append("country1", params.country1);
            }

            if(params.country2) {
                urlWithParams.append("country2", params.country2);
            }

            if(params.lang) {
                urlWithParams.append("lang", params.lang);
            }

            return urlWithParams
        }
    })
    .then((res) => res.data);

    return response
}

export function getComparisonChartData(question, sectorSize, lang, country, answer) {
    const URL = `${BASEURL}quantitative/getComparisonChartData`;
    const response = axios.get(URL, {
        params: {
            question,
            sectorSize,
            lang,
            country,
            answer
        }
    })
    .then((res) => res.data);

    return response
}

export function getPieChartData(question, country, lang){
    const URL = `${BASEURL}quantitative/getChartData`;
    const response = axios.get(URL, {
        params: {
            question: question,
            country: country,
            lang: lang
        },
        paramsSerializer: params => {
            let urlWithParams = new URLSearchParams()

            if(params.question) {
                urlWithParams.append('question', params.question);
            }

            if(params.country) {
                urlWithParams.append("country", params.country);
            }

            if(params.lang) {
                urlWithParams.append("lang", params.lang);
            }

            return urlWithParams
        }
    })
    .then((res) => res.data);

    return response
}

export function getComparisonQuestionID(question)
{
    const URL = `${BASEURL}qualitative/getComparisonQuestionID`;
    const response = axios.get(URL, {
        params: {
            question: question
        },
        paramsSerializer: params => {
            let urlWithParams = new URLSearchParams()

            if(params.question) {
                urlWithParams.append('question', params.question);
            }

            return urlWithParams
        }
    })
    .then((res) => res.data);

    return response
}