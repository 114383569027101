import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactHtmlParser from 'react-html-parser'
import { getComparisonQuestionFilters } from '../../api';

import LocaleSelect from '../common/LocaleSelect';

class ComparisonSelector extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            localLanguage: props.locale,
            selectCountries: [],
            selectActivitySectors: [],
            questionFilters: {
                answers: [],
                countries: [],
            },
            breadcrumb: "",
            langSize: [
                ["bg"],
                ["hr", "nl_1", "cy", "cz","is","nl","pt"],
                ["al", "fr_1","hr","da","el_1","al_1","mk","hu","et","ru_1",
                "sv_1","fr","el","it","lv","ru_2","fr_2","mt","no","pl","ro","sk","sl","es","sv","fr_3","it_1","tr"]
            ],
            isLoading: false,
            isToggleOpen: false,
            dataForRef: React.createRef(),
            dataForYears: this.props.dataforYears,
            disabledActivity: false,
            disabledCountry: false
        }
    }

    getComparisonQuestionFilters() {
        this.setState({ ...this.state, isLoading: true })
        try {
            getComparisonQuestionFilters(this.props.filters.question, this.props.lang)
            // getComparisonQuestionFilters(this.props.question, this.props.lang)
                .then((res) => {
                    this.setState({ questionFilters: res.resultset })
                    this.rulesForSelects(res.resultset);
                })
        } catch(error) {
            console.log('Error on question filters', error)
        } finally {
            this.setState({ ...this.state, isLoading: false })
        }
    }

    rulesForSelects = (generalDataList) => {
        console.log("Comparisons rulesForSelects");
        let firstCountryList = [];
        let activitySectorList = [];
        let currentCountry = null;
        let currentActivity = null;

        if (['E3Q260_1', 'E3Q260_2', 'E3Q260_3', 'E3Q260_4'].indexOf(this.props.filters.question) > -1) {
            if (this.props.filters.sectorSize == 'activity-sector') {
                this.setState({ disabledCountry: true })
            } else {
                this.setState({ disabledCountry: false })
                if (this.props.filters.country == 'EU27_2020') {
                    this.setState({ disabledActivity: false })
                } else {
                    this.setState({ disabledActivity: true })
                }
            }
        } else {
            this.setState({ disabledActivity: false, disabledCountry: false })
        }
        
        for (let i = 0; i<generalDataList.countries.length; i++) {
            currentCountry = generalDataList.countries[i];

            switch (this.props.filters.question) {
                default:
                    if (this.props.year == "2014") {
                        firstCountryList.push(currentCountry);
                        // 2014 - company size, 50 -249 not all countries in the select
                        // if (this.props.filters.establishmentSizes == 14) {
                        //     if (this.props.filters.question.match('Q261') != undefined ) {
                        //         if(currentCountry.code != 'AL' && currentCountry.code != 'ME' && currentCountry.code != 'BG' 
                        //             && currentCountry.code != 'DK' && currentCountry.code != 'EE' && currentCountry.code != 'ES' 
                        //             && currentCountry.code != 'HR' && currentCountry.code != 'IT' && currentCountry.code != 'LV' 
                        //             && currentCountry.code != 'RO' && currentCountry.code != 'SE' && currentCountry.code != 'SI' 
                        //             && currentCountry.code != 'UK' && this.props.filters.sectorSize == 'company-size'){
                        //             if(currentCountry.code != this.props.filters.country){
                        //                 firstCountryList.push(currentCountry);
                        //             }
                        //         }
                        //     }
                        // } else if (this.props.activitySectors == 6) {
                        //     if(this.props.filters.question === ('Q251') || (this.props.filters.question.match('Q252') != undefined && this.props.filters.question != 'Q252_2')
                        //         || this.props.filters.question == 'Q254gr' || this.props.filters.question == 'Q255' || this.props.filters.question.match('Q256') != undefined
                        //         || this.props.filters.question == 'Q258b' || this.props.filters.question == 'Q259') {
                        //             if(currentCountry.code != 'AL' && currentCountry.code != 'IS' && currentCountry.code != 'ME'
                        //                 && currentCountry.code != 'MK' && currentCountry.code != 'CY' && currentCountry.code != 'EL' 
                        //                 && currentCountry.code != 'HR' && currentCountry.code != 'LT' && currentCountry.code != 'LU' 
                        //                 && currentCountry.code != 'MT' ) {
                        //                     if(currentCountry.code != this.props.filters.country){
                        //                         firstCountryList.push(currentCountry);
                        //                     }
                        //                 }
                        //         } 
                        //         else {
                        //             firstCountryList.push(currentCountry);
                        //             // if(currentCountry.code != this.props.filters.country){
                        //             // }
                        //         }
                        // } 
                    } 
                    if (this.props.year == '2019') {
                        // if (currentCountry.code != this.props.filters.country) {
                            firstCountryList.push(currentCountry);
                        // }
                    }
                    break;
            }
        }

        for (let i = 0; i < generalDataList.activitySectors.length; i++) {
            currentActivity = generalDataList.activitySectors[i];

            switch (true) {
                case this.props.filters.question.match('Q202') != undefined:
                    if((this.props.filters.question == 'Q202_2' || this.props.filters.question == 'Q202_3' || this.props.filters.question == 'Q202_5'
                        || this.props.filters.question == 'Q202_6' || this.props.filters.question == 'Q202_8' || this.props.filters.question == 'Q202_9'
                        || this.props.filters.question == 'Q202_11' || this.props.filters.question == 'Q202_12' || this.props.filters.question == 'Q202_13'
                        || this.props.filters.question == 'Q202_15') ){
                        if(elem[0] != 6){
                            activitySectorList.push(currentActivity);
                        }
                    }else{
                        activitySectorList.push(currentActivity);
                    }
                    break;
                    case (this.props.filters.question.match('Q251') != undefined || this.props.filters.question.match('Q252') != undefined
                    || this.props.filters.question == 'Q254gr' || this.props.filters.question == 'Q255' || this.props.filters.question.match('Q256') != undefined
                    || this.props.filters.question == 'Q258b' || this.props.filters.question == 'Q259' || this.props.filters.question == 'Q308_1'
                    || this.props.filters.question == 'Q354'):
                    if(this.props.chart == 'national-comparisons'){
                        if(this.props.filters.question != 'Q252_2' && this.props.filters.question != 'Q308_1' && this.props.filters.question != 'Q354'){
                            if((this.props.filters.country != 'AL' && this.props.filters.country2 != 'AL' && this.props.filters.country != 'IS' 
                            && this.props.filters.country2 != 'IS' && this.props.filters.country != 'ME' && this.props.filters.country2 != 'ME' 
                            && this.props.filters.country != 'MK' && this.props.filters.country2 != 'MK' && this.props.filters.country != 'CY' 
                            && this.props.filters.country2 != 'CY' && this.props.filters.country != 'EL' && this.props.filters.country2 != 'EL' 
                            && this.props.filters.country != 'HR' && this.props.filters.country2 != 'HR' && this.props.filters.country != 'LT' 
                            && this.props.filters.country2 != 'LT' && this.props.filters.country != 'LU' && this.props.filters.country2 != 'LU' 
                            && this.props.filters.country != 'MT' && this.props.filters.country2 != 'MT')){
                                activitySectorList.push(currentActivity);
                            }else{
                                if(currentActivity.id != 6){
                                    activitySectorList.push(currentActivity);
                                }
                            }
                        }
                    }else{
                        if(currentActivity.id != 6){
                            activitySectorList.push(currentActivity);
                        }
                    }
                    break;
                default:
                    activitySectorList.push(currentActivity);
                    break;
            }
        }

        this.setState({
            selectCountries: firstCountryList,
            selectActivitySectors: activitySectorList
        })
    }

    setQuestionBreadcrumb(){
        // console.log("setQuestionBreadcrumb",this.props.questionProperties);
        let breadcrumb = "";
        if (this.props.questionProperties != null)
        {
            if (this.props.questionProperties.level === 2)
            {
                if (this.props.questionProperties.name2LiteralID === 0 || this.props.questionProperties.name2LiteralID === undefined)
                {
                    breadcrumb = '<span class="level1-bread">' + this.props.localeLiterals["L"+this.props.questionProperties.fatherLiteralID] + '</span>' 
                        + " / " + '<span class="level2-bread">' + this.props.localeLiterals["L"+this.props.questionProperties.name3LiteralID] + "</span>";	
                }
                else
                {
                    breadcrumb = '<span class="level1-bread">' + this.props.localeLiterals["L"+this.props.questionProperties.fatherLiteralID] + '</span>' 
                        + " / " + '<span class="level2-bread">' + this.props.localeLiterals["L"+this.props.questionProperties.name2LiteralID] + "</span>";	
                }							
            }
            else if (this.props.questionProperties.level === 3)
            {
                breadcrumb = '<span class="level1-bread">' + this.props.localeLiterals["L"+this.props.questionProperties.grandfatherLiteralID] + '</span>' 
                    + " / " + '<span class="level2-bread">' + this.props.localeLiterals["L"+this.props.questionProperties.fatherLiteralID] + ": " 
                    + this.props.localeLiterals["L"+this.props.questionProperties.name2LiteralID]  + "</span>";
            }
        }
        this.setState({ breadcrumb });
    }

    //Function called to load the previous or next question of the actual one
    changeQuestion = (id, navType) => () => {
        // console.log("comparisonSelector.js changeQuestion function");
        let filters = {...this.props.filters};
        let question = id;
        if(question === null){
            // if (this.props.year === "2014") {
            //     if(navType === 'n'){
            //         filters.topic = "osh-management";
            //         filters.question = 'Q150_1';
            //     }else if(navType === 'p'){
            //         filters.topic = "worker-participation";
            //         filters.question = 'Q358';
            //     }
            // } else {
                if(navType === 'n'){
                    filters.topic = "osh-management";
                    filters.question = 'E3Q151_1';
                }else if(navType === 'p'){
                    filters.topic = "worker-participation";
                    // filters.question = 'E3Q357';
                    filters.question = 'E3Q354';
                }
            // }
        }
        
        if(navType === 'n'){
                if(question === "MM202_1" || question === "E3Q201_1" || question === "Q201_1"){
                    filters.topic = "emerging-risks-and-their-management";
                }else if(question === "MM171_1" || question === "Q264_1" || question === "E3Q262_1"){
                    filters.topic = "drivers-and-barriers";
                }
                else if(question === "MM350" || question === "Q166_1" || question === "E3Q258"){
                    filters.topic = "worker-participation";
                }
        } else {
            if(question === "MM200_7" || question === "Q356_5" || question === "E3Q355_6"){
                filters.topic = "osh-management";
            }else if(question === "MM260" || question === "Q307" || question === "E3Q312_8"){
                filters.topic = "emerging-risks-and-their-management";
            }
            else if(question === "MM303a" || question === "Q166_1" || question === "E3Q308_4"){
                filters.topic = "drivers-and-barriers";
            }
        }

        /*if(topicId === 1){
			return "osh-management";
		}else if(topicId === 5){
			return "emerging-risks-and-their-management";
		}else if(topicId === 8){
			return "drivers-and-barriers";
		}else if(topicId === 11){
			return "worker-participation";
		} */

        //Call data load again with new question
        //Check if new question has exceptions
        //If not change url 
        var exception = this.props.rules(question != undefined ? question : filters.question );

        if(!exception){
            // filters.question = question;
            if(question != null){
                filters.question = question;
            }
            this.props.callback(filters);
        }
    }

    onClickDataforDropdown = () => {
        this.setState({ isToggleOpen: !this.state.isToggleOpen })
    }
    
    updateChart = (filterChanged) => (e) => {
        e.preventDefault();

        let filters = {...this.props.filters}

        switch (filterChanged) {
            case 'country':
                console.log('country',e.target.value);
                filters.country = e.target.value
                break;
            
            case 'answer':
                console.log('answer', e.target.value);
                filters.answer = e.target.value
                break;

            case 'activityToCompany':
                console.log(filterChanged);
                filters.sectorSize = "company-size"
                break;
        
            case 'companyToActivity':
                console.log(filterChanged);
                filters.sectorSize = "activity-sector";
            default:
                console.log('no changes detected');
        }

        this.props.callback(filters)
    }

    //Function to slice question name in case it is too long
    trim = (text,type) => {
        var trimText = '';
            if(this.props.questionProperties != null){
                if(this.props.questionProperties.nextCategory === null && type === 'n'){
                    text = this.props.localeLiterals.L496; //'An occupational health doctor'
                }

                if(this.props.questionProperties.previousCategory === null && type === 'p'){
                    text = this.props.localeLiterals.L556; //'Is there a health and safety committee in your establishment?';
                }
            }
            
            if(text != null){
                
                if(text.length > 10){
                    trimText = text.substring(0,10) + '...';
                    return trimText;
                }else{
                    return text;
                }
            }
    }

    //Function to create title of previous and next question
    isNull = (questionName, type) => {
        var text = this.props.localeLiterals["L"+questionName];
        
        if(this.props.questionProperties != null){
            if(this.props.questionProperties.previousLevel != 1 && type === 'p'){
                if(this.props.questionProperties.previousLiteralID === null){
                    text = this.props.localeLiterals.L556; //Is there a health and safety committee in your establishment?
                }else{
                    // return this.props.literals['L'+this.props.questionProperties.previousFatherLiteralID] + ': ' + text;
                    text = this.props.localeLiterals['L'+this.props.questionProperties.previousFatherLiteralID] + ': ' + text;
                }
            }else if(this.props.questionProperties.nextLevel != 1 && type === 'n'){
                if(this.props.questionProperties.nextLiteralID === null && type === 'n'){
                    text = this.props.localeLiterals.L100003 + ': ' + this.props.localeLiterals.L496; //Use of health and safety services: An occupational health doctor
                }else{
                    // return this.props.literals['L'+this.props.questionProperties.nextFatherLiteralID] + ': ' + text;
                    text = this.props.localeLiterals['L'+this.props.questionProperties.nextFatherLiteralID] + ': ' + text;
                }
            }
        }
        
        return text;
    }

    componentDidMount() {
        this.getComparisonQuestionFilters();
        this.setQuestionBreadcrumb();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.questionProperties != this.props.questionProperties) {
            this.setQuestionBreadcrumb();
        }

        if (prevProps.filters.question != this.props.filters.question) {
            this.getComparisonQuestionFilters();
        }
        
        if (prevProps.filters.sectorSize != this.props.filters.sectorSize) {
            this.getComparisonQuestionFilters();
        }
        
        if (prevProps.filters.country != this.props.filters.country) {
            this.getComparisonQuestionFilters();
        }

        if (prevState.selectedCountry != this.state.selectedCountry) {
            this.getComparisonQuestionFilters();
        }
    }

    changeLocale = () => {
        console.log('change local button')
    }

    onClickSelectedTag = (selectedYear) => {
        return () => {
            console.log('click on selected year: ', selectedYear)
        }
    }

    render() {

        /**
         * @description
         * Buttons for activity sector, company size, country select and answer
         */
        let nationalBarChartSelectFilters = "";

        return (
            <div className="selector--page--elements">

                <div className="content--intro--wrapper" >
                    {/* <!-- DATAFOR Selector --> */}
                    <div className={`datafor--dropdown--wrapper ${this.state.isToggleOpen ? 'viewOptions' : ''}`} >
                        <div className="datafor--dropdown--list" ref={this.state.dataForRef} >
                            <p className="option-title" onClick={this.onClickDataforDropdown} >{this.props.localeLiterals.L101086}</p>
                            <ul className="datafor--dropdown--options" >
                                {this.state.dataForYears.map((element, index) => (
                                    <li key={`${element}-${index}`} >
                                        <input type="checkbox" checked disabled />
                                        <label value={element} >{element}</label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {this.state.dataForYears && (
                        <p className="selected--tags-wrapper"  >
                            {this.state.dataForYears.map((year) => (
                                <span className="selected-tag" key={year} onClick={this.onClickSelectedTag(year)} >{year}</span>
                            ))}
                        </p>
                    )}
                    <p>{ReactHtmlParser(this.state.breadcrumb)}</p>
                    <ul className="question--by--question" >
                        <li>
                            <a onClick={this.changeQuestion(this.props.questionProperties.previousCategory, 'p')} 
                                title={this.isNull(this.props.questionProperties.previousLiteralID, 'p')} >
                                    {this.trim(this.props.localeLiterals[`L${this.props.questionProperties.previousLiteralID}`], 'p')}
                                </a>
                        </li>
                        <li>
                            <a onClick={this.changeQuestion(this.props.questionProperties.nextCategory, 'n')} 
                                title={this.isNull(this.props.questionProperties.nextLiteralID, 'n')}>
                                {this.trim(this.props.localeLiterals[`L${this.props.questionProperties.nextLiteralID}`], 'n')}
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="content--lang--wrapper" >
                    <div className="national-version-select" >
                        <form action="" className="compare--block--form">
                            <label htmlFor="searchFilter" >{this.props.localeLiterals.L101032}</label>
                            <LocaleSelect year={this.props.year} locale={this.props.locale} comparison={true} />
                        </form>
                    </div>
                </div>

                {/* <!-- Chart section and indicator selection --> */}
                <div className="content--intro chart--wrapper">
                    {/* <!-- Question title --> */}
                    <h2 className="title-question main-color">{this.props.localeLiterals["L"+this.props.questionProperties.name3LiteralID]}</h2>
                    <p  className="description--question">{ReactHtmlParser(this.props.localeLiterals["L"+this.props.questionProperties.bottomTextLiteralID])}</p>
                </div>
                {
                    // BUTTONS FOR ACTIVITY SECTOR, COMPANY SIZE AND COUNTRY SELECT
                    nationalBarChartSelectFilters = (
                    <div className="content--filter--wrapper" >
                        <form className={`block--filter--wrapper ${(this.state.langSize[0].indexOf(this.state.localLanguage) != -1 ) ? this.state.localeLanguage : ""}`}>
                            <p className="tags">
                                <span>{this.props.localeLiterals.L101026}</span>
                            </p>
                            {/* <!-- Activity sector --> */}
                            <div className={`filter-text-button ${this.state.disabledActivity ? 'exception' : ''}`}>
                                <button className={`${(this.props.filters.sectorSize === 'activity-sector') ? "active" : ""}`} disabled={this.state.disabledActivity} onClick={this.updateChart('companyToActivity')} 
                                    // ui-sref="detailpage-national-bar-chart({ pTopic: topic, pChart: 'national-bar-chart', pSectorSize: 'activity-sector', pCountry: filters.country, pQuestion: indicator, pLanguage: pLanguage, pLocale: pLocale, pAnswer: answer})"
                                >{this.props.localeLiterals.L100573}</button>
                            </div>
                            <p className="tags">
                                <span>{this.props.localeLiterals.L101027}</span>
                            </p>       	
                            {/* <!-- Establishment size --> */}
                            <div className="filter-text-button">
                                <button className={`${(this.props.filters.sectorSize === 'company-size') ? 'active' : ''}`} onClick={this.updateChart('activityToCompany')} 
                                    // ui-sref="detailpage-national-bar-chart({ pTopic: topic, pChart: 'national-bar-chart', pSectorSize: 'company-size', pCountry: filters.country, pQuestion: indicator, pLanguage: pLanguage, pLocale: pLocale, pAnswer: answer})"
                                >{this.props.localeLiterals.L100574}</button>
                            </div>
                            {/* <!-- Country --> */}
                            <div className="filter-text-button country-select">
                                <select id="countrySelect" disabled={this.state.disabledCountry} onChange={this.updateChart('country')} 
                                value={this.props.filters.country}>
                                        {this.state.selectCountries.map((country) => (
                                            <option key={country.code} value={country.code}>{(country.code != 'EU28' && country.code != 'EU27_2020') ? `(${country.code}) ${this.props.localeLiterals["L"+country.literalID]}` : `${country.code}` }</option>
                                        ))}
                                </select>
                            </div>
                            </form>
                            <hr />
                            <div className="filters--and--typechart comparisons-filter" >
                                <form className={`block--typechart--wrapper ${(this.state.langSize[0].indexOf(this.state.localLanguage) != -1 ) ? this.state.localeLanguage : ""}`}>
                                    {/* <!-- Answer --> */}
                                    <div className="filter-text sort-by">
                                        <span> {this.props.localeLiterals.L100588}: </span>
                                        <select onChange={this.updateChart('answer')} value={this.state.questionFilters.id}>
                                            {this.state.questionFilters.answers.map((answer) => (
                                                <option key={answer.id} value={answer.id} >{this.props.localeLiterals[`L${answer.literalID}`]}</option>
                                            ))}
                                        </select>
                                    </div>
                                </form>
                            </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) 
{
    const {literals} = state;
    const {localeLiterals} = state;
    return {
        literals,
        localeLiterals
    }
}
export default connect(mapStateToProps)(ComparisonSelector);