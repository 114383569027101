import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EuropeanMap from '../common/charts/EuropeanMap';
import EuropeanBarChart from '../common/charts/EuropeanBarChart';
import NationalComparisonsChart from '../common/charts/NationalComparisonsChart';
import NationalBarChart from '../common/charts/NationalBarChart';
import PieChart from '../common/charts/PieChart';
import QuestionList from '../common/QuestionList';
import Selector from '../common/Selector';
import Export from '../common/Export';
import { getCurrentQuestionData } from '../../api';
import { setComparisonProps } from '../../actions';

const DetailPage = props => 
{
	const history = useHistory();
	const { country } = useSelector((state) => state.comparisonProps)
	
	const [chart, setChart] = useState(props.chart);
	const [loaded, setLoaded] = useState(false);
	const [csvChartName, setCsvChartName] = useState('');
	const [questionObject, setQuestionObject] = useState({
		answerID: "", //answer_id
		bottomTextLiteralID: "", //bottomText
		fatherLiteralID: "", //father
		grandfatherLiteralID: "", //grandfather
		level: "", //level
		name2LiteralID: "", //name2
		name3LiteralID: "", //name3
		nextCategory: "", //nextID
		nextFatherID: "", //nextFatherID
		nextFatherLiteralID: "", //nextFatherName
		nextLevel: "", //nextLevel
		nextLiteralID: "", //nextName
		previousCategory: "", //previousID
		previousFatherID: "", //previousFatherID
		previousFatherLiteralID: "", //previousFatherName
		previousLevel: "", //previousLevel
		previousLiteralID: "" //previousName
	});
	const [filters, setFilters] = useState({
		question: props.question,
		topic: props.topic,
		activitySector: props.activity === undefined ? "14" : props.activity,
		establishmentSize: props.company === undefined ? "11" : props.company,
		country: props.country,
		country2: props.country2,
		answer: props.answer === undefined ? "1" : props.answer,
		euOnly: 0,
		sectorSize: props.sectorSize === undefined ? "activity-sector" : props.sectorSize,
		locale: props.locale,
		sortBy: "0"
	});
	const [csvData, setCsvData] = useState(null);
	const questRef = useRef();

	/*console.log('Props.comparisonProps', props.comparisonProps);
	console.log('Props', props);*/
	if (props.comparisonProps == undefined || props.comparisonProps == '' || props.comparisonProps.question != props.question)
	{
		props.setComparisonProps({ question: props.question, country: props.country });
	}
	
	//ComponentDidMount/ComponentDidUpdate
	useEffect(() => {
		// console.log("useEffect", filters, questionObject);
		getQuestionData();

		// console.log("questRef.current",questRef.current,filters.question, filters.answer, questionObject);
		if(filters.question != questRef.current && questRef.current != undefined){
			// console.log("USE EFFECT TRIGGERED");
			if((filters.answer != questionObject.answerID && questionObject.answerID != "")){
			// if(chart === "european-map" && (filters.answer != questionObject.answerID && questionObject.answerID != "")){
				setFilters(prevFilters => ({
					...prevFilters,
					answer: questionObject.answerID
				}));
			}
		}
		setUrl();
	},[filters, questionObject.name3LiteralID]);

	useEffect(() => {
		if(loaded && questionObject.answerID != ""){
			if ((country == 'EU27_2020') || (country == 'EU28')) {
				setFilters({
					...filters,
					country2: 'AT'
				})
			} else {
				setFilters({
					...filters,
					country2:props.year == '2019' ? 'EU27_2020' : 'EU28',
					answer: questionObject.answerID
				})
			}
		}
	}, [loaded])

	// if answer changes, reset sortBy filter to 0
	useEffect(() => {
		setFilters({
			...filters,
			sortBy: '0'
		})
	}, [questionObject.name3LiteralID])

	useEffect(() => {
		if (props.year == '2014') {
			if (props.chart == 'european-map') {
				setCsvChartName('csv_heatMap-esener2')
			} else if (props.chart == 'european-bar-chart') {
				setCsvChartName('csv_euBars-esener2')
			} else if (props.chart == 'national-bar-chart') {
				setCsvChartName('csv_inCountry-esener2')
			} else if (props.chart == 'national-comparisons') {
				setCsvChartName('csv_crossCountry-esener2')
			} else if (props.chart == 'pie-chart') {
				setCsvChartName('csv_pieChart-esener2')
			}
		} else {
			if (props.chart == 'european-map') {
				setCsvChartName('csv_heatMap')
			} else if (props.chart == 'european-bar-chart') {
				setCsvChartName('csv_euBars')
			} else if (props.chart == 'national-bar-chart') {
				setCsvChartName('csv_inCountry')
			} else if (props.chart == 'national-comparisons') {
				setCsvChartName('csv_crossCountry')
			} else if (props.chart == 'pie-chart') {
				setCsvChartName('csv_pieChart')
			}
		}

	}, [props.chart])

	useEffect(() => {
		if (filters.locale != props.locale)
		{
			setFilters(prevFilters => ({
				...prevFilters,
				locale: props.locale
			}));
		}
	},[props.locale]);

	//Function that calls the webservice to retrieve question data
	function getQuestionData(){
        try {
			getCurrentQuestionData(filters.question)
				.then((res) => {
                    // this.setState({questionObject: res.resultset});
					setQuestionObject(res.resultset);
					setLoaded(true);
					questRef.current = props.question;
				})
		} catch (error) {
			console.log('Error fetching selector data:', error)
		}
    }

	const changeToQuestion = (questionSelected, topicSelected) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			euOnly: 0
		}))
		// console.log("Questionlist callback",topicSelected);
		// this.setState({ filters: {...filters, question: questionSelected, topic: topicSelected} });
		if (topicSelected == null) {
			setFilters((prevFilters) => ({
				...prevFilters,
				question: questionSelected.value
			}))
		}
		
		if (questionSelected && topicSelected) {
			setFilters(prevFilters => ({
				...prevFilters,
				question: questionSelected.value != undefined ? questionSelected.value : questionSelected, 
				topic: topicSelected
			}));
		}

		props.setComparisonProps({ ...props.comparisonProps, question: questionSelected.value != undefined ? questionSelected.value : questionSelected });

		// setUrl();
	}

	const changeToNewFilters = (newFilters, newChart) => {
		// console.log("changeToNewFilters callback",newChart,newFilters);
		// this.setState({ filters: newFilters, chart: newChart });

		// Check if the country has changed
		if (props.country != newFilters.country)
		{
			// Update the country that will be selected in the Comparisons page
			props.setComparisonProps({ ...props.comparisonProps, country: newFilters.country });
		}

		setFilters(newFilters);

		if(chart != newChart){
			setChart(newChart);
		}
	}

	const rulesForTooltip = (question) => {
		console.log("Arrives to rulesForTooltip in DetailPage.js");
		//RULES ESENER 2009
		if(props.year === "2009"){
			if(question === "MM303a"){
				if(props.chart === 'national-comparisons' || props.chart === 'national-bar-chart'){
					if(props.country === "CY" || props.country === "EE" || props.country === "MT"){
						alert(props.literals.L100576);
						return true;
					}
				}

				if(props.chart === 'national-comparisons'){
					if(props.country2 === "CY" || props.country2 === "EE" || props.country2 === "MT"){
						alert(props.literals.L100576);
						return true;
					}
				}
			}else if(question === "MM350"){
				if(props.chart === 'national-comparisons' || props.chart === 'national-bar-chart' || props.chart === 'pie-chart'){
					if(props.country === "CY" || props.country === "MT" || props.country === "SE"){
						alert(props.literals.L100576);
						return true;
					}
				}

				if(props.chart === 'national-comparisons'){
					if(props.country2 === "CY" || props.country2 === "MT" || props.country2 === "SE"){
						alert(props.literals.L100576);
						return true;
					}
				}
			}else if(question === "MM351"){
				if(props.chart === 'national-comparisons' || props.chart === 'national-bar-chart' /*|| props.chart === "pie-chart"*/){
					if(props.country === "AT" || props.country === "DE" || props.country === "LU"){
						alert(props.literals.L100576);
						return true;
					}
				}

				if(props.chart === 'national-comparisons'){
					if(props.country2 === "AT" || props.country2 === "DE" || props.country2 === "LU"){
						alert(props.literals.L100576);
						return true;
					}
				}
			}else if(question === "MM355all"){
				if(props.chart === 'national-comparisons' || props.chart === 'national-bar-chart' || props.chart === 'pie-chart'){
					if(props.country === "CH"){
						alert(props.literals.L100576);
						return true;
					}
				}

				if(props.chart === 'national-comparisons'){
					if(props.country2 === "CH"){
						alert(props.literals.L100576);
						return true;
					}
				}
			}else if(question === "MM358"){
				if(props.chart === 'national-comparisons' || props.chart === 'national-bar-chart' || props.chart === 'pie-chart'){
					if(props.country === "LU" || props.country === "SI"){
						alert(props.literals.L100576);
						return true;
					}
				}

				if(props.chart === 'national-comparisons'){
					if(props.country2 === "LU" || props.country2 === "SI"){
						alert(props.literals.L100576);
						return true;
					}
				}
			}
			
			// if(props.chart === 'national-comparisons'){
			// 	if((question === 'MM303a' && (filters.country === 'CY' || filters.country === 'EE' 
			// 		|| filters.country === 'MT' || filters.country2 === 'CY' || filters.country2 === 'EE' 
			// 		|| filters.country2 === 'MT')) ){
			// 			alert(props.literals.L100576);
			// 			return true;
			// 	}
			// }else if(props.chart === 'national-bar-chart'){
			// 	if((question === 'MM303a' && (filters.country === 'CY' || filters.country === 'EE' 
			// 		|| filters.country === 'MT')) ){
			// 		alert(props.literals.L100576);
			// 		return true;
			// 	}
			// }else if(props.chart === 'pie-chart'){
			// 	if(question === 'MM350' && (filters.country === 'CY' || filters.country === 'MT' 
			// 		|| filters.country === 'SE') ){
			// 		alert(props.literals.L100576);
			// 		return true;					
			// 	}
			// }
		}

		//RULES ESENER 2014
		if((props.chart === 'european-map' || props.chart === 'european-bar-chart') && props.year === "2014")
		{
			if(question === 'Q202_2' || question === 'Q202_3' || question === 'Q202_5'
				|| question === 'Q202_6' || question == 'Q202_8' || question === 'Q202_9'
				|| question === 'Q202_11' || question == 'Q202_12' || question === 'Q202_13'
				|| question === 'Q202_15' || question.match('Q251') != undefined 
				|| question.match('Q252') != undefined || question === 'Q254gr' 
				|| question === 'Q255' || question.match('Q256') != undefined
				|| question === 'Q258b' || question === 'Q259' || question === 'Q308_1'
				|| question === 'Q354')
			{
				if(filters.sectorSize === 'activity-sector' && filters.activitySector === "6")
				{
					alert(props.literals.L100576);
					return true;
				}
			}else if(question.match('Q261') != undefined)
			{
				//En vez de popup que cambie a company size si as = 14 y es = 11
				if(filters.sectorSize === 'activity-sector')
				{
					if(filters.activitySector === "14" && filters.establishmentSize === "11"){
						// setSectorSize('company-size');
						// this.setState({ filters: {...filters, sectorSize: 'company-size'} });
						setFilters(prevFilters => ({
							...prevFilters,
							sectorSize: 'company-size'
						}));
						return false;
					}else{
						alert(props.literals.L100576);
						return true;							
					}

				}else
				{
					if(filters.establishmentSize === "14" || filters.establishmentSize === "10")
					{
						alert(props.literals.L100576);
						return true;
					}
				}
			}
		}else if(props.chart === 'national-bar-chart' && props.year === "2014"){
			if(question.match('Q261') != undefined)
			{
				alert(props.literals.L100576);
				return true;
			}
		}else if(props.chart === 'national-comparisons' && props.year === "2014"){
			if(question.match('Q261') != undefined)
			{
				if(filters.sectorSize === 'activity-sector')
				{
					if(filters.activitySector === "14" && filters.establishmentSize === "11"){
						// setSectorSize('company-size');
						// this.setState({ filters: {...filters, sectorSize: 'company-size'} });
						setFilters(prevFilters => ({
							...prevFilters,
							sectorSize: 'company-size'
						}));
						return false;
					}else{
						alert(props.literals.L100576);
						return true;							
					}
				}else
				{
					if(filters.establishmentSize === "10")
					{
						alert(props.literals.L100576);
						return true;
					}else if(filters.establishmentSize === "14" && (filters.country === 'AL' || filters.country2 === 'AL'
						|| filters.country === 'ME' || filters.country2 === 'ME' || filters.country === 'BG' 
						|| filters.country2 === 'BG' || filters.country === 'DK' || filters.country2 === 'DK'
						|| filters.country === 'EE' || filters.country2 === 'EE' || filters.country === 'ES' 
						|| filters.country2 === 'ES' || filters.country === 'HR' || filters.country2 === 'HR'
						|| filters.country === 'IT' || filters.country2 === 'IT' || filters.country === 'LV' 
						|| filters.country2 === 'LV' || filters.country === 'RO' || filters.country2 === 'RO'
						|| filters.country === 'SE' || filters.country2 === 'SE' || filters.country === 'SI' 
						|| filters.country2 === 'SI' || filters.country === 'UK' || filters.country2 === 'UK')){
						alert(props.literals.L100576);
						return true;
					}
				}
			}else if((question === 'Q251' || (question.match('Q252') != undefined && question != 'Q252_2')
				|| question === 'Q254gr' || question === 'Q255' || question.match('Q256') != undefined
				|| question === 'Q258b' || question === 'Q259') && (filters.country === 'AL' 
				|| filters.country2 === 'AL' || filters.country === 'IS' || filters.country2 === 'IS'
				|| filters.country === 'ME' || filters.country2 === 'ME' || filters.country === 'MK' 
				|| filters.country2 === 'MK' || filters.country === 'CY' || filters.country2 === 'CY'
				|| filters.country === 'EL' || filters.country2 === 'EL' || filters.country === 'HR' 
				|| filters.country2 === 'HR' || filters.country === 'LT' || filters.country2 === 'LT'
				|| filters.country === 'LU' || filters.country2 === 'LU' || filters.country === 'MT' 
				|| filters.country2 === 'MT')){
				if(filters.sectorSize === 'activity-sector' && filters.activitySector === "6"){
					alert(props.literals.L100576);
					return true;
				}
			}
		}

		return false;
	}

	const onCallback = (callbackSeries) => {
		setCsvData(callbackSeries);
	}

	function setUrl(){
		// console.log("setUrl",filters.answer);
		var url = "/"+props.lang+"/survey/"+"detailpage-"+chart+"/"+props.year+"/"+filters.topic+"/"+props.locale+"/"+filters.question;
		if(chart === "european-map"){
			url = url+"/"+filters.sectorSize+"/"+filters.activitySector+"/"+filters.establishmentSize+"/"+filters.answer;
		}else if(chart === "european-bar-chart"){
			url = url+"/"+filters.sectorSize+"/"+filters.activitySector+"/"+filters.establishmentSize;
		}else if(chart === "national-bar-chart"){
			url = url+"/"+filters.sectorSize+"/"+filters.country;
		}else if(chart === "national-comparisons"){
			url = url+"/"+filters.sectorSize+"/"+filters.activitySector+"/"+filters.establishmentSize+"/"+filters.country+"/"+filters.country2;
		}else{
			url = url+"/"+filters.country;
		}

		// console.log("url",url);

		history.push({
			pathname: url
		})
	}

	var chartComponent = "";

	if(props.chart === "european-map"){
		chartComponent = <EuropeanMap filters={filters} csvDataCallback={onCallback} />;
	}else if(props.chart === "european-bar-chart"){
		chartComponent = <EuropeanBarChart filters={filters} csvDataCallback={onCallback} year={props.year}/>
	}else if(props.chart === "national-bar-chart"){
		chartComponent = <NationalBarChart filters={filters} csvDataCallback={onCallback} year={props.year} />
	}else if(props.chart === "national-comparisons"){
		chartComponent = <NationalComparisonsChart filters={filters} literals={props.localeLiterals} csvDataCallback={onCallback} />
	}else if(props.chart === "pie-chart"){
		chartComponent = <PieChart filters={filters} csvDataCallback={onCallback} year={props.year}/>
	}

	return(
		<div className="detail-page en">
			<div className="container-fluid detail--page--wrapper">
				<QuestionList filters={filters} year={props.year} chart={chart} lang={props.lang} locale={props.locale} callback={changeToQuestion} page="detailpage"
				rules={rulesForTooltip}/>
				<section className="detail--content--section">
					<Selector year={props.year} chart={chart} filters={filters} literals={props.literals} locale={props.locale}
						callback={changeToNewFilters} rules={rulesForTooltip} questionProperties={questionObject} />
					{chartComponent}
					<Export 
						literals={props.localeLiterals} 
						year={props.year} 
						filters={filters} 
						questionProperties={questionObject}
						chart={chart} 
						csvChartName={csvChartName} 
						csvData={csvData} />
				</section>
			</div>
		</div>
	)
}

function mapStateToProps(state){
    const {literals} = state;
	const {comparisonProps} = state;
    return {
        literals,
		comparisonProps
    }
}

export default connect(mapStateToProps, { setComparisonProps })(DetailPage);