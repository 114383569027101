import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import $ from "jquery";

// Custom Css-in-js styles
const colorStyles = {
    option: (styles, state) => {
        return {
            ...styles,
            backgroundColor: state.isSelected ? '#ccc' : '#666',
            color: state.isSelected ? '#666' : '#fff',
            '&:hover': {
                background: '#ccc',
                color: '#666',
            }
        }
    },
    valueContainer: (styles) => {
        return {
            ...styles,
            color: '#fff'
        }
    },
    singleValue: (styles) => {
        return {
            ...styles,
            color: '#fff',
        }
    },
    input: (styles) => {
        return {
            ...styles,
            color: '#fff'
        }
    },
    dropdownIndicator: (styles, state) => {
        return {
            ...styles,
            color: '#FFF',
            transition: 'all .2s ease',
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'null',
            '&:hover': {
                color: '#fff'
            }
        }
    }
}

class QuestionListSelect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectDefaultQuestion: [],
            selectedDefaultQuestion: null,
        }
    }

    // Initialization of questions array - It also sets the first question is there are none at this moment
    defaultQuestionInitArray = (questionArray) => {
        const auxQuestionArray = [];
        questionArray.forEach((element) => {
            if (element.categoryName != null || element.categoryName != undefined) {
                auxQuestionArray.push({ label: this.props.localeLiterals[`L${element.nameLiteralID}`], value: element.categoryName });
            }
        })

        // if selected value is null, sets the correct value, based on the local locale language selected
        if (this.state.selectedDefaultQuestion == null) {
            const question = auxQuestionArray.find((element) => element.value == this.props.filters.question);

            this.setState({ selectedDefaultQuestion: question })
        }
        
        // it Changes the selected value, based on the local locale language selected.
        if (this.state.selectedDefaultQuestion != null) {
            const question = auxQuestionArray.find((element) => element.value == this.props.filters.question);
            
            this.setState({ selectedDefaultQuestion: question })
        }

        this.setState({ selectDefaultQuestion: auxQuestionArray });
    }

    handleSelectChange = (value) => {
        this.props.onCallback(value);
        let searchContainer = $('div.css-1pahdxg-control');
        searchContainer.removeClass('css-1pahdxg-control');
        searchContainer.addClass('css-yk16xz-control');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.questionList != this.props.questionList
            || prevProps.locale != this.props.locale 
            || prevProps.filters.question != this.props.filters.question) {
            this.defaultQuestionInitArray(this.props.questionList);
        }
    }

    render() {
        return (
            <div className="filter--questions--wrapper" >
                <div className="dropdown">
                    <Select
                        onChange={this.handleSelectChange}
                        value={this.state.selectedDefaultQuestion} 
                        isSearchable
                        options={this.state.selectDefaultQuestion.map(( item ) => ({ label: item.label, value: item.value }))} 
                        className="select2-container select2-results" 
                        styles={colorStyles}
                        />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {localeLiterals} = state;
    return {
        localeLiterals
    }
}
export default connect(mapStateToProps)(QuestionListSelect);