import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducers';

import { Cookies, CookiesProvider, useCookies } from 'react-cookie';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

// App
import App from './App';
// Home
import Home from './components/home/Home';
// Survey
import Comparison from './components/survey/Comparison';
import DataVisualisation from './components/survey/DataVisualisation'
import DetailPage from './components/survey/DetailPage';
import Overview from './components/survey/Overview';
import Methodology from './components/methodology/Methodology';
//Footer pages
import Sitemap from './components/footer-pages/Sitemap';
import Accessibility from './components/footer-pages/Accesibility';
import PrivacyPolicy from './components/footer-pages/PrivacyPolicy';
import LegalNotice from './components/footer-pages/LegalNotice';
import ScrollToTop from './components/common/hook/ScrollToTop';
import PageNotFound from './components/footer-pages/PageNotFound';
import CookiesPage from './components/footer-pages/CookiesPage';

const middlewares = composeWithDevTools(
	applyMiddleware()
)

if (window.location.href.indexOf('#!') > -1)
{
	// Check if the #! is between / symbols
	if (window.location.href.indexOf('/#!/') > -1)
	{
		window.location.href = window.location.href.replace('#!/', '');
	}
	else
	{
		window.location.href = window.location.href.replace('#!', '');
	}	
}

const store = createStore(reducer, middlewares);

// var literals = require('./model/Literals.json');
// var literals = store.getState().literals;

const PIWIK = process.env.PIWIK;
const DVT = process.env.DVT;

var configuration = require('../src/components/common/cookies/cookiesConfig.json');
var url = configuration.paths.piwik[PIWIK].protocol + ":" + configuration.paths.piwik[PIWIK].domain + ":" + configuration.paths.piwik[PIWIK].port + configuration.paths.piwik[PIWIK].path;
var basePath = configuration.paths.enviroment[DVT].domain + ":" + configuration.paths.enviroment[DVT].port + "/";

const instance = createInstance({
	urlBase: basePath, //https://LINK.TO.DOMAIN',
	siteId: configuration.paths.piwik[PIWIK].SiteId, //3
	// userId: 'UID76903202', // optional, default value: `undefined`.
	trackerUrl: url+'piwik.php', //'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
	srcUrl: url+'piwik.js', //'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
	disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
	// heartBeat: { // optional, enabled by default
	//   active: true, // optional, default value: true
	//   seconds: 10 // optional, default value: `15
	// },
	linkTracking: true, // optional, default value: true
	// configurations: { // optional, default value: {}
	//   // any valid matomo configuration, all below are optional
	//   disableCookies: true,
	//   setSecureCookie: true,
	//   setRequestMethod: 'POST'
	// }
});

// console.log("instance",instance);

ReactDOM.render(
	<MatomoProvider value={instance}>
	<CookiesProvider>
	<Provider store={store}>
		<BrowserRouter basename="esener" >
			<ScrollToTop />
			<Switch>
				<Route exact path="/">
					<Redirect to="/en" />
				</Route>
				<Route exact path="/:lang" render={routeParams => <App><Home lang={routeParams.match.params.lang} literals={store.getState().literals}/></App>} />
				<Route 
					path="/:lang/survey/overview/:year" 
					render={routeParams => <App><Overview year={routeParams.match.params.year} lang={routeParams.match.params.lang} literals={store.getState().literals}/></App>}
				/>
				<Route 
					path="/:lang/survey/datavisualisation/:year" 
					render={routeParams => <App><DataVisualisation year={routeParams.match.params.year} lang={routeParams.match.params.lang} literals={store.getState().literals} locale={store.getState().localeValue} /></App>} 
				/>
				<Route 
					path="/:lang/survey/comparisons/:year/:topic/:locale/:sectorSize/:question/:country/:answer" 
					render={routeParams => <App><Comparison 
								topic={routeParams.match.params.topic} 
								question={routeParams.match.params.question} 
								country={routeParams.match.params.country} 
								answer={routeParams.match.params.answer} 
								sectorSize={routeParams.match.params.sectorSize} 
								year={routeParams.match.params.year} 
								lang={routeParams.match.params.lang}
								locale={routeParams.match.params.locale} 
							/></App>} 
				/>

				{/* Chart pages */}
				{/* EUROPEAN MAP */}
				<Route 
					path="/:lang/survey/detailpage-european-map/:year/:topic/:locale/:question/:sectorSize/:activity/:company/:answer" 
					render={routeParams => <App><DetailPage chart="european-map" year={routeParams.match.params.year} lang={routeParams.match.params.lang} 
					locale={routeParams.match.params.locale} topic={routeParams.match.params.topic} question={routeParams.match.params.question} 
					sectorSize={routeParams.match.params.sectorSize} activity={routeParams.match.params.activity} company={routeParams.match.params.company} 
					answer={routeParams.match.params.answer} literals={store.getState().literals} localeLiterals={store.getState().localeLiterals}/></App>}
				/>
				{/* EUROPEAN BAR CHART */}
				<Route 
					path="/:lang/survey/detailpage-european-bar-chart/:year/:topic/:locale/:question/:sectorSize/:activity/:company" 
					render={routeParams => <App><DetailPage chart="european-bar-chart" year={routeParams.match.params.year} lang={routeParams.match.params.lang} 
					locale={routeParams.match.params.locale} topic={routeParams.match.params.topic} question={routeParams.match.params.question} 
					sectorSize={routeParams.match.params.sectorSize} activity={routeParams.match.params.activity} company={routeParams.match.params.company} 
					literals={store.getState().literals} localeLiterals={store.getState().localeLiterals}/></App>} 
				/>
				{/* NATIONAL BAR CHART */}
				<Route 
					path="/:lang/survey/detailpage-national-bar-chart/:year/:topic/:locale/:question/:sectorSize/:country" 
					render={routeParams => <App><DetailPage chart="national-bar-chart" year={routeParams.match.params.year} lang={routeParams.match.params.lang} 
					locale={routeParams.match.params.locale} topic={routeParams.match.params.topic} question={routeParams.match.params.question} 
					sectorSize={routeParams.match.params.sectorSize} country={routeParams.match.params.country} literals={store.getState().literals} 
					localeLiterals={store.getState().localeLiterals}/></App>} 
				/>
				{/* NATIONAL COMPARISONS */}
				<Route 
					path="/:lang/survey/detailpage-national-comparisons/:year/:topic/:locale/:question/:sectorSize/:activity/:company/:country/:country2" 
					render={routeParams => <App><DetailPage chart="national-comparisons" year={routeParams.match.params.year} lang={routeParams.match.params.lang} 
					locale={routeParams.match.params.locale} topic={routeParams.match.params.topic} question={routeParams.match.params.question} 
					sectorSize={routeParams.match.params.sectorSize} activity={routeParams.match.params.activity} company={routeParams.match.params.company}
					country={routeParams.match.params.country} country2={routeParams.match.params.country2} literals={store.getState().literals}
					localeLiterals={store.getState().localeLiterals}/></App>} 
				/>
				{/* PIE CHART */}
				<Route 
					path="/:lang/survey/detailpage-pie-chart/:year/:topic/:locale/:question/:country" 
					render={routeParams => <App><DetailPage chart="pie-chart" year={routeParams.match.params.year} lang={routeParams.match.params.lang} 
					locale={routeParams.match.params.locale} topic={routeParams.match.params.topic} question={routeParams.match.params.question} 
					country={routeParams.match.params.country} literals={store.getState().literals}  localeLiterals={store.getState().localeLiterals}/></App>} 
				/>

				{/* Footer pages */}
				<Route exact path="/:lang/about-tool" render={routeParams => <App><Methodology lang={routeParams.match.params.lang} literals={store.getState().literals}/></App>} />
				<Route exact path="/:lang/sitemap" render={routeParams => <App><Sitemap lang={routeParams.match.params.lang} literals={store.getState().literals}/></App>} />
				<Route exact path="/:lang/accessibility" render={routeParams => <App><Accessibility lang={routeParams.match.params.lang} literals={store.getState().literals}/></App>} />
				<Route exact path="/:lang/cookies" render={routeParams => <App><CookiesPage matomo={instance} lang={routeParams.match.params.lang} literals={store.getState().literals}/></App>} />
				<Route exact path="/:lang/legal-notice" render={routeParams => <App><LegalNotice lang={routeParams.match.params.lang} literals={store.getState().literals}/></App>} />
				<Route path="/:lang/page-not-found" render={routeParams => <App><PageNotFound lang={routeParams.match.params.lang} literals={store.getState().literals} /></App>} />

				<Redirect to="/en/page-not-found" />
			</Switch>
		</BrowserRouter>
	</Provider>
	</CookiesProvider>
	</MatomoProvider>, 
	document.getElementById('root')
);