import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setLocale, setLocaleValue } from '../../actions/';
import { Fragment } from 'react';

const LocaleSelect = props => {

    const history = useHistory();

    function changeLocale(event) 
    {      
        const newLocale = event.target.value;

        updateLocale(newLocale);
    }

    function updateLocale (newLocale)
    {
        props.setLocale(newLocale);
        props.setLocaleValue(newLocale);

        var url = history.location.pathname.split('/');
        // The locale is in the same position in the data visualisation and in the comparisons pages
        url[6] = newLocale;
        url = url.join('/');

        history.push({
            pathname: url
        })
    }

    if (props.comparison)
    {
        let changeLocale = false;
        let newLocale = '';
        if (props.locale == 'al' || props.locale == 'me' || props.locale == 'tr')
        {
            changeLocale = true;
            newLocale='en_1';
        }
        else if (props.locale == 'al_1')
        {
            changeLocale = true;
            newLocale = 'mk';
        }

        if (changeLocale)
        {
            updateLocale(newLocale);
        }
    }

    return (
        <div id="searchFilter">
            <select tyle={{width:140}} className='lang-dropdown-select-element form-select' id='lang-dropdown-select-language_content'
                accessKey='8' title='Language' name='lang_dropdown_select' onChange={changeLocale} value={props.locale}>
                    {/* Year is 2009 */}
                    {props.year == 2009 && (
                        <Fragment>
                            <option value="bg">Български</option> {/* <!-- Bulgary --> */}
                            <option value="cs">Čeština</option>
                            <option value="da">Dansk</option>
                            <option value="de">Deutsch</option>
                            <option value="et">Eesti</option>
                            <option value="el">Ελληνικά</option>
                            <option value="en">English</option>
                            <option value="es">Español</option>
                            <option value="fr">Français</option>
                            <option value="it">Italiano</option>
                            <option value="hr">Hrvatski</option>
                            <option value="lv">Latviešu</option>
                            <option value="lt">Lietuvių</option>
                            <option value="hu">Magyar</option>
                            <option value="mt">Malti</option>
                            <option value="nl">Nederlands</option>
                            <option value="no">Norsk</option>
                            <option value="pl">Polski</option>
                            <option value="pt">Português</option>
                            <option value="ro">Română</option>
                            <option value="sk">Slovenčina</option>
                            <option value="sl">Slovenščina</option>
                            <option value="sv">Svenska</option>
                            <option value="fi">Suomi</option>
                            <option value="tr">Türkçe</option>
                        </Fragment>
                    )}
                    {/* Year is 2014 and 2019 */}
                    {(props.year == 2014 || props.year == 2019) && (
                        <Fragment>
                            {
                                props.year === "2014" && !props.comparison ? (
                                    <option value="al">(AL) Shqipëria</option>
                                ) : "" 
                            }
                            <option value="de_1">(AT) Österreich</option>
                            <option value="fr_1">(BE) Belgique</option>
                            <option value="nl_1">(BE) België</option>
                            <option value="bg">(BG) България</option>
                            <option value="de_3">(CH) Schweiz</option>
                            <option value="fr_3">(CH) Suisse</option>
                            <option value="it_1">(CH) Svizzera</option>
                            <option value="el_1">(CY) Κύπρος</option>
                            <option value="cs">(CZ) Česko</option>                       
                            <option value="de">(DE) Deutschland</option>
                            <option value="da">(DK) Danmark</option>
                            <option value="et">(EE) Eesti</option>
                            <option value="ru_1">(EE) Эстония</option>
                            <option value="el">(EL) Ελλάδα</option>
                            <option value="es">(ES) España</option>
                            <option value="fi">(FI) Suomi</option>
                            <option value="sv_1">(FI) Finland</option>                    
                            <option value="fr">(FR) France</option> 
                            <option value="hr">(HR) Hrvatska</option>
                            <option value="hu">(HU) Magyarország</option>
                            <option value="en_1">(IE) Ireland</option>
                            <option value="is">(IS) Ísland</option>
                            <option value="it">(IT) Italia</option>
                            <option value="lt">(LT) Lietuva</option>
                            <option value="ru_3">(LT) Литва</option>
                            <option value="de_2">(LU) Luxemburg</option>
                            <option value="fr_2">(LU) Luxembourg</option>
                            <option value="lu">(LU) Lëtzebuerg</option>
                            <option value="lv">(LV) Latvija</option>
                            <option value="ru_2">(LV) Латвия</option>
                            {
                                props.year === "2014" && !props.comparison ? (
                                    <option value="me">(ME) Crna Gora</option>
                                ) : "" 
                            }
                            {
                                props.year === "2014" && !props.comparison ? (
                                    <option value="al_1">(MK) Maqedonia e Veriut</option>
                                ) : "" 
                            }
                            <option value="mk">(MK) Северна Македонија</option>
                            <option value="en_2">(MT) Malta (EN)</option>
                            <option value="mt">(MT) Malta (MT)</option>
                            <option value="nl">(NL) Nederland</option>
                            <option value="no">(NO) Norge</option>
                            <option value="pl">(PL) Polska</option>
                            <option value="pt">(PT) Portugal</option>
                            <option value="ro">(RO) România</option>
                            <option value="sr">(RS) Srbija</option>
                            <option value="sv">(SE) Sverige</option>
                            <option value="sl">(SI) Slovenija</option>
                            <option value="sk">(SK) Slovensko</option>
                            {
                                props.year === "2014" && !props.comparison ? (
                                    <option value="tr">(TR) Türkiye</option>
                                ) : "" 
                            }
                            <option value="en">(UK) United Kingdom</option>
                        </Fragment>
                        
                    )}
            </select>
        </div>
    )
}

function mapStateToProps(state){
    const { localeLiterals } = state;
    return {
        localeLiterals
    }
}

export default connect(mapStateToProps, { setLocale, setLocaleValue })(LocaleSelect);