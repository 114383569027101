import React, { Component } from 'react';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { getComparisonChartData } from '../../../api';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

class ComparisonColumnChart extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            chartConfig: {
                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },
                colors: [],
                chart: {
                    //width: 820,
                    height: 660,
                    type: this.props.type,
                    backgroundColor: '#FFF'
                },
                exporting: {
                    enabled: false,
                    buttons: {
                        contextButton: {
                            menuItems: ['viewFullscreen']
                        }
                    }
                },
                legend: {
                    symbolRadius: 0,
                    itemStyle: {
                        // color: '#000000',
                        font: "14px OpenSans",
                        fontWeight: 'normal'
                    },
                    itemDistance: 10,
                },
                xAxis: {
                    labels: {
                        rotation: -60,
                        style: {
                            fontFamily: 'OpenSans-bold',
							fontWeight: 'normal',
							fontSize:'12px',
                        },
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    labels: {
                        format: this.props.percentage === true ? '{value:,.0f} %' : `{value:,.0f} ${this.props.percentage}`,
                        style: {
							fontFamily: 'OpenSans-bold',
							fontWeight: 'normal',
							fontSize:'12px'
						}
                    },
                    tickInterval:20,
                    min: 0,
                    max: 100
                },
                tooltip: {
                    useHTML: true,
                    opacity:0.9,
                    backgroundColor: '#333333',
                    borderColor: '#000000',
                    zIndex: 100,
                    style: {
                        width: 450,
                        color: 'white',
                        fontFamily: 'OpenSans',
                        fontWeight: 'normal',
                        fontSize:'12px'
                    },
                    formatter: function () {
                        return '<ul class="tooltip-item">'+
                        '<li>'+ this.x +' </li>' +
                        '<li>'+ this.series.name  +' </li>' +
                        '<li><strong class="tooltip-value up"> </strong> '+ this.y +'%</li>' +
                        '</ul>';
                    }
                },
                plotOptions: {},
                series: []
            },
            isLoading: false
        }
    }

    createColorPalette = (size) => {
        const colorArray = [];

        switch (size) {
            case 1:
                colorArray.push(...colorArray, '#f6a400')
                break;
        
            case 2: 
                colorArray.push(...colorArray, '#f6a400', '#cbe2e3')
                break;

            case 3:
                colorArray.push(...colorArray, '#f6a400', '#cbe2e3', '#58585A')
                break;
            default:
                console.log('no length of series found');
                break;
        }

        return colorArray
    }

    redrawChart = () => {
        this.state.chartConfig.chart.events.render();
    }

    loadChartData = (question, sectorSize, country, answer, language) => {
        let categories = [];
        let series = [];
        let auxSeries = [];
        let dataForCsv = []

        getComparisonChartData(question, sectorSize, language, country, answer)
            .then((data) => {
                try {
                    this.setState({ ...this.state, isLoading: true });
                    data.resultset.forEach((element) => {
                        if (categories.indexOf(element.country) == -1) {
                            categories.push(element.split2);
                            dataForCsv.push({ name: element.split2, value: element.value, question: element.question })
                        }

                        let split1 = element.split1;
                        if (!(split1 in auxSeries)) {
                            auxSeries[split1] = []
                        }
                        auxSeries[split1].push(element.value);

                    })
                } catch (error) {
                    console.log('Error fetching comparison chart data', error.message);
                } finally {
                    this.setState({ ...this.state, isLoading: false });
                }

                for (let serie in auxSeries) {
                    series.push({ name: serie, data: auxSeries[serie], color: serie == '2014' ? '#f6a400' : '#cbe2e3' });
                }
                this.setState({ chartConfig: {...this.state.chartConfig, colors: [], series: [], xAxis: {}}});

                this.props.callbackSeries({dataForCsv});

                setTimeout(() => {
                    this.setState({ chartConfig: {
                        ...this.state.chartConfig, 
                        colors: this.createColorPalette(series.length), 
                        series, 
                        xAxis: {categories}
                    }})    
                }, 50);
            })

    }

    componentDidMount() {
        this.loadChartData(
            this.props.filters.question, 
            this.props.filters.sectorSize, 
            this.props.filters.country, 
            this.props.filters.answer,
            this.props.filters.locale);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters != this.props.filters) {
            this.loadChartData(
                this.props.filters.question, 
                this.props.filters.sectorSize, 
                this.props.filters.country, 
                this.props.filters.answer,
                this.props.filters.locale);
        }
    }
    

    render() {
        return (
            <div className="">
            {/** <-- TODO: Specific container styles for comparion bar chart */}
                <div className="xxs-w1 xs-w1 w1 center-text">
                    <div className="card--block--chart">
                        <div className="chart--block">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={this.state.chartConfig}
                                containerProps={{ className: 'chart--block' }}
                            />
                        </div>
                    </div>
                </div>
			</div>
        );
    }
}

export default ComparisonColumnChart;