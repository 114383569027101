import React, { Component, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useMatomo } from '@datapunt/matomo-tracker-react';

class EURibbon extends Component
{
    constructor(props)
	{
		super(props);
	}

	// Check if the dropdown is open and close it if it is
	handleClickOutside(e)
	{
		const elements = Array.from(document.getElementsByClassName("ribbon-eu open"));
		if (elements.length > 0 && !e.target.classList.contains("dropdown-toggle") && !e.target.id=="institutionsBodies")
		{
			elements[0].classList.remove("open")
		}		
	}

	handleLinkClick(e)
	{
		const { trackEvent } = useMatomo();
		trackEvent({
			category: 'Family bar',
			action: 'Link Click',
			name: e.target.href, 
		})
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
	}
	
	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

    render()
    {
        return (
            <Fragment>
                <div className="ribbon-eu">
					<span>{this.props.literals.L903}</span>
					<a className="dropdown-toggle " href="#" role="button" id="EUAddresses" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.props.literals.L904}</a>
                    <div className="dropdown-menu" aria-labelledby="EUAddresses" >
                        <p className='dropdown-item'>{ ReactHtmlParser(this.props.literals.L905) }</p>
                        <p className='dropdown-item'><a href={"https://europa.eu/european-union/contact/institutions-bodies_"+this.props.lang} target="_blank" id="institutionsBodies">{this.props.literals.L906}</a></p>
                    </div>
                </div>
				
                <div className="our-websites-menu">
					<span className='view-our-webs'>{this.props.literals.L907}</span>
					<nav className="container">
						<ul className="clearfix nav">
							<li className="nav-item">
								<span className="nav-link nav-link-">{this.props.literals.L907}</span>
							</li>
							<li className="nav-item">
								<a href={"https://osha.europa.eu/"+this.props.lang} className="our-websites nav-link nav-link-https--oshaeuropaeu" target="_blank" onClick={this.handleLinkClick}>{this.props.literals.L908}</a>
							</li>
							<li className="nav-item">
								<a href={"https://healthy-workplaces.osha.europa.eu/"+this.props.lang} className="nav-link nav-link-https--healthy-workplaceseu" target="_blank" onClick={this.handleLinkClick}>{this.props.literals.L909}</a>
							</li>
							<li className="nav-item">
								<a href="https://visualisation.osha.europa.eu/osh-barometer/" className="active-item nav-link nav-link-https--visualisationoshaeuropaeu-osh-barometer-" target="_blank" onClick={this.handleLinkClick}>{this.props.literals.L910}</a>
							</li>
							<li className="nav-item">
								<a href={"https://oiraproject.eu/"+this.props.lang} className="our-websites nav-link nav-link-https--oiraprojecteu" target="_blank" onClick={this.handleLinkClick}>{this.props.literals.L911}</a>
							</li>
							<li className="nav-item">
								<a href={"https://oshwiki.osha.europa.eu/"+this.props.lang} className="our-websites nav-link nav-link-https--oshwikieu" target="_blank" onClick={this.handleLinkClick}>{this.props.literals.L912}</a>
							</li>
							<li className="nav-item active">
								<a href={"https://visualisation.osha.europa.eu/esener/"+this.props.lang} className="our-websites nav-link nav-link-https--visualisationoshaeuropaeu-esener" target="_self" onClick={this.handleLinkClick}>{this.props.literals.L913}</a>
							</li>
						</ul>
					</nav>
				</div>
            </Fragment>
        )
    }
}

export default EURibbon;