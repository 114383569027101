import { SET_LANGUAGE, SET_LOCALE, SET_LOCALE_VALUE, SET_LOCALE_VALUE_WITH_LANG, SET_COMPARISON_PROPS } from '../constants';

// For each of the actions defined for redux, this file will export one function
export function setLanguage(language) {
    const action = {
        type: SET_LANGUAGE,
        language
    }
    return action;
}

export function setLocale(locale) {
    const action = {
        type: SET_LOCALE,
        locale
    }
    return action;
}

export function setLocaleValue(locale) {
    const action = {
        type: SET_LOCALE_VALUE,
        locale
    }
    return action;
}

export function setLocaleValueWithLanguage(lang, year) {
    const action = {
        type: SET_LOCALE_VALUE_WITH_LANG,
        lang,
        year
    }
    return action;
}

export function setComparisonProps(comparisonProps)
{
    const action = {
        type: SET_COMPARISON_PROPS,
        comparisonProps
    }
    return action;
}