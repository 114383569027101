import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Footer extends Component
{
    goToTop = () => {
        window.scrollTo(0, 0);
    }

	render()
	{
        return(
            <div>
                <p className="go-to"><a onClick={this.goToTop}></a></p>
                <footer className={"bar-container nopadding container-fluid "+this.props.lang}>    
                    <div className="footer-elements">                        
                        <div className='footer-top'>
                            <span className='label'>{this.props.literals.L684}</span>
                            <ul className="footer-social-network list-inline">
                                <li>
                                    <a target="_blank" href="https://twitter.com/eu_osha" title={this.props.literals.L370} className="menu__link twiter">
                                        <span className="sr-only">{this.props.literals.L374}</span>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/EuropeanAgencyforSafetyandHealthatWork" title={this.props.literals.L371} className="menu__link facebook">
                                        <span className="sr-only">{this.props.literals.L375}</span>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="http://www.linkedin.com/company/european-agency-for-safety-and-health-at-work" title={this.props.literals.L372} className="menu__link linkedin">
                                        <span className="sr-only">{this.props.literals.L373}</span>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.youtube.com/user/EUOSHA" title={this.props.literals.L482} className="menu__link">
                                        <i className="fa fa-youtube-play" aria-hidden="true"></i>
                                        <span className="sr-only">{this.props.literals.L376}</span>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.flickr.com/photos/euosha/albums" title={this.props.literals.L483} className="menu__link flickr">
                                        <span className="sr-only">{this.props.literals.L377}</span>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.slideshare.net/EUOSHA" title={this.props.literals.L691} className="menu__link">
                                        <i className="fa fa-slideshare" aria-hidden="true"></i><span className="sr-only">{this.props.literals.L691}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='footer-bottom'>	
                            <p className="copyright">© {(new Date().getFullYear())} EU-OSHA | <a href={"https://osha.europa.eu/"+this.props.lang} target="_blank">{this.props.literals.L362}</a></p>
                            <ul className="footer-nav-accessibility list-inline">
                                <li>
                                    <NavLink className="menu__link" accessKey="M" to={"/"+this.props.lang+"/sitemap"}><span>{this.props.literals.L356}</span></NavLink>
                                </li>
                                <li>
                                    <a target="_blank" href={"https://osha.europa.eu/"+this.props.lang+"/contact-us"} accessKey="C" className="menu__link"> <span>{this.props.literals.L357}</span></a>
                                </li>
                                <li>
                                    <NavLink className="menu__link" accessKey="K" to={"/"+this.props.lang+"/accessibility"}><span>{this.props.literals.L358}</span></NavLink>
                                </li>
                                <li>
                                    <a target="_blank" href={"https://osha.europa.eu/"+this.props.lang+"/about-eu-osha/data-protection"} className="menu__link"><span>{this.props.literals.L359}</span></a>
                                </li>
                                <li>
                                    <NavLink className="menu__link" accessKey="P" to={"/"+this.props.lang+"/cookies"}><span>{this.props.literals.L390}</span></NavLink>
                                </li>
                                <li>
                                    <NavLink className="menu__link" accessKey="N" to={"/"+this.props.lang+"/legal-notice"}><span>{this.props.literals.L360}</span></NavLink>
                                </li>
                            </ul>                        
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Footer;