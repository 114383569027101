import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { methodologyLabel } from '../../model/PageLabels';


class Methodology extends Component
{
	componentDidMount() {
		document.title = methodologyLabel
	}
	
	render()
	{
		return(
			<div className={`container methodology ${this.props.lang}`}>{ReactHtmlParser(this.props.literals.L101073)}</div>
		)
	}
}

function mapStateToProps(state){
    const {literals} = state;
    return {
        literals
    }
}

export default connect(mapStateToProps, null)(Methodology);
// export default Methodology;