import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useStore } from 'react-redux';
import { setLanguage, setLocaleValueWithLanguage } from '../../actions/';

const LanguageSelect = props => { 
    const history = useHistory();
    const store = useStore();

    function changeLanguage (event) {
        const newLang = event.target.value;
        if(props.year === "2009" && (newLang === "is") || newLang === "sv"){
            props.setLanguage("en");
            props.setLocaleValueWithLanguage(newLang, props.year);
        }else if(props.year === "2014" && newLang === "en"){
            props.setLanguage("en_1");
            props.setLocaleValueWithLanguage(newLang, props.year);
        }else if(props.year === "2019" && newLang === "en"){
            props.setLanguage("en_1");
            props.setLocaleValueWithLanguage(newLang, props.year);
        }else{
            props.setLanguage(newLang);
            props.setLocaleValueWithLanguage(newLang, props.year);
        }
        const newLocale = store.getState().localeValue;

        var url = history.location.pathname.substr(3);
        // Check if the current url has the locale set
        url = url.split('/');
        if (url[5] != undefined && url[5] != null && url[5] != newLocale)
        {
            // Change the locale with its new value in the URL
            url[5] = newLocale;
        }
        url = url.join('/');

        history.push({
            pathname: "/"+newLang+url
        })
    }

    return (
        <form action="" className="lang--form">
            <label htmlFor="search" className="sr-only">Language</label>
            <select style={{width:'140px'}} className="lang-dropdown-select-element form-select" id="lang-dropdown-select-language_content" 
                accessKey="L" title="Language" name="lang_dropdown_select" onChange={changeLanguage} value={props.lang}>
                <option value="bg">Български</option>
                <option value="cs">Čeština</option>
                <option value="da">Dansk</option>
                <option value="de">Deutsch</option>
                <option value="et">Eesti</option>
                <option value="el">Ελληνικά</option>
                <option value="en">English</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
                <option value="hr">Hrvatski</option>
                <option value="is">Íslenska</option>
                <option value="it">Italiano</option>
                <option value="lv">Latviešu</option>
                <option value="lt">Lietuvių</option>
                <option value="hu">Magyar</option>
                <option value="mt">Malti</option>
                <option value="nl">Nederlands</option>
                <option value="no">Norsk</option>
                <option value="pl">Polski</option>
                <option value="pt">Português</option>
                <option value="ro">Română</option>
                <option value="sk">Slovenčina</option>
                <option value="sl">Slovenščina</option>
                <option value="fi">Suomi</option>
                <option value="sv">Svenska</option>
            </select>
        </form>
    )
}

function mapStateToProps(state){
    // console.log('state',state);
    const {literals} = state;
    const {localeValue} = state;
    return {
        literals,
        localeValue
    }
}

export default connect(mapStateToProps, {setLanguage, setLocaleValueWithLanguage})(LanguageSelect);

// export default LanguageSelect;