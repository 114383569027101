const bg = require('./EU-OSHA-bg.png');
const cs = require('./EU-OSHA-cs.png');
const da = require('./EU-OSHA-da.png');
const de = require('./EU-OSHA-de.png');
const et = require('./EU-OSHA-et.png');
const el = require('./EU-OSHA-el.png');
const en = require('./EU-OSHA-en.png');
const es = require('./EU-OSHA-es.png');
const fr = require('./EU-OSHA-fr.png');
const hr = require('./EU-OSHA-hr.png');
const is = require('./EU-OSHA-is.png');
const it = require('./EU-OSHA-it.png');
const lv = require('./EU-OSHA-lv.png');
const lt = require('./EU-OSHA-lt.png');
const hu = require('./EU-OSHA-hu.png');
const mt = require('./EU-OSHA-mt.png');
const nl = require('./EU-OSHA-nl.png');
const no = require('./EU-OSHA-no.png');
const pl = require('./EU-OSHA-pl.png');
const pt = require('./EU-OSHA-pt.png');
const ro = require('./EU-OSHA-ro.png');
const sk = require('./EU-OSHA-sk.png');
const sl = require('./EU-OSHA-sl.png');
const fi = require('./EU-OSHA-fi.png');
const sv = require('./EU-OSHA-sv.png');

const headerLogos = {
    bg,
    cs,
    da,
    de,
    et,
    el,
    en,
    es,
    fr,
    hr,
    is,
    it,
    lv,
    lt,
    hu,
    mt,
    nl,
    no,
    pl,
    pt,
    ro,
    sk,
    sl,
    fi,
    sv
}

export default headerLogos;