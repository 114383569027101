import { SET_LOCALE_VALUE, SET_LOCALE_VALUE_WITH_LANG } from '../constants';

import localeRelation from '../model/locale.json';

export default (state = '', action) => {
    switch (action.type) {
        case SET_LOCALE_VALUE:
            const { locale } = action;
            return locale;
        case SET_LOCALE_VALUE_WITH_LANG:
            const { lang, year } = action;
            if (year == 2009 && (lang == 'en' || lang == 'is'))
            {
                return 'en';
            }
            else
            {
                return localeRelation[lang];
            }
        default:
            return state;
    }
}