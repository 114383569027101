import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { siteMapLabel } from '../../model/PageLabels';

class Sitemap extends Component
{
	componentDidMount() {
		document.title = siteMapLabel
	}

	render()
	{
		const menu = require('../../model/menu.json');
		return(
			<div>
				<h1 className="title-section second-color ng-binding text-center">{this.props.literals.L356}</h1>
				<section className="container site--map--block" >
					<ul>
						{
							menu.map((level0, index) => (
								<li key={index}>
									{
										!!level0.levels ? (<span>{this.props.literals[level0.name]}</span>) : (
											<NavLink to={"/"+this.props.lang+"/"+level0.sref}>{this.props.literals[level0.name]}</NavLink>)
									}
									{
										level0.levels ? (
											<ul>
												{
													level0.levels.map((level1, index2) => (
														<li key={index2}>
															{
																
																level1.sref ? (
																	<NavLink to={"/"+this.props.lang+level1.sref+"/"+level1.indicator}>{this.props.literals[level1.name]}</NavLink>
																) : (
																	<a >{this.props.literals[level1.name]}</a>
																)
															}
														</li>
													))
												}
											</ul>
										) : ""
									}
								</li>									
							))
						}
						<li>
							<NavLink to={"/"+this.props.lang+"/sitemap"}>{this.props.literals.L356}</NavLink>
						</li>
						<li>
							<a target="_blank" href={"https://osha.europa.eu/"+this.props.lang+"/contact-us"}>{this.props.literals.L357}</a>
						</li>
						<li>
							<NavLink to={"/"+this.props.lang+"/accessibility"}>{this.props.literals.L358}</NavLink>
						</li>
						<li>
							<NavLink to={"/"+this.props.lang+"/privacy-notice"}>{this.props.literals.L359}</NavLink>
						</li>
						<li>
							<NavLink to={"/"+this.props.lang+"/legal-notice"}>{this.props.literals.L360}</NavLink>
						</li>
					</ul>
				</section>
			</div>
		)
	}
}

function mapStateToProps(state){
    const {literals} = state;	
    return {
        literals
    }
}

export default connect(mapStateToProps, null)(Sitemap);

// export default Sitemap;