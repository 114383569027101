//Main languages
const bg = require('./Literals_BG.json');
const cs = require('./Literals_CS.json');
const da = require('./Literals_DA.json');
const de = require('./Literals_DE.json');
const et = require('./Literals_ET.json');
const el = require('./Literals_EL.json');
const en = require('./Literals');
const es = require('./Literals_ES.json');
const fr = require('./Literals_FR.json');
const hr = require('./Literals_HR.json');
const is = require('./Literals_IS.json');
const it = require('./Literals_IT.json');
const lv = require('./Literals_LV.json');
const lt = require('./Literals_LT.json');
const hu = require('./Literals_HU.json');
const mt = require('./Literals_MT.json');
const nl = require('./Literals_NL.json');
const no = require('./Literals_NO.json');
const pl = require('./Literals_PL.json');
const pt = require('./Literals_PT.json');
const ro = require('./Literals_RO.json');
const sk = require('./Literals_SK.json');
const sl = require('./Literals_SL.json');
const fi = require('./Literals_FI.json');
const sv = require('./Literals_SV.json');

// National Versions
const al = require('./Literals_AL.json');
const de_1 = require('./Literals_DE_1.json');
const fr_1 = require('./Literals_FR_1.json');
const nl_1 = require('./Literals_NL_1.json');
const de_3 = require('./Literals_DE_3.json');
const fr_3 = require('./Literals_FR_3.json');
const it_1 = require('./Literals_IT_1.json');
const el_1 = require('./Literals_EL_1.json');
const ru_1 = require('./Literals_RU_1.json');
const sv_1 = require('./Literals_SV_1.json');
const en_1 = require('./Literals_EN_1.json');
const ru_3 = require('./Literals_RU_3.json');
const de_2 = require('./Literals_DE_2.json');
const fr_2 = require('./Literals_FR_2.json');
const lu = require('./Literals_LU.json');
const ru_2 = require('./Literals_RU_2.json');
const me = require('./Literals_ME.json');
const al_1 = require('./Literals_AL_1.json');
const mk = require('./Literals_MK.json');
const en_2 = require('./Literals_EN_2.json');
const sr = require('./Literals_SR.json');
const tr = require('./Literals_TR.json');

const literals = {
    bg,
    cs,
    da,
    de,
    et,
    el,
    en,
    es,
    fr,
    hr,
    is,
    it,
    lv,
    lt,
    hu,
    mt,
    nl,
    no,
    pl,
    pt,
    ro,
    sk,
    sl,
    fi,
    sv,
    al,
    de_1,
    fr_1,
    nl_1,
    de_3,
    fr_3,
    it_1,
    el_1,
    ru_1,
    sv_1,
    en_1,
    ru_3,
    de_2,
    fr_2,
    lu,
    ru_2,
    me,
    al_1,
    mk,
    en_2,
    sr,
    tr
}

export default literals