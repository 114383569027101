import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

import { getEuropeanBarChartData } from '../../../api';
import { europeanBarChartLabel } from '../../../model/PageLabels';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

class EuropeanBarChart extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            chartConfig: {
                title: {
                    text: "",
                    align: 'left'
                },
                credits: {
                    enabled: false
                },
                colors: [],
                chart: {
                    type: this.props.type,
                    backgroundColor: '#FFF'
                },
                exporting: {
                    enabled: true,
                    buttons: {
                        contextButton: {
                            menuItems: ['viewFullscreen']
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                tooltip: {},
                plotOptions: {
                    bar: {
                        stacking: 'normal',
                        borderWidth: 0
                    }
                },
                series: []
            },
            isLoading: false
        }
    }

    updateDimension = () => {
        const width = window.innerWidth;

        if (width > 767) {
            this.setState({
                chartConfig: {
                    ...this.state.chartConfig,
                    chart: {
                        ...this.state.chartConfig.chart,
                        type: 'column',
                        height: 660
                    },
                    xAxis: {
                        ...this.state.chartConfig.xAxis,
                        labels: {
                            ...this.state.chartConfig.labels,
                            rotation: -60
                        }
                    }
                }
            });
        } else {
            this.setState({
                chartConfig: {
                    ...this.state.chartConfig,
                    chart: {
                        ...this.state.chartConfig.chart,
                        height: 800,
                        type: 'bar'
                    },
                    xAxis: {
                        ...this.state.chartConfig.xAxis,
                        labels: {
                            ...this.state.chartConfig.labels,
                            rotation: 0
                        }
                    }
                }
            });
        }
    }

    componentDidMount() {
        document.title = europeanBarChartLabel;
        // console.log("European bar chart did mount");
        var charts = Highcharts.charts.filter(function( element ) {
            return element !== undefined;
        });
        charts[0].renderer.boxWrapper.attr({ preserveAspectRatio: 'xMinYMin' });
        //console.log("charts[0]",charts[0]);
        this.getLoadData(this.props.filters.question, this.props.filters.sectorSize, this.props.filters.activitySector, 
            this.props.filters.establishmentSize, this.props.filters.euOnly, this.props.filters.sortBy,this.props.filters.locale);

        window.addEventListener('resize', this.updateDimension);
    }

    componentDidUpdate(prevProps) {
        // console.log("prevProps.filters",prevProps.filters,"this.props.filters",this.props.filters);
        if(prevProps.filters.question != this.props.filters.question || prevProps.filters.sectorSize != this.props.filters.sectorSize
            || prevProps.filters.activitySector != this.props.filters.activitySector || prevProps.filters.establishmentSize != this.props.filters.establishmentSize
            || prevProps.filters.euOnly != this.props.filters.euOnly || prevProps.filters.sortBy != this.props.filters.sortBy
            || prevProps.filters.locale != this.props.filters.locale){
            this.getLoadData(this.props.filters.question, this.props.filters.sectorSize, this.props.filters.activitySector, 
                this.props.filters.establishmentSize, this.props.filters.euOnly, this.props.filters.sortBy,this.props.filters.locale);
            var charts = Highcharts.charts.filter(function( element ) {
                return element !== undefined;
            });
            charts[0].renderer.boxWrapper.attr({ preserveAspectRatio: 'xMinYMin' });
            // console.log("charts[0]",charts[0]);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimension);
    }

    createColorPalette = (size) => {
        let colorArray = [];
        if(size === 1){
            colorArray.push("#f6a400");
        }else if(size === 2){
            colorArray.push("#f6a400");
            colorArray.push("#cbe2e3");
        }else if(size === 3){
            colorArray.push("#f6a400");
            colorArray.push("#cbe2e3");
            colorArray.push("#58585A");
        }else if(size === 4){
            colorArray.push("#f6a400");
            colorArray.push("#cbe2e3");
            colorArray.push("#58585A");
            colorArray.push("#FFE300");
        }else if(size === 5){
            colorArray.push("#f6a400");
            colorArray.push("#cbe2e3");
            colorArray.push("#58585A");
            colorArray.push("#FFE300");
            colorArray.push("#449FA2");
        }else if(size === 6){
            colorArray.push("#f6a400");
            colorArray.push("#cbe2e3");
            colorArray.push("#58585A");
            colorArray.push("#FFE300");
            colorArray.push("#449FA2");
            colorArray.push("#F3C564");
        }else if(size === 7){
            colorArray.push("#f6a400");
            colorArray.push("#cbe2e3");
            colorArray.push("#58585A");
            colorArray.push("#FFE300");
            colorArray.push("#449FA2");
            colorArray.push("#F3C564");
            colorArray.push("#16983E");
        }

        colorArray.reverse();
        
        return colorArray;
    }

    // Init chart config so the Chart loads correctly
    initChart = () => {
        this.setState({ chartConfig: {
            title: {
                text: "",
                align: 'left'
            },
            credits: {
                enabled: false
            },
            // colors: ['#58585A','#cbe2e3','#f6a400'],
            colors: [],
            chart: {
                // width: 960,
                // marginLeft: 0,
                // width:995,
                height: window.innerWidth > 768 ? 660 : 800,
                type: window.innerWidth > 768 ? 'column' : 'bar',
                backgroundColor: '#FFF'
            },
            exporting: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    theme: {
                        fill: 'transparent',
                        states: {
                            hover: {
                                fill: '#CCC'
                            },
                            select: {
                                fill: 'transparent'
                            }
                        }
                    },
                    verticalAlign: 'top',
                    y: 0
                }
            },
            legend: {
                symbolRadius: 0,
                itemStyle: {
                    // color: '#000000',
                    font: "14px OpenSans",
                    fontWeight: 'normal'
                },
                itemDistance: 10,
                reversed: true
            },
            xAxis: {
                categories: [],
                labels: {
                    staggerLines: 1,
                    rotation: window.innerWidth > 768 ? -60 : 0,
                    style: {
                        fontFamily: 'OpenSans',
                        fontWeight: 'normal',
                        fontSize:'14px'
                    }
                }
            },
            yAxis: {
                title: {
                    text: ''
                },
                max: 100,
                labels: {
                    style: {
                        fontFamily: 'OpenSans',
                        fontWeight: 'normal',
                        fontSize:'12px',
                        whiteSpace: 'wrap'
                    },
                    formatter: function () {
                        return Math.abs(this.value) + '%';
                    }
                },
                gridLineWidth: 0
            },
            tooltip: {},
            plotOptions: {
                column: {
                    stacking: 'normal',
                    pointPadding: 0,
                    groupPadding: 0.2,
                    pointWidth: 15
                },
                series: {
                    states: {
                        inactive: {
                            enabled: false
                        },
                        hover:{
                            opacity: 0.7
                        }
                    }
                }
            },
            series: []
        } })
    }

    // Gets data for the selected Chart
    getLoadData = (question, sectorSize, activityId, establishmentId, euOnly, sortBy, locale) => {
        // console.log("European bar chart getLoadData");
        let categories = [];
        let categoriesClean = null;
        let series = [];
        let auxSeries = [];
        let seriesNames = [];
        
        let csvData = [];

        let answer = sortBy === "0" ? undefined : sortBy ;
        // console.log("this.props.year",this.props.year);
        
        this.initChart();
        getEuropeanBarChartData(question, sectorSize, sectorSize === "activity-sector" ? activityId : establishmentId, euOnly, locale, answer)
            .then((data) => {
                try {
                    this.setState({ ...this.state, isLoading: true });
                    let index = 0;

                    data.resultset.forEach(element => {
                        
                        if (categories.indexOf(element.country) == -1) {
                            categories.push(element.country);
                        }

                        let split1 = element.split1;
                        if ((seriesNames.indexOf(split1) === -1)) {
                            auxSeries[index] = [];
                            seriesNames.push(split1);
                            index++;
                        }

                        csvData.push({ country: element.countryCode, subset: element.split2, answer: element.split1, value: element.value });
                    });
                    
                    this.props.csvDataCallback(csvData);

                    // console.log("categories",categories);

                    let categoryIndex = 0;
                    categoriesClean = Array.from(categories);
                    categories.forEach((category,index) => {
                        // Check if a certain country has value for any of the answers
                        // if (!data.resultset.find(element => element.country === category && element.value == null))
                        let nullValueSize = data.resultset.filter(element => element.country === category && element.value == null);
                        
                        if (nullValueSize.length == 0)
                        {
                            // Only the countries that have data for at least one of the answers will be added
                            for (let i=0;i<seriesNames.length;i++) {
                                let found = data.resultset.find(element => element.country === category && element.split1 === seriesNames[i]);
                                // console.log("found", found);
                                if(found != undefined){
                                    auxSeries[i].push({name: seriesNames[i], y: found.value});
                                }else{
                                    auxSeries[i].push({name: seriesNames[i], y: 0});
                                }
                            }
                        }else{
                            if(nullValueSize.length == seriesNames.length){
                                // categoriesClean.splice(categoryIndex,1)
                                categoriesClean.splice(categoriesClean.indexOf(category),1);
                            }else{
                                // Only the countries that have data for at least one of the answers will be added
                                for (let i=0;i<seriesNames.length;i++) {
                                    let found = data.resultset.find(element => element.country === category && element.split1 === seriesNames[i]);
                                    // console.log("found", found);
                                    if(found != undefined){
                                        auxSeries[i].push({name: seriesNames[i], y: found.value});
                                    }else{
                                        auxSeries[i].push({name: seriesNames[i], y: 0});
                                    }
                                }
                            }
                        }
                        categoryIndex++;
                    })
                } catch (error) {
                    console.log('error fetching data', error)
                } finally {
                    this.setState({ ...this.state, isLoading: false })
                }

                let newArray;
                for (let i=0;i<seriesNames.length;i++) {
                    series.push( {
                        name: seriesNames[i], 
                        data: auxSeries[i]
                    } );
                    newArray = [...series].reverse();
                }

                var secondLinePosition = 0;

                if(this.props.year == "2019"){
                    let exists = categoriesClean.find(country => country.indexOf("CH") != -1);
                    let chCountryPosition = categoriesClean.indexOf(exists);
                    if(chCountryPosition != -1){
                        secondLinePosition = (chCountryPosition-1) + 0.5;
                    }
                }else{
                    secondLinePosition = 28.5;
                }

                this.setState({
                    chartConfig: {
                        ...this.state.chartConfig, 
                        colors: this.createColorPalette(series.length),
                        xAxis: {
                            categories: categoriesClean,
                            plotLines: this.props.filters.sortBy === "0" || this.props.filters.sortBy === undefined ? [
                                {
                                    color: 'black',
                                    width: 2,
                                    value: 0.5,
                                    id: 'break-eu'
                                },
                                {
                                    color: 'black',
                                    width: this.props.filters.euOnly != 0 ? 0 : 2,
                                    // value: this.props.year != "2019" ? 28.5 : 27.5,
                                    value: secondLinePosition,
                                    id: 'break-country'
                                }
                            ] : [
                                {
                                    color: 'black',
                                    width: 2,
                                    value: 0.5,
                                    id: 'break-eu'
                                }
                            ]
                        },
                        tooltip: {
                            // headerFormat: '<b>{point.x}</b><br/><b>{series.name}</b><br/>',
                            // pointFormat: '<b>{point.y}%</b> ',
                            useHTML: true,
                            opacity:0.9,
                            backgroundColor: '#333333',
                            borderColor: '#000000',
                            zIndex: 100,
                            style: {
                                width: 450,
                                color: 'white',
                                fontFamily: 'OpenSans',
                                fontWeight: 'normal',
                                fontSize:'12px'
                            },
                            formatter: function () {
                                return '<ul class="tooltip-item">'+
                                '<li>'+ this.x +' </li>' +
                                '<li>'+ this.series.name  +' </li>' +
                                '<li><strong class="tooltip-value up"> </strong> '+ this.y +'%</li>' +
                                '</ul>';
                            }
                        },
                        plotOptions: {
                            column: {
                                pointPadding: 0.01, 
                                borderWidth: 0
                            }, 
                            series: {
                                pointStart: 0,
                            },
                        }, 
                        series: newArray
                        // series: [{
                        //     dataSorting: {
                        //         enabled: true
                        //     },
                        //     data: newArray
                        // }]
                    }
                });
                // console.log(this.state.chartConfig);
            });
    }

	render()
	{
        // console.log("EUROPEAN BAR CHART FILTER PROPS", this.props);
        // console.log("this.state",this.state);
		return(
			<div className="european-bar-chart">
                <div className="xxs-w1 xs-w1 w1 center-text">
                    <div className="card--block--chart">
                        <div className="chart--block">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={this.state.chartConfig}
                                containerProps={{ className: 'chart--block' }}
                            />
                        </div>
                    </div>
                </div>
			</div>
		)
	}
}

export default EuropeanBarChart;